import React, { useState, useCallback, useRef, memo, FC } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Warning } from "../assets/warning.svg";
import { ReactComponent as LogOutIcon } from "../assets/logOut.svg";
import { useOnClickOutside } from "../../../utils/hooks";

import {
  ContextMenuStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  ContextMenuIconStyled,
} from "./styles";

interface DropdownConfigStyles {
  id: string;
  text: string;
  clickAction: () => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  onlyAdmin: boolean;
}

type ContextMenuProps = {
  handleReportModal: () => void;
};

const ContextMenu: FC<ContextMenuProps> = memo(({ handleReportModal }) => {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOnClickOutside(ref, () => setShowDropdown(false));

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem("JWT");
    setShowDropdown(false);
    navigate("/login");
  }, []);

  const dropdownConfig: Array<DropdownConfigStyles> = [
    {
      id: "send_report",
      text: "Send Report",
      clickAction: () => {
        handleReportModal();
        setShowDropdown(false);
      },
      Icon: Warning,
      onlyAdmin: true,
    },
    {
      id: "logout",
      text: "Logout",
      clickAction: handleLogout,
      Icon: LogOutIcon,
      onlyAdmin: false,
    },
  ];

  return (
    <ContextMenuStyled ref={ref}>
      <ContextMenuIconStyled
        data-test-id="menu"
        active={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownContentStyled>
          {dropdownConfig.map(({ text, clickAction, Icon, id }) => {
            return (
              <DropdownItemStyled
                data-test-id={id}
                key={id}
                onClick={clickAction}
              >
                <Icon />
                {text}
              </DropdownItemStyled>
            );
          })}
        </DropdownContentStyled>
      )}
    </ContextMenuStyled>
  );
});

export default ContextMenu;
