import React, { FC, memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";

import {
  FeaturePropertiesFeatureInfoStyled,
  FeaturePropertiesTableContainer,
  FeaturePropertiesBodyStyled,
  DeleteFeaturePropertiesStyled,
} from "../../../common/toolbox";
import * as MapConst from "../../../../constants/map-constants";
import { LineStringFeature } from "../../../LineStringFeature";
import { PolygonFeature } from "../../../PolygonFeature";
import {
  AvailableIds,
  FeaturesInterface,
  Lane,
  MapStructs,
} from "../../../../models/map-interface";

type FeaturePropertiesPanelProps = {
  featureIndex: number;
  testFeatures: FeaturesInterface;
  availableIds: AvailableIds;
  mapStructs: MapStructs;
  updateAvailablesIds: (ids: any) => void;
  updateMapStructs: (structs: any) => void;
  updateFeatures: (temp_features: any) => void;
  handleAddFeatureProperty: (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => void;
  deleteFeatureProperty: (
    featureIndex: number,
    featureInfoIndex: number
  ) => void;
};

export type FeatureInfo = { [key: string]: string | number };

export const FeaturePropertiesPanel: FC<FeaturePropertiesPanelProps> = memo(
  ({
    featureIndex,
    testFeatures,
    availableIds,
    mapStructs,
    updateAvailablesIds,
    handleAddFeatureProperty,
    updateMapStructs,
    updateFeatures,
    deleteFeatureProperty,
  }) => {
    const geoJsonProperties = testFeatures.features[featureIndex].properties;
    const isHasProperties = Object.keys(geoJsonProperties || {}).length > 0;
    const featureInfoList =
      isHasProperties && (geoJsonProperties?.feature_info_list || []);
    const featureId =
      testFeatures.features[featureIndex].properties?.feature_id;

    const makeLaneIdAvailable = (laneId: string, elementType: string) => {
      // handle NULL and NEW line ids
      if (elementType === MapConst.NULL_STRING_NAME || !laneId) {
        return;
      }

      const leftBoundaryLine = availableIds.lane_ids_for_left_boundary_line;
      const rightBoundaryLine = availableIds.lane_ids_for_right_boundary_line;
      const startLine = availableIds.lane_ids_for_start_line;
      const terminationLine = availableIds.lane_ids_for_termination_line;
      const stopSignControlLine =
        availableIds.lane_ids_for_stop_sign_control_line;
      const stopSign = availableIds.lane_ids_for_stop_sign;
      const intersection = availableIds.lane_ids_for_intersection;

      if (elementType === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
        leftBoundaryLine.add(laneId);
      } else if (
        elementType === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
      ) {
        rightBoundaryLine.add(laneId);
      } else if (elementType === MapConst.LANE_START_LINE_STRING_NAME) {
        startLine.add(laneId);
      } else if (elementType === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
        terminationLine.add(laneId);
      } else if (elementType === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
        stopSignControlLine.add(laneId);
      } else if (elementType === MapConst.STOP_SIGN_STRING_NAME) {
        stopSign.add(laneId);
      } else if (elementType === MapConst.INTERSECTION_STRING_NAME) {
        intersection.add(laneId);
      }

      updateAvailablesIds({
        temp_lane_ids_for_left_boundary_line: leftBoundaryLine,
        temp_lane_ids_for_right_boundary_line: rightBoundaryLine,
        temp_lane_ids_for_start_line: startLine,
        temp_lane_ids_for_termination_line: terminationLine,
        temp_lane_ids_for_stop_sign_control_line: stopSignControlLine,
        temp_lane_ids_for_stop_sign: stopSign,
        temp_lane_ids_for_intersection: intersection,
      });
    };

    const makeLaneIdAvailableForAllElements = (laneId: string) => {
      makeLaneIdAvailable(laneId, MapConst.LANE_START_LINE_STRING_NAME);
      makeLaneIdAvailable(laneId, MapConst.LANE_TERMINATION_LINE_STRING_NAME);
      makeLaneIdAvailable(laneId, MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME);
      makeLaneIdAvailable(
        laneId,
        MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
      );
      makeLaneIdAvailable(laneId, MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME);
      makeLaneIdAvailable(laneId, MapConst.STOP_SIGN_STRING_NAME);
      makeLaneIdAvailable(laneId, MapConst.INTERSECTION_STRING_NAME);
    };

    const addNewLaneToMapStructs = (laneId: string) => {
      const tempLanes = mapStructs.lanes;
      const newLane: Lane = {
        lane_id: laneId,
        left_boundary_line_id: "",
        right_boundary_line_id: "",
        start_line_id: "",
        termination_line_id: "",
      };
      tempLanes.set(laneId, newLane);
      updateMapStructs({ tempLanes });
    };

    const makeLaneIdUnavailable = (laneId: string, elementType: string) => {
      // handle NULL and NEW line ids
      if (!laneId) {
        return;
      }
      const leftBoundaryLine = availableIds.lane_ids_for_left_boundary_line;
      const rightBoundaryLine = availableIds.lane_ids_for_right_boundary_line;
      const startLine = availableIds.lane_ids_for_start_line;
      const terminationLine = availableIds.lane_ids_for_termination_line;
      const stopSignControlLine =
        availableIds.lane_ids_for_stop_sign_control_line;
      const stopSign = availableIds.lane_ids_for_stop_sign;
      const intersection = availableIds.lane_ids_for_intersection;
      if (elementType === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
        leftBoundaryLine.delete(laneId);
      } else if (
        elementType === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
      ) {
        rightBoundaryLine.delete(laneId);
      } else if (elementType === MapConst.LANE_START_LINE_STRING_NAME) {
        startLine.delete(laneId);
      } else if (elementType === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
        terminationLine.delete(laneId);
      } else if (elementType === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
        stopSignControlLine.delete(laneId);
      } else if (elementType === MapConst.STOP_SIGN_STRING_NAME) {
        stopSign.delete(laneId);
      } else if (elementType === MapConst.INTERSECTION_STRING_NAME) {
        intersection.delete(laneId);
      }

      updateAvailablesIds({
        temp_lane_ids_for_left_boundary_line: leftBoundaryLine,
        temp_lane_ids_for_right_boundary_line: rightBoundaryLine,
        temp_lane_ids_for_start_line: startLine,
        temp_lane_ids_for_termination_line: terminationLine,
        temp_lane_ids_for_stop_sign_control_line: stopSignControlLine,
        temp_lane_ids_for_stop_sign: stopSign,
        temp_lane_ids_for_intersection: intersection,
      });
    };

    return (
      <FeaturePropertiesTableContainer>
        {isHasProperties && (
          <>
            {[
              <Container
                key={featureIndex + "_" + MapConst.FEATURE_ID_STRING_NAME}
              >
                <Row>
                  <Col xs={6}>
                    <b>FEATURE ID</b>
                  </Col>
                  <Col xs={6}>
                    <b>{featureId}</b>
                  </Col>
                </Row>
              </Container>,
              featureInfoList.map(
                (featureInfoItem: FeatureInfo, featureInfoIndex: number) => (
                  <FeaturePropertiesBodyStyled
                    key={
                      featureIndex + "_" + featureInfoIndex + "_feature_info"
                    }
                  >
                    <FeaturePropertiesFeatureInfoStyled className="feature-property-info">
                      {MapConst.LINE_TYPE_STRING_NAME in featureInfoItem ? (
                        <LineStringFeature
                          mapStructs={mapStructs}
                          testFeatures={testFeatures}
                          availableIds={availableIds}
                          updateMapStructs={updateMapStructs}
                          updateFeatures={updateFeatures}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                          makeLaneIdAvailable={makeLaneIdAvailable}
                          makeLaneIdAvailableForAllElements={
                            makeLaneIdAvailableForAllElements
                          }
                          addNewLaneToMapStructs={addNewLaneToMapStructs}
                          makeLaneIdUnavailable={makeLaneIdUnavailable}
                        />
                      ) : (
                        <PolygonFeature
                          mapStructs={mapStructs}
                          updateMapStructs={updateMapStructs}
                          availableIds={availableIds}
                          updateFeatures={updateFeatures}
                          testFeatures={testFeatures}
                          feature_info={featureInfoItem}
                          feature_index={featureIndex}
                          feature_info_index={featureInfoIndex}
                          makeLaneIdAvailable={makeLaneIdAvailable}
                          makeLaneIdAvailableForAllElements={
                            makeLaneIdAvailableForAllElements
                          }
                          addNewLaneToMapStructs={addNewLaneToMapStructs}
                          makeLaneIdUnavailable={makeLaneIdUnavailable}
                        />
                      )}
                    </FeaturePropertiesFeatureInfoStyled>
                    <DeleteFeaturePropertiesStyled
                      onClick={() =>
                        deleteFeatureProperty(featureIndex, featureInfoIndex)
                      }
                    >
                      <XCircleFill size={24} color={"red"} />
                    </DeleteFeaturePropertiesStyled>
                  </FeaturePropertiesBodyStyled>
                )
              ),
            ]}
          </>
        )}

        <button onClick={(e) => handleAddFeatureProperty(e, featureIndex)}>
          Add New Feature Properties
        </button>
      </FeaturePropertiesTableContainer>
    );
  }
);
