import GL from "@luma.gl/constants";
import { isClientRendered } from "../utils/index";

import yaml from "js-yaml";
import fs from "fs";

export const fetchMapOrigin = () => {
  const LOCATION_ID = window._envK8S_?.location_id || "US";
  const location_id_specific_config_file_path =
    window._envK8S_?.REACT_APP_LOCATION_PATH + LOCATION_ID + "/metadata.yaml";

  let latitude = 12.3;
  let longitude = -45.6;

  try {
    if (!isClientRendered) {
      const fileContents = fs.readFileSync(
        location_id_specific_config_file_path
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = yaml.safeLoad(fileContents);
      latitude = data.origin.position.lat_deg;
      longitude = data.origin.position.lon_deg;
    }
  } catch (e) {
    console.log(
      "unable to fetch map origin lat long with location_id: " + LOCATION_ID
    );
  }

  return {
    type: "FeatureCollection",
    features: [],
    properties: {
      useLocalCoord: true,
      latLngOrigin: {
        latitude,
        longitude,
      },
    },
  };
};

export const EMPTY_MAP_STRUCTS = {
  lanes: new Map(),
  stop_signs: new Map(),
  intersections: new Map(),
  control_lines: new Map(),
};

export const EMPTY_AVAILABLE_IDS = {
  lane_ids_for_left_boundary_line: new Set<string>(),
  lane_ids_for_right_boundary_line: new Set<string>(),
  lane_ids_for_start_line: new Set<string>(),
  lane_ids_for_termination_line: new Set<string>(),
  lane_ids_for_stop_sign_control_line: new Set<string>(),
  lane_ids_for_stop_sign: new Set<string>(),
  lane_ids_for_intersection: new Set<string>(),
};

export const getEmptyFeatureCollection = (lat: any, long: any) => {
  return {
    type: "FeatureCollection",
    features: [],
    properties: {
      useLocalCoord: true,
      latLngOrigin: {
        latitude: lat,
        longitude: long,
      },
    },
  };
};

export const LINE_TYPE_STRING_NAME = "line_type";
export const LANE_START_LINE_STRING_NAME = "lane_start_line";
export const LANE_TERMINATION_LINE_STRING_NAME = "lane_termination_line";
export const LANE_LEFT_BOUNDARY_LINE_STRING_NAME = "lane_left_boundary_line";
export const LANE_RIGHT_BOUNDARY_LINE_STRING_NAME = "lane_right_boundary_line";
export const STOP_SIGN_CONTROL_LINE_STRING_NAME = "stop_sign_control_line";

export const POLYGON_TYPE_STRING_NAME = "polygon_type";
export const STOP_SIGN_STRING_NAME = "stop_sign";
export const INTERSECTION_STRING_NAME = "intersection";

export const LANE_ASSOCIATION_STRING_NAME = "lane_association";
export const CONTROL_LINE_ID_STRING_NAME = "control_line_id";
export const STOP_SIGN_ASSOCIATION_STRING_NAME = "stop_sign_association";

export const STOP_SIGN_ID_STRING_NAME = "stop_sign_id";
export const CONTROL_LINE_ASSOCIATION_STRING_NAME = "control_line_association";

export const FEATURE_ID_STRING_NAME = "feature_id";

export const INTERSECTION_ID_STRING_NAME = "intersection_id";

export const NULL_STRING_NAME = "NULL";
export const NEW_STRING_NAME = "NEW";

export const INVALID_ID_NUMBER = -1;
export const SMALLEST_UNIQUE_ID = 1000000;

export const MIN_RADIUS = 0.5; // (based on vehicle turn radius 1 meter)
export const DIST_BETWEEN_POINTS = 0.5;

export const SNAP_MIN_DISTANCE = 0.0003; // 30 cmts

export const DEBUG_LANE_SEGMENT = false; // Debug lane segment clickable area

export const COLOR_COUNT = 5;
export const MAX_UNDO = 10;

export const TANGENT_LINE_LIMIT = 2;

export const ESCAPE_KEY = 27;

export const VERIFY_FIELDS = new Map([
  [LANE_START_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_TERMINATION_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_LEFT_BOUNDARY_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [LANE_RIGHT_BOUNDARY_LINE_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [INTERSECTION_STRING_NAME, [LANE_ASSOCIATION_STRING_NAME]],
  [
    STOP_SIGN_STRING_NAME,
    [LANE_ASSOCIATION_STRING_NAME, CONTROL_LINE_ASSOCIATION_STRING_NAME],
  ],
  [
    STOP_SIGN_CONTROL_LINE_STRING_NAME,
    [LANE_ASSOCIATION_STRING_NAME, STOP_SIGN_ASSOCIATION_STRING_NAME],
  ],
]);

export const modeConfigAdditionalSnapTargets = [
  {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-122.52235, 37.734008],
          [-122.52217, 37.712706],
          [-122.49436, 37.711979],
          [-122.49725, 37.734306],
          [-122.52235, 37.734008],
        ],
      ],
    },
  },
];

// Demonstrate how to override sub layer properties
const _subLayerProps = {
  tooltips: {
    getColor: [255, 255, 255, 255],
  },
};

export const defaultGeoJsonLayerProps = {
  id: "geojson",
  autoHighlight: false,
  // test using icons for edit handles
  // editHandleIconAtlas: iconSheet,
  editHandleIconMapping: {
    intermediate: {
      x: 0,
      y: 0,
      width: 58,
      height: 58,
      mask: false,
    },
    existing: {
      x: 58,
      y: 0,
      width: 58,
      height: 58,
      mask: false,
    },
    "snap-source": {
      x: 58,
      y: 0,
      width: 58,
      height: 58,
      mask: false,
    },
    "snap-target": {
      x: 0,
      y: 0,
      width: 58,
      height: 58,
      mask: false,
    },
  },
  getEditHandleIconSize: 40,
  // Specify the same GeoJsonLayer props
  // lineWidthMinPixels: 2,
  pointRadiusMinPixels: 5,
  // getLineDashArray: () => [0, 0],

  // Accessors receive an isSelected argument
  getFillColor: "#ffffff",

  // Can customize editing points props
  editHandlePointRadiusScale: 2,

  _subLayerProps,

  // customize tentative feature style
  // getTentativeLineDashArray: () => [7, 4],
  // getTentativeLineColor: () => [0x8f, 0x8f, 0x8f, 0xff],
  parameters: {
    depthTest: true,
    depthMask: true,

    blend: true,
    blendEquation: GL.FUNC_ADD,
    blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
  },
};
