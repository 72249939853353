import { Col, DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const UniqColStyled = styled(Col)`
  padding: 0;
`;

export const DropdownButtonStyled = styled(DropdownButton)`
  padding: 0;
`;
