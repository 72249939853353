import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, Container, Navbar, ListGroup } from "react-bootstrap";

import NewPointCloudModal from "../../components/common/projectModal/newPointCloudModal";
import NewProjectModal from "../../components/common/projectModal/newProjectModal";
import EditPointCloudModal from "../../components/common/projectModal/editPointCloudModal";
import EditProjectModal from "../../components/common/projectModal/editProjectModal";
import LoaderSpinner from "../../components/common/loaderSpinner";
import {
  selectProjectsList,
  createProject,
  deleteProject,
  updateProject,
} from "../../redux/slices/projectsList";
import {
  selectCloudList,
  createPointCloud,
  deletePointCloud,
  updatePointCloud,
} from "../../redux/slices/cloudList";
import { getIsLoading, getInitialData } from "../../redux/slices/appState";
import { collectCurrentMapData } from "../../redux/slices/currentMap";
import { BASE } from "../../api";

import {
  ContainerStyled,
  RowStyled,
  ButtonStyled,
  LinkStyled,
  RowBootstrapStyled,
  ColStyled,
  ButtonContainerStyled,
  ListGroupItemStyled,
} from "./styles";

interface ProjectsMenuProps {
  setShowReportModal?: () => void;
}

const ProjectsMenu: React.FC<ProjectsMenuProps> = ({ setShowReportModal }) => {
  const [showNewCloudModal, setShowNewCloudModal] = useState<boolean>(false);
  const [showEditCloudModal, setShowEditCloudModal] = useState<any>({});
  const [showEditProjectModal, setShowEditProjectModal] = useState<any>({});
  const [showNewProjectModal, setShowNewProjectModal] =
    useState<boolean>(false);
  const projectsList = useSelector(selectProjectsList);
  const cloudList = useSelector(selectCloudList);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOutAction = () => {
    window.localStorage.removeItem("JWT");
    navigate("/login");
  };

  useEffect(() => {
    dispatch(getInitialData(() => navigate("/login")));
  }, [dispatch, navigate]);

  return (
    <div>
      {isLoading && <LoaderSpinner />}
      <EditPointCloudModal
        data={showEditCloudModal}
        handleClose={() => setShowEditCloudModal({})}
        updatePointCloud={updatePointCloud}
      />
      <EditProjectModal
        data={showEditProjectModal}
        updateProject={updateProject}
        handleClose={() => setShowEditProjectModal({})}
      />
      <NewProjectModal
        createProject={createProject}
        dropdownItems={cloudList || []}
        show={showNewProjectModal}
        handleClose={() => setShowNewProjectModal(false)}
      />
      <NewPointCloudModal
        show={showNewCloudModal}
        handleClose={() => setShowNewCloudModal(false)}
        createPointCloud={createPointCloud}
      />
      <Container>
        <ContainerStyled>
          <RowStyled>
            <Navbar.Brand>Point Cloud List</Navbar.Brand>
            <ListGroupItemStyled>
              <RowBootstrapStyled>
                <ColStyled>Name</ColStyled>
                <ColStyled xs={3}>Description</ColStyled>
                <ColStyled>Created</ColStyled>
                <ColStyled>Modified</ColStyled>
                <ColStyled xs={4}>Actions</ColStyled>
              </RowBootstrapStyled>
            </ListGroupItemStyled>
            <ListGroup>
              <ListGroupItemStyled>
                {cloudList.map(
                  ({ _id, created, description, name, updated }: any) => {
                    return (
                      <RowBootstrapStyled key={_id}>
                        <ColStyled>{name}</ColStyled>
                        <ColStyled xs={3}>{description}</ColStyled>
                        <ColStyled>
                          {new Date(created).toLocaleDateString()}
                        </ColStyled>
                        <ColStyled>
                          {new Date(updated).toLocaleDateString()}
                        </ColStyled>
                        <ColStyled xs={4}>
                          <ButtonGroup size="sm">
                            <ButtonStyled
                              onClick={() =>
                                setShowEditCloudModal({
                                  _id,
                                  name,
                                  description,
                                })
                              }
                            >
                              Edit
                            </ButtonStyled>
                            <ButtonStyled
                              onClick={() => dispatch(deletePointCloud(_id))}
                              variant="danger"
                            >
                              Delete
                            </ButtonStyled>
                          </ButtonGroup>
                        </ColStyled>
                      </RowBootstrapStyled>
                    );
                  }
                )}
              </ListGroupItemStyled>
            </ListGroup>
          </RowStyled>
          <RowStyled>
            <ButtonStyled
              onClick={() => setShowNewCloudModal(true)}
              variant="success"
            >
              New
            </ButtonStyled>
          </RowStyled>
        </ContainerStyled>
        <ContainerStyled>
          <RowStyled>
            <Navbar.Brand>Projects</Navbar.Brand>
            <ListGroupItemStyled>
              <RowBootstrapStyled>
                <ColStyled>Name</ColStyled>
                <ColStyled xs={3}>Description</ColStyled>
                <ColStyled>Created</ColStyled>
                <ColStyled>Modified</ColStyled>
                <ColStyled xs={4}>Actions</ColStyled>
              </RowBootstrapStyled>
            </ListGroupItemStyled>
            <ListGroup>
              <ListGroupItemStyled>
                {projectsList.map(
                  ({
                    _id,
                    created,
                    description,
                    name,
                    updated,
                    latitude,
                    longitude,
                    pointCloudId,
                  }: any) => {
                    return (
                      <RowBootstrapStyled key={_id}>
                        <ColStyled>{name}</ColStyled>
                        <ColStyled xs={3}>{description}</ColStyled>
                        <ColStyled>
                          {new Date(created).toLocaleDateString()}
                        </ColStyled>
                        <ColStyled>
                          {new Date(updated).toLocaleDateString()}
                        </ColStyled>
                        <ColStyled xs={4}>
                          <ButtonContainerStyled>
                            <ButtonGroup size="sm">
                              <ButtonStyled
                                onClick={() =>
                                  setShowEditProjectModal({
                                    _id,
                                    name,
                                    description,
                                    latitude,
                                    longitude,
                                    pointCloudId,
                                  })
                                }
                              >
                                Configure
                              </ButtonStyled>
                              <ButtonStyled
                                onClick={async () => {
                                  dispatch(
                                    await collectCurrentMapData({
                                      _id,
                                      latitude,
                                      longitude,
                                    })
                                  );
                                  navigate(`/mapEditor/${_id}`);
                                }}
                              >
                                Edit Map
                              </ButtonStyled>
                            </ButtonGroup>
                            <ButtonGroup size="sm">
                              <ButtonStyled>
                                <LinkStyled
                                  to={`${BASE}map-projects/${_id}/semantic_map.geojson`}
                                  target="_blank"
                                  download="semantic_map.geojson"
                                  rel="noreferrer"
                                >
                                  Download Map
                                </LinkStyled>
                              </ButtonStyled>
                              <ButtonStyled
                                onClick={() => dispatch(deleteProject(_id))}
                                variant="danger"
                              >
                                Delete
                              </ButtonStyled>
                            </ButtonGroup>
                          </ButtonContainerStyled>
                        </ColStyled>
                      </RowBootstrapStyled>
                    );
                  }
                )}
              </ListGroupItemStyled>
            </ListGroup>
          </RowStyled>
          <RowStyled>
            <ButtonStyled
              variant="success"
              onClick={() => setShowNewProjectModal(true)}
            >
              New
            </ButtonStyled>
          </RowStyled>
          {setShowReportModal && (
            <RowStyled>
              <ButtonStyled onClick={setShowReportModal}>
                Send Report
              </ButtonStyled>
            </RowStyled>
          )}
          <ButtonStyled onClick={logOutAction} variant="danger">
            Log Out
          </ButtonStyled>
        </ContainerStyled>
      </Container>
    </div>
  );
};

export default ProjectsMenu;
