import React from "react";
import { HiddenCheckbox, Label, StyledCheckbox, Icon } from "./styles";

interface CheckboxProps {
  onChange: any;
  checked: boolean;
}

export const Checkbox = ({ checked, onChange }: CheckboxProps) => {
  return (
    <Label>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </Label>
  );
};
