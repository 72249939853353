import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { BaseError } from "../../types";
import API from "../../api";

import { setIsLoading } from "./appState";

export const fetchCloudList = createAsyncThunk(
  "cloudList/fetchCloudList",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    try {
      const response = await API.get(`point-clouds`);
      thunkAPI.dispatch(setValue(response.data.pointClouds));
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with fetchCloudList");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);

export const fetchPointCloud = createAsyncThunk(
  "cloudList/fetchPointCloud",
  async (pointCloudId, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    const response = await API.get(`point-clouds/${pointCloudId}`);
    thunkAPI.dispatch(setIsLoading(false));
    return response.data;
  }
);

export const updatePointCloud = createAsyncThunk(
  "cloudList/updatePointCloud",
  async ({ _id, name, description }: any, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    const response = await API.put(`point-clouds/${_id}`, {
      name,
      description,
    });
    thunkAPI.dispatch(fetchCloudList());
    thunkAPI.dispatch(setIsLoading(false));
    return response.data;
  }
);

export const deletePointCloud = createAsyncThunk(
  "cloudList/deletePointCloud",
  async (pointCloudId: string, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    const response = await API.delete(`point-clouds/${pointCloudId}`);
    thunkAPI.dispatch(fetchCloudList());
    thunkAPI.dispatch(setIsLoading(false));
    return response.data;
  }
);

export const createPointCloud = createAsyncThunk(
  "cloudList/createPointCloud",
  async ({ name, description, file }: any, thunkAPI) => {
    const jwt = window.localStorage.getItem("JWT");
    thunkAPI.dispatch(setIsLoading(true));

    try {
      const fileDataForm = new FormData();
      fileDataForm.append("ply_file", file);

      const responseNewPointCLoud = await API.post(`point-cloud`, {
        name,
        description,
        points: "",
      });

      await API.post(
        `point-clouds/${responseNewPointCLoud.data._id}/point-cloud-file`,
        fileDataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: jwt!,
          },
        }
      );

      thunkAPI.dispatch(fetchCloudList());
      thunkAPI.dispatch(setIsLoading(false));

      return responseNewPointCLoud.data._id;
    } catch (err) {
      thunkAPI.dispatch(setIsLoading(false));
      console.log("error", err);
    }
  }
);

export const Slice = createSlice({
  name: "cloudList",
  initialState: [],
  reducers: {
    setValue: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const selectCloudList = (state: any) => state.cloudList;

export const { setValue } = Slice.actions;
export default Slice.reducer;
