import React from "react";
import { Navigate } from "react-router-dom";
import { RouteProps } from "react-router";

export interface ChildrenProps {
  children: RouteProps["children"];
}

export const AuthorizedAppGuard = ({ children }: ChildrenProps) => {
  const isLoggedIn = window.localStorage.getItem("JWT");

  if (!isLoggedIn) return <Navigate to="/login" />;

  return <>{children}</>;
};
