import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { closeAnyModal } from "../../../redux/slices/modals";
import { setSaveSemanticMapFlag } from "../../../redux/slices/appState";

import {
  SaveProgressModalStyled,
  ButtonsContainerStyled,
  CancelButtonStyled,
  SubmitButtonStyled,
} from "./styles";
import { ModalBackgroundStyled, ModalStyled } from "../styles";

export const SaveProgressModal = memo(() => {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const saveProgress = useCallback(() => {
    dispatch(closeAnyModal());
    dispatch(setSaveSemanticMapFlag(true));
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <SaveProgressModalStyled>
        The session will be interrupted after 1 minute. Do you want to save your
        current progress?
        <ButtonsContainerStyled>
          <CancelButtonStyled onClick={handleCloseModal}>No</CancelButtonStyled>
          <SubmitButtonStyled onClick={saveProgress}>Save</SubmitButtonStyled>
        </ButtonsContainerStyled>
      </SaveProgressModalStyled>
    </ModalStyled>
  );
});
