import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import API from "../../api";

import { setIsLoading } from "./appState";

export const getLogin = createAsyncThunk(
  "auth/getLogin",
  async ({ email, password }: any, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    try {
      const response = await API.post("/login/", {
        email,
        password,
      });
      window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
      window.localStorage.setItem(
        "JWTrefresh",
        `Bearer ${response.data.refresh}`
      );
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error: any) {
      let errorMessage = "There was an error logging in.";
      try {
        const message = error.response.data.detail.message;
        if (message) {
          errorMessage = message;
        }
      } catch (e) {
        console.error("There was an error in the response message.");
      }
      thunkAPI.dispatch(setIsLoading(false));
      return new Error(errorMessage);
    }
  }
);

export const verifyMFA = createAsyncThunk(
  "auth/verifyMFA",
  async ({ id, token, code }: any, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    try {
      const response = await API.post("/login", {
        mfa: {
          id,
          token,
          code,
        },
      });
      thunkAPI.dispatch(setIsLoading(false));
      return response.data;
    } catch (error: any) {
      let errorMessage = "An error occured with multi-factor authentication.";
      try {
        const message = error.response.data.detail.message;
        if (message) {
          errorMessage = message;
        }
      } catch (e) {
        console.error("There was an error in the response message.");
      }
      thunkAPI.dispatch(setIsLoading(false));
      return new Error(errorMessage);
    }
  }
);

export const Slice = createSlice({
  name: "auth",
  initialState: {
    jvt: null,
  },
  reducers: {
    setValue: (state, action) => {
      state = action.payload;
    },
  },
});

export const selectProjects = (state: any) => state.user;

export const { setValue } = Slice.actions;
export default Slice.reducer;
