import * as React from "react";
import { ToolboxButton } from "../common/toolbox";
import { FeatureMenuStyled } from "./styles";

export default function FeatureMenu({
  featureMenu,
  featureMenuClick,
}: {
  featureMenuClick: (action: string) => void;
  featureMenu?: { index: number; x: number; y: number };
}) {
  const x = featureMenu?.x || 0;
  const y = featureMenu?.y || 0;

  return (
    <FeatureMenuStyled top={y - 200} left={x + 60}>
      <ToolboxButton onClick={() => featureMenuClick("delete")}>
        Delete
      </ToolboxButton>
      <ToolboxButton onClick={() => featureMenuClick("split")}>
        Split
      </ToolboxButton>
      <ToolboxButton onClick={() => featureMenuClick("info")}>
        Info
      </ToolboxButton>
      <ToolboxButton onClick={() => featureMenuClick("close")}>
        Close
      </ToolboxButton>
    </FeatureMenuStyled>
  );
}
