import styled from "styled-components";
import { Link } from "react-router-dom";
import { ListGroup, Col, Row, Button } from "react-bootstrap";

export const ContainerStyled = styled.div`
  margin-bottom: 24px;
`;

export const RowStyled = styled.div`
  margin: 8px 0;
`;

export const RowBootstrapStyled = styled(Row)`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const ButtonStyled = styled(Button)`
  height: 40px;
  display: flex !important;
  align-items: center;
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 88px;
  justify-content: space-between;
`;

export const LinkStyled = styled(Link)`
  color: #ffffff !important;
  text-decoration: none !important;
`;

export const ColStyled = styled(Col)`
  display: flex !important;
  justify-content: center !important;
`;

export const ListGroupItemStyled = styled(ListGroup.Item)`
  display: flex !important;
  align-items: center !important;
  flex-direction: column;
  justify-content: space-between !important;
  margin-bottom: 8px;
`;
