import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { Modals } from "../../components/Modals";
import {
  getExpirationAccessToken,
  refreshTokenAction,
  getExpirationRefreshToken,
} from "../../redux/slices/appState";
import {
  toggleModal,
  SAVE_PROGRESS_MODAL,
  SHOW_REPORT_MODAL,
} from "../../redux/slices/modals";
import Header from "../../components/Header";

import { AuthorizedAppGuard } from "./AuthorizedAppGuard";
import "./AuthorizedApp.css";

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  box-sizing: border-box;
  width: 100%;
`;

const REFRESH_TIME_DELTA = 1000000;
const LOGOUT_TIME_DELTA = 60000;
let logoutTimer: number | undefined = undefined;
let refreshTimer: number | undefined = undefined;

export const AuthorizedApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const expirationAccessToken = useSelector(getExpirationAccessToken);
  const expirationRefreshToken = useSelector(getExpirationRefreshToken);
  const { pathname } = location;

  useEffect(() => {
    if (expirationRefreshToken && pathname.includes("mapEditor")) {
      //show Save Progress modal before session is clodes
      const timeToShowModal =
        expirationRefreshToken - Date.now() - LOGOUT_TIME_DELTA;

      logoutTimer = window.setTimeout(() => {
        dispatch(toggleModal({ type: SAVE_PROGRESS_MODAL }));
        clearTimeout(logoutTimer);
      }, timeToShowModal);
    }

    return () => clearTimeout(logoutTimer);
  }, [expirationRefreshToken, pathname]);

  useEffect(() => {
    if (expirationAccessToken) {
      //update the token 10 seconds before it expires
      const timeToRefreshToken =
        expirationAccessToken - Date.now() - REFRESH_TIME_DELTA;

      refreshTimer = window.setTimeout(() => {
        dispatch(refreshTokenAction(() => navigate("/login")));
        clearTimeout(refreshTimer);
      }, timeToRefreshToken);
    }

    return () => clearTimeout(refreshTimer);
  }, [expirationAccessToken]);

  return (
    <>
      <AppStyled>
        <Header
          handleReportModal={() =>
            dispatch(toggleModal({ type: SHOW_REPORT_MODAL }))
          }
        />
        <Modals />
        <AuthorizedAppGuard>
          <Outlet />
        </AuthorizedAppGuard>
      </AppStyled>
    </>
  );
};
