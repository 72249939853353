import axios from "axios";
import { toast } from "react-toastify";

import { sendSentryEvent } from "./utils/sentryUtils";

export const API_URL = window._envK8S_?.REACT_APP_API_URL || "";
const API_VERSION = window._envK8S_?.REACT_APP_API_VERSION || "v1";

export const BASE = `${API_URL}api/${API_VERSION}/`;

const instance = axios.create({
  baseURL: BASE,
});

instance.defaults.headers.common["Authorization"] =
  window.localStorage.getItem("JWT");

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    toast.error("API Error", error);
    sendSentryEvent(error, "API error");
    throw error;
  }
);

instance.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("JWT");
  config.headers.Authorization = token;

  return config;
});

export default instance;
