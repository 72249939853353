import {
  ClickEvent,
  FeatureCollection,
  ModeProps,
  ModifyMode,
} from "@nebula.gl/edit-modes";
import { Bezier } from "bezier-js";
import { toMercator } from "@turf/projection";
import { LineString, Position } from "geojson";
import merge from "lodash/merge";

import {
  getPickedExistingEditHandle,
  getPickedIntermediateEditHandle,
} from "./nebula.gl";
import { pointToCoor } from ".";
import { MIN_RADIUS, DIST_BETWEEN_POINTS } from "../constants/map-constants";

/**
 * Calculate a curve for a line based of a radius
 * @see https://docs.google.com/presentation/d/15STp6d6xa_4uOiIl2L5QMkte_dMcHU8dqYu_ZC08Wb8/edit#slide=id.g21806e960f5_0_18
 * @param line line to be affected
 * @param radius radius to be used
 * @param position position of the point in the coordinates array
 * @param vertex unused for now. Could be useful to calculate the curve based on the closest point
 */
export const calculateCurve = (
  line: LineString,
  radius: number,
  position: number,
  vertex?: Position
): Position[] => {
  const coordinates = line.coordinates;

  // Check validity of radius value
  if (radius < MIN_RADIUS) {
    throw Error("Invalid values");
  }

  const a = toMercator(coordinates[position - 1]); // toMercator --> mts
  const b = toMercator(vertex || coordinates[position]);
  const c = toMercator(coordinates[position + 1]);

  const bzLine = new Bezier([...a, ...b, ...c]);
  const lut = bzLine.getLUT(Math.round(bzLine.length() / DIST_BETWEEN_POINTS));
  const parsedLut = lut.map((p) => pointToCoor(p));

  // Replace ABC with line segment
  coordinates.splice(position - 1, 3, ...parsedLut);

  return coordinates;
};

export class AlterCurvedEdgeMode extends ModifyMode {
  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    const pickedExistingHandle = getPickedExistingEditHandle(event.picks);
    const pickedIntermediateHandle = getPickedIntermediateEditHandle(
      event.picks
    );
    const edit = {
      updatedData: props.data,
      editType: "radiusDialog",
      editContext: {
        position: null,
        radiusDialog: true,
      },
    };

    if (pickedExistingHandle) {
      const { featureIndex, positionIndexes } = pickedExistingHandle.properties;

      props.onEdit(
        merge(edit, {
          editContext: {
            featureIndex,
            positionIndexes,
          },
        })
      );
    } else if (pickedIntermediateHandle) {
      const { featureIndex, positionIndexes } =
        pickedIntermediateHandle.properties;

      props.onEdit(
        merge(edit, {
          editContext: {
            featureIndex,
            positionIndexes,
            position: pickedIntermediateHandle.geometry.coordinates,
          },
        })
      );
    }

    // super.handleClick(event, props);
  }

  handleDragging(): void {
    // Override to do nothing on dragging
  }

  handleStartDragging() {
    // Override to do nothing on dragging
  }

  handleStopDragging() {
    // Override to do nothing on dragging
  }
}
