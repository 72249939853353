import { RefObject, useEffect, MouseEvent } from "react";

export function useOnClickOutside(
  ref: RefObject<HTMLDivElement>,
  callback: () => void
) {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (ref.current && !ref.current.contains(e.target as Element)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick as any);

    return () => {
      document.removeEventListener("click", handleClick as any);
    };
  });
}
