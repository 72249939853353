import * as React from "react";
import { ArrowCounterclockwise, ArrowReturnLeft } from "react-bootstrap-icons";
import {
  MeasureDistanceMode,
  ModifyMode,
  SnappableMode,
} from "@nebula.gl/edit-modes";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";

import { ALL_MODES, TWO_CLICK_POLYGON_MODES } from "../../constants/nebula";
import {
  Toolbox,
  ToolboxButton,
  ToolboxCheckbox,
  ToolboxControl,
  ToolboxRow,
  ToolboxSelect,
  ToolboxLabel,
} from "../../components/common/toolbox";
import {
  add_deploy_properties_to_geojson,
  verify_features,
  verify_lanes,
} from "../../utils/toolbox";
import { GeoJSONInterface } from "../../models/map-interface.d";
import FeatureItem from "../FeatureItem";
import {
  GeoJsonType,
  GeoJsonUploadType,
} from "../../pages/MapEditor/mapEditor";
import * as Utils from "../../utils";
import MeasureElements from "../MeasureElements";

import { MapToolboxProps, ModeType } from "./index.d";
import { ToolboxControlTextareaStyled, ToolboxTitleStyled } from "./styles";
import { CONFIRM_ACTION_MODAL } from "../../redux/slices/modals";

export class MapToolbox extends React.Component<
  MapToolboxProps,
  {
    category?: string;
    pointcloudURL: string | null;
    editableGeoJsonLayer: EditableGeoJsonLayer | null;
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      pointcloudURL: null,
      category: "View",
      editableGeoJsonLayer: null,
    };
  }

  backAction = () => {
    window.location.pathname = "/";
  };

  undoAction = () => {
    this.props.undo();
  };

  _setCategory(category: any): void {
    this.setState({ category });
  }

  getOutput = (): GeoJSONInterface => ({
    type: "FeatureCollection",
    properties: {
      latLngOrigin: this.props.pointcloudOrigin,
      useLocalCoord: this.props.useLocalCoord,
      radius: this.props.radius,
    },
    features: this.props.testFeatures.features,
    map_structs: this.props.mapStructs,
    available_ids: this.props.availableIds,
  });

  _deploy = () => {
    // overwrite the geojson coordinate info when download
    // No need to re-render
    const output = add_deploy_properties_to_geojson(this.getOutput());

    console.log(output);

    // TODO: Call API to Generate files
    // this.props.dispatch(
    //  deployMapGeoJson({ file, projectId: this.props.currentMapProjectId })
    // );
  };

  _download = () => {
    const output = this.getOutput();
    const { testFeatures, mapStructs } = this.props;
    // verify geojson has no missing field
    if (!verify_features(testFeatures.features)) {
      return;
    }

    // verify lane elements completeness
    if (!verify_lanes(mapStructs.lanes)) {
      return;
    }

    // overwrite the geojson coordinate info when download
    // No need to re-render
    // const output = this.getOutput();

    const blob = new Blob([JSON.stringify(output, Utils.replacer)], {
      type: "octet/stream",
    });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "nebula.geojson";
    a.click();
  };

  saveGeoJson = (geojsonType: GeoJsonType) => {
    const { saveMapGeoJson, currentMapProjectId } = this.props;
    const output = this.getOutput();

    const file = new Blob([JSON.stringify(output, Utils.replacer)], {
      type: "octet/stream",
    });

    const fileName = `output_${geojsonType}.geojson`;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    saveMapGeoJson({
      file,
      fileName,
      projectId: currentMapProjectId,
    });
  };

  _loadSample = (type: string, mergeType?: GeoJsonUploadType) => {
    const { parseStringJson, updatePointCloudJson } = this.props;

    if (type === "geojson") {
      const el = document.createElement("input");
      el.type = "file";
      el.onchange = (e) => {
        const eventTarget = e.target as HTMLInputElement;
        if (eventTarget.files && eventTarget.files[0]) {
          const reader = new FileReader();
          reader.onload = ({ target }) => {
            parseStringJson(target?.result as string, mergeType);
          };
          reader.readAsText(eventTarget.files[0]);
        }
      };
      el.click();
    } else if (type === "ply") {
      const el = document.createElement("input");
      el.type = "file";
      el.onchange = (e) => {
        const eventTarget = e.target as HTMLInputElement;
        if (eventTarget.files && eventTarget.files[0]) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(eventTarget.files[0]);
          reader.onload = ({ target }) => {
            const blob = new Blob([target?.result as BlobPart], {
              type: "application/octet-stream",
            });
            if (this.state.pointcloudURL) {
              window.webkitURL.revokeObjectURL(this.state.pointcloudURL);
            }
            const pointcloudURL = window.webkitURL.createObjectURL(blob);
            this.setState({
              pointcloudURL,
            });
            updatePointCloudJson(pointcloudURL);
          };
        }
      };
      el.click();
    }
  };

  _renderTwoClickPolygonControls() {
    const { modeConfig, updateModeConfig } = this.props;

    return (
      <ToolboxRow key="twoClick">
        <ToolboxTitleStyled>Drag to draw</ToolboxTitleStyled>
        <ToolboxControl>
          <ToolboxCheckbox
            checked={Boolean(modeConfig && modeConfig.dragToDraw)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateModeConfig({
                dragToDraw: Boolean(event.target.checked),
              })
            }
          />
        </ToolboxControl>
      </ToolboxRow>
    );
  }

  _renderModifyModeControls() {
    const { pointsRemovable, updatePointsRemovable } = this.props;

    return (
      <ToolboxRow key="modify">
        <ToolboxTitleStyled>Allow removing points</ToolboxTitleStyled>
        <ToolboxControl>
          <ToolboxCheckbox
            checked={pointsRemovable}
            onChange={() => updatePointsRemovable()}
          />
        </ToolboxControl>
      </ToolboxRow>
    );
  }

  _renderSnappingControls() {
    const { modeConfig, updateModeConfig } = this.props;

    return (
      <ToolboxRow key="snap">
        <ToolboxTitleStyled>Enable snapping</ToolboxTitleStyled>
        <ToolboxControl>
          <ToolboxCheckbox
            checked={Boolean(modeConfig && modeConfig.enableSnapping)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateModeConfig({
                enableSnapping: Boolean(event.target.checked),
              })
            }
          />
        </ToolboxControl>
      </ToolboxRow>
    );
  }

  _renderMeasureDistanceControls() {
    const { modeConfig, updateModeConfig } = this.props;

    return (
      <ToolboxRow key="measure-distance">
        <ToolboxTitleStyled>Units</ToolboxTitleStyled>
        <ToolboxControl>
          <ToolboxSelect
            value={modeConfig?.turfOptions?.units}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              updateModeConfig({
                turfOptions: { units: event.target.value },
              })
            }
            options={["meters", "miles", "degrees", "radians"]}
          ></ToolboxSelect>
        </ToolboxControl>
      </ToolboxRow>
    );
  }

  _renderModeConfigControls() {
    const { mode } = this.props;
    const controls: JSX.Element[] = [];

    if (TWO_CLICK_POLYGON_MODES.indexOf(mode) > -1) {
      controls.push(this._renderTwoClickPolygonControls());
    }
    if (mode === ModifyMode) {
      controls.push(this._renderModifyModeControls());
    }
    if (mode instanceof SnappableMode) {
      controls.push(this._renderSnappingControls());
    }
    if (mode === MeasureDistanceMode) {
      controls.push(this._renderMeasureDistanceControls());
    }

    return controls;
  }

  // TODO: Unused?
  _renderSplitModeControls() {
    const { modeConfig, updateModeConfig } = this.props;

    return (
      <ToolboxRow key="split">
        <ToolboxTitleStyled>Constrain to 90&deg;</ToolboxTitleStyled>
        <ToolboxControl>
          <ToolboxCheckbox
            checked={Boolean(modeConfig && modeConfig.lock90Degree)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              updateModeConfig({
                lock90Degree: Boolean(event.target.checked),
              })
            }
          />
        </ToolboxControl>
      </ToolboxRow>
    );
  }

  _buildSelectFeatureCheckboxes() {
    const {
      testFeatures: { features },
    } = this.props;
    const checkboxes: JSX.Element[] = [];
    // TODO: Send only required properties
    features.forEach((v, i) =>
      checkboxes.push(
        <FeatureItem
          deleteFeatureProperty={this.props.deleteFeatureProperty}
          testFeatures={this.props.testFeatures}
          availableIds={this.props.availableIds}
          mapStructs={this.props.mapStructs}
          selectedFeatureIndexes={this.props.selectedFeatureIndexes}
          featureMenuClick={this.props.featureMenuClick}
          updateAvailablesIds={this.props.updateAvailablesIds}
          updateMapStructs={this.props.updateMapStructs}
          updateFeatures={this.props.updateFeatures}
          updateSelectedFeatureIndexes={this.props.updateSelectedFeatureIndexes}
          key={`feature-item-${i}`}
          index={i}
          featureType={v?.geometry?.type}
        />
      )
    );

    return checkboxes;
  }

  _buildMode({ note, label, mode }: ModeType, category: string) {
    if (note) {
      return <ToolboxLabel key={label}>{label}</ToolboxLabel>;
    }

    return (
      <ToolboxButton
        key={label}
        selected={this.props.mode === mode}
        onClick={() => this.props.updateMode(mode, category)}
      >
        {label}
      </ToolboxButton>
    );
  }

  render() {
    const {
      geojsonType,
      undoFeatures,
      alterDisabled,
      showGeoJson,
      updateShowGeoJson,
      testFeatures,
      updateFeatures,
      onGeojsonTypeSelect,
      renderMapboxLayer,
      updateRenderMapboxLayer,
      toggleLayerVisibility,
      editableGeoJsonLayerVisible,
      toggleEditableGeoJsonLayerVisibility,
      clearSelectedFeatures,
      layerVisibility,
      measureFeatures,
      modeConfig,
      removeMeasureElements,
      toggleModalAction,
    } = this.props;

    return (
      <Toolbox>
        <ToolboxButton onClick={this.backAction}>
          <ArrowReturnLeft /> Back
        </ToolboxButton>
        <ToolboxButton
          disabled={undoFeatures.length === 0}
          onClick={this.undoAction}
        >
          <ArrowCounterclockwise /> Undo
        </ToolboxButton>
        {ALL_MODES.map((category: { category: string; modes: ModeType[] }) => {
          const disabled = category.category === "Alter" && alterDisabled;
          return (
            <ToolboxRow key={category.category}>
              <ToolboxTitleStyled
                isDisabled={disabled}
                onClick={() =>
                  !disabled && this._setCategory(category.category)
                }
                active={category.category === this.state.category}
              >
                {category.category} Modes
              </ToolboxTitleStyled>
              {category.category === this.state.category &&
                category.modes.map((mode) =>
                  this._buildMode(mode, category.category)
                )}
              {category.category === "View" &&
                measureFeatures.features.length !== 0 && (
                  <MeasureElements
                    units={modeConfig?.turfOptions?.units}
                    features={measureFeatures.features}
                    removeMeasureElements={removeMeasureElements}
                  />
                )}
            </ToolboxRow>
          );
        })}
        {this._renderModeConfigControls()}
        {showGeoJson && (
          <React.Fragment>
            <ToolboxTitleStyled>GeoJSON</ToolboxTitleStyled>
            <ToolboxButton onClick={() => updateShowGeoJson()}>
              hide &#9650;
            </ToolboxButton>
            <ToolboxControl>
              <ToolboxControlTextareaStyled
                id="geo-json-text"
                rows={5}
                value={JSON.stringify(testFeatures)}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  updateFeatures(JSON.parse(event.target.value))
                }
              />
            </ToolboxControl>
          </React.Fragment>
        )}
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => this._setCategory("Data")}
            active={this.state.category === "Data"}
          >
            Data
          </ToolboxTitleStyled>
          {this.state.category === "Data" && (
            <>
              <ToolboxButton onClick={() => this._deploy()}>
                Edit and Deploy
              </ToolboxButton>
              <ToolboxButton onClick={() => this._download()}>
                Download
              </ToolboxButton>
              {geojsonType === GeoJsonType.Centerline && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => this._loadSample("geojson")}>
                    Open Centerline GeoJSON
                  </ToolboxButton>
                  <ToolboxButton
                    onClick={() => this.saveGeoJson(GeoJsonType.Centerline)}
                  >
                    Save Centerline GeoJSON
                  </ToolboxButton>
                </ToolboxControl>
              )}
              {geojsonType === GeoJsonType.Semantic && (
                <ToolboxControl>
                  <ToolboxButton onClick={() => this._loadSample("geojson")}>
                    Open Semantic GeoJSON
                  </ToolboxButton>
                  {testFeatures.features.length !== 0 && (
                    <ToolboxButton
                      // onClick={() =>
                      //   this._loadSample("geojson", GeoJsonUploadType.GeoJsonUploadType)
                      // }
                      onClick={() =>
                        toggleModalAction({
                          type: CONFIRM_ACTION_MODAL,
                          data: {
                            text: "Would you like to merge with feature properties?",
                            confirmAction: () =>
                              this._loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithFeatures
                              ),
                            cancelAction: () =>
                              this._loadSample(
                                "geojson",
                                GeoJsonUploadType.MergeWithNoFeatures
                              ),
                          },
                        })
                      }
                    >
                      Merge geojson
                    </ToolboxButton>
                  )}
                  <ToolboxButton
                    onClick={() => this.saveGeoJson(GeoJsonType.Semantic)}
                  >
                    Save Semantic GeoJSON
                  </ToolboxButton>
                </ToolboxControl>
              )}

              <ToolboxControl>
                <ToolboxButton
                  disabled
                  title="Coming soon..."
                  onClick={() => this._loadSample("ply")}
                >
                  Open pointcloud map...
                </ToolboxButton>
              </ToolboxControl>
            </>
          )}
        </ToolboxRow>
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => this._setCategory("Features")}
            active={this.state.category === "Features"}
          >
            Features
          </ToolboxTitleStyled>
          {this.state.category === "Features" && (
            <>
              <ToolboxButton onClick={() => clearSelectedFeatures()}>
                Clear Selection
              </ToolboxButton>
              <ToolboxRow>{this._buildSelectFeatureCheckboxes()}</ToolboxRow>
            </>
          )}
        </ToolboxRow>
        <ToolboxRow>
          <ToolboxTitleStyled
            onClick={() => this._setCategory("Layers")}
            active={this.state.category === "Layers"}
          >
            Map Layers
          </ToolboxTitleStyled>
          {this.state.category === "Layers" && (
            <>
              <ToolboxButton
                onClick={() => toggleEditableGeoJsonLayerVisibility?.()}
              >
                {editableGeoJsonLayerVisible
                  ? "Hide Editable GeoJson Layer"
                  : "Show Editable GeoJson Layer"}
              </ToolboxButton>
              {/* <ToolboxButton
                onClick={() => this.props.toggleEditPlaneLayerVisibility?.()}
              >
                {this.props.layerVisibility.edit_plane_layer
                  ? "Hide Edit Plane Layer"
                  : "Show Edit Plane Layer"}
              </ToolboxButton> */}
              {Object.entries(layerVisibility).map(([layerId, isVisible]) => {
                const index = layerId
                  ? parseInt(layerId.split("_").pop() || "0") + 1
                  : 0;
                return (
                  <ToolboxButton
                    key={layerId}
                    onClick={() => toggleLayerVisibility(layerId)}
                  >
                    {isVisible
                      ? `Hide 3D point Layer ${index}`
                      : `Show 3D point Layer ${index}`}
                  </ToolboxButton>
                );
              })}
              <ToolboxButton
                key={"RenderMapbox"}
                onClick={() => {
                  updateRenderMapboxLayer();
                }}
              >
                {renderMapboxLayer ? "Disable Mapbox" : "Enable Mapbox"}
              </ToolboxButton>
              <ToolboxButton
                onClick={() => onGeojsonTypeSelect(GeoJsonType.Semantic)}
                disabled={geojsonType === GeoJsonType.Semantic}
              >
                {geojsonType === GeoJsonType.Semantic
                  ? "Semantic selected"
                  : "Select Semantic"}
              </ToolboxButton>
              <ToolboxButton
                onClick={() => onGeojsonTypeSelect(GeoJsonType.Centerline)}
                disabled={geojsonType === GeoJsonType.Centerline}
              >
                {geojsonType === GeoJsonType.Centerline
                  ? "Centerline selected"
                  : "Select Centerline"}
              </ToolboxButton>
            </>
          )}
        </ToolboxRow>
      </Toolbox>
    );
  }
}
