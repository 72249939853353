import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";

export const GoogleLoginStyled = styled(GoogleLogin)`
  border-radius: 4px !important;
  border: 2px solid #cfd0d5 !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  transition: border 0.2s !important;
  width: 100%;

  div {
    margin: 0px !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ButtonStyled = styled(Button)`
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FormGroupInputContainerStyled = styled(Form.Group)`
  margin-bottom: 16px;
`;

export const LoginFormContainerStyled = styled(Form)`
  margin: 0 auto;
  max-width: 320px;
`;

export const LoginContainerStyled = styled.div`
  padding: 60px 0;
`;

export const FormGroupUniqContainerStyled = styled(Form.Group)`
  margin-bottom: $spacer !important;
`;

export const DropdownContainerStyled = styled(Dropdown)`
  margin-bottom: 16px;
`;
