import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API from "../../api";
import {
  BaseError,
  UpdateProjectDataType,
  NewProjectDataType,
} from "../../types";
import { RootState } from "../store";

import { createPointCloud } from "./cloudList";
import { setIsLoading } from "./appState";

export const fetchProjectsList = createAsyncThunk(
  "projectsList/fetchProjectsList",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setIsLoading(true));
    try {
      const response = await API.get("map-projects");
      thunkAPI.dispatch(setValue(response.data.mapProjects));
      return response.data;
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with fetchCloudList");
    }
    thunkAPI.dispatch(setIsLoading(false));
  }
);

export const getProject = createAsyncThunk(
  "projectsList/getProject",
  async (projectId: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await API.get(`map-projects/${projectId}`);
      return response.data;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const updateProject = createAsyncThunk(
  "projectsList/updateProject",
  async (data: UpdateProjectDataType, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await API.put(`map-projects/${data._id}`, {
        ...data,
      });
      thunkAPI.dispatch(fetchProjectsList());
      return response.data;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projectsList/deleteProject",
  async (projectId: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await API.delete(`map-projects/${projectId}`);
      thunkAPI.dispatch(fetchProjectsList());
      return response.data;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createProject = createAsyncThunk(
  "projectsList/createProject",
  async (
    {
      name,
      description,
      latitude,
      longitude,
      pointCloudId,
      file,
      cloudName,
      cloudDescription,
    }: NewProjectDataType,
    thunkAPI
  ) => {
    try {
      let pointCloudIdValue = pointCloudId;
      thunkAPI.dispatch(setIsLoading(true));

      if (!pointCloudIdValue) {
        const pointCloudIdResponse = await thunkAPI.dispatch(
          createPointCloud({
            name: cloudName,
            description: cloudDescription,
            file,
          })
        );

        pointCloudIdValue = pointCloudIdResponse.payload;
      }

      const response = await API.post("map-project", {
        name,
        description,
        latitude,
        longitude,
        pointCloudId: pointCloudIdValue,
      });
      thunkAPI.dispatch(fetchProjectsList());
      return response.data;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const getProjectPointCloud = createAsyncThunk(
  "projectsList/getProjectPointCloud",
  async (projectId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await API.get(
        `map-projects/${projectId}/point_cloud_json`
      );
      return response;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const getMapGeoJson = createAsyncThunk(
  "projectsList/getMapGeoJson",
  async (projectId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await API.get(`map-projects/${projectId}/geo_json`);
      return response;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const saveMapGeoJson = createAsyncThunk(
  "projectsList/putMapGeoJson",
  async (
    { projectId, file }: { projectId?: string; file: Blob; fileName: string },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const jwt = window.localStorage.getItem("JWT");
      const fileDataForm = new FormData();
      fileDataForm.append("semantic_map_json_file", file);

      const response = await API.post(
        `map-projects/${projectId}/semantic_map.geojson`,
        fileDataForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: jwt!,
          },
        }
      );
      return response.data;
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const Slice = createSlice({
  name: "projectsList",
  initialState: [],
  reducers: {
    setValue: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const selectProjectsList = (state: RootState) => state.projectsList;

export const { setValue } = Slice.actions;
export default Slice.reducer;
