import React from "react";

import ProjectsMenu from "../pages/ProjectsMenu";
import MapEditor from "../pages/MapEditor";

export const AuthorizedPages = [
  {
    path: "/",
    element: <ProjectsMenu />,
  },
  {
    path: "/mapEditor/:mapProjectId",
    element: <MapEditor />,
  },
];
