import styled from "styled-components";

interface FeatureMenuProps {
  top: number;
  left: number;
}

export const FeatureMenuStyled = styled.div<FeatureMenuProps>`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;
