import React, { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ResizableBox } from "react-resizable";

import FeatureItem from "../FeatureItem";
import { FeatureDialogProps } from "./index.d";
import { Lane } from "../../models/map-interface.d";

import {
  ModalStyled,
  ResizebleHandleSWStyled,
  ResizebleHandleSEStyled,
  ResizebleHandleNWStyled,
  ResizebleHandleNEStyled,
  ResizebleWrapperContentStyled,
} from "./styles";
import {
  DEFAULT_FEATURE_DIALOG_SIZE,
  MINIMUM_FEATURE_DIALOG_SIZE,
} from "./constants";
import "./index.css";

type Position = {
  xRate: number;
  yRate: number;
};

type Size = {
  height: number;
  width: number;
};

const getResizeHandler = (type: string, ref: React.RefObject<SVGSVGElement>) =>
  ({
    sw: <ResizebleHandleSWStyled ref={ref} />,
    se: <ResizebleHandleSEStyled ref={ref} />,
    nw: <ResizebleHandleNWStyled ref={ref} />,
    ne: <ResizebleHandleNEStyled ref={ref} />,
  }[type]);

const LaneSpeedLimit = ({
  lane,
  onSpeedLimitChange,
}: {
  lane?: Lane;
  onSpeedLimitChange: (lane_id: string, lane_speed_limit: number) => void;
}) => {
  return lane ? (
    <Form.Group as={Row}>
      <Form.Label column sm="4">
        <b>Speed Limit:</b>
      </Form.Label>
      <Col sm="8">
        <Form.Control
          type="text"
          placeholder="Speed Limit"
          defaultValue={lane.speed_limit}
          onBlur={(e) =>
            onSpeedLimitChange(lane.lane_id, Number(e.target.value))
          }
        />
      </Col>
    </Form.Group>
  ) : null;
};

const Feature = (props: FeatureDialogProps & { index: number }) => {
  const { index, testFeatures, deleteFeatureProperty } = props;
  const feature = testFeatures.features?.[index] as GeoJSON.Feature<
    GeoJSON.LineString | GeoJSON.Point | GeoJSON.Polygon
  >;
  const radius = feature?.properties?.radius;
  const coordinates = feature?.geometry?.coordinates;
  const num_points =
    feature?.geometry?.type === "LineString"
      ? coordinates?.length
      : (coordinates?.[0] as GeoJSON.Position)?.length;

  return (
    <>
      <FeatureItem
        {...props}
        deleteFeatureProperty={deleteFeatureProperty}
        key={`feature-item-${index}`}
        index={index}
        featureType={feature?.geometry?.type}
        isModal
      />
      <br />
      <Accordion key={`feature-${index}`}>
        <Accordion.Item eventKey={index.toString()}>
          <Accordion.Header>
            <b>Info Feature No {feature.properties?.feature_id}</b>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p>
                <b>Coordinates:</b> ({num_points} points)
                <br />
                {JSON.stringify(coordinates)}
              </p>
              {radius && (
                <p>
                  <b>Radius (position, radius):</b>{" "}
                  {JSON.stringify([...radius])}
                </p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
    </>
  );
};

export default function FeatureDialog(props: FeatureDialogProps) {
  const {
    lane,
    selectedFeatureIndexes,
    showDialog,
    updateDialog,
    onSpeedLimitChange,
    deleteFeatureProperty,
  } = props;

  const [currentPosition, setCurrentPosition] = useState<Position>({
    xRate: 0,
    yRate: 0,
  });
  const [currentSize, setCurrentSize] = useState<Size>(
    DEFAULT_FEATURE_DIALOG_SIZE
  );

  if (!showDialog) return <></>;

  let title = "Feature Info";

  if (lane) {
    title = `Lane Segment No ${lane.lane_id}`;
  } else if (selectedFeatureIndexes.length === 0) {
    title = "No Feature(s) selected";
  }

  const closeAction = () => updateDialog(false);

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    setCurrentPosition({ xRate: data.lastX, yRate: data.lastY });
  };

  return (
    <Draggable
      position={{
        x: currentPosition.xRate,
        y: currentPosition.yRate,
      }}
      onDrag={onDrag}
      cancel="svg"
    >
      <ModalStyled className="feature-dialog-modal">
        <ResizableBox
          width={currentSize.width}
          height={currentSize.height}
          resizeHandles={["sw", "se", "nw", "ne"]}
          handle={(
            resizeHandleType: string,
            ref: React.RefObject<SVGSVGElement>
          ) => {
            return getResizeHandler(resizeHandleType, ref);
          }}
          onResize={(e: any, { size }: any) => setCurrentSize(size)}
          minConstraints={MINIMUM_FEATURE_DIALOG_SIZE}
        >
          <ResizebleWrapperContentStyled>
            <Modal.Header closeButton closeVariant="white" onHide={closeAction}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Form>
                  <LaneSpeedLimit
                    lane={lane}
                    onSpeedLimitChange={onSpeedLimitChange}
                  />
                  {selectedFeatureIndexes.map((index) => (
                    <Feature
                      {...props}
                      deleteFeatureProperty={deleteFeatureProperty}
                      key={`feature-${index}`}
                      index={index}
                    />
                  ))}
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeAction}>
                Close
              </Button>
            </Modal.Footer>
          </ResizebleWrapperContentStyled>
        </ResizableBox>
      </ModalStyled>
    </Draggable>
  );
}
