import React from "react";
import { createBrowserRouter } from "react-router-dom";

import LoginPage from "../pages/Login";
import { AuthorizedApp } from "../pages/AuthorizedApp";

import { AuthorizedPages } from "./AuthorizedPages";
import App from "../App";

const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    children: [
      {
        path: "",
        element: <AuthorizedApp />,
        children: AuthorizedPages,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
]);

export default router;
