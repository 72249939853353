import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import {
  DropdownContainerStyled,
  FormGroupInputContainerStyled,
  FormGroupUniqContainerStyled,
} from "../../../pages/Login/styles";

export default function NewProjectModal({
  dropdownItems,
  handleClose,
  show,
  createProject,
}) {
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [cloudNameValue, setCloudNameValue] = useState("");
  const [cloudDescriptionValue, setCloudDescriptionValue] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [currentDropdown, setCurrentDropdown] = useState({});
  const [fileValue, setFileValue] = useState(null);
  const dispatch = useDispatch();

  const activeCreateBtn =
    nameValue &&
    descriptionValue &&
    ((fileValue && cloudNameValue && cloudDescriptionValue) ||
      currentDropdown._id);

  const createAction = async () => {
    if (activeCreateBtn) {
      dispatch(
        createProject({
          name: nameValue,
          description: descriptionValue,
          latitude: Number(lat),
          longitude: Number(long),
          pointCloudId: currentDropdown._id,
          cloudName: cloudNameValue,
          cloudDescription: cloudDescriptionValue,
          file: fileValue,
        })
      );
      setNameValue("");
      setDescriptionValue("");
      setCloudNameValue("");
      setCloudDescriptionValue("");
      setLat("");
      setLong("");
      setFileValue(null);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create new project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroupInputContainerStyled controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupInputContainerStyled controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <Form>
          <FormGroupInputContainerStyled controlId="password">
            <Form.Label>Lat</Form.Label>
            <Form.Control
              type="text"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
          </FormGroupInputContainerStyled>
          <FormGroupInputContainerStyled controlId="password">
            <Form.Label>Long</Form.Label>
            <Form.Control
              type="text"
              value={long}
              onChange={(e) => setLong(e.target.value)}
            />
          </FormGroupInputContainerStyled>
        </Form>
        <FormGroupUniqContainerStyled controlId="formFile">
          <Form.Label>Point cloud</Form.Label>
          {dropdownItems && (
            <DropdownContainerStyled>
              <Dropdown.Toggle id="dropdown-basic">
                {currentDropdown.name || "Choose existing"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {dropdownItems.map(({ name, _id }) => {
                  if (currentDropdown._id === _id) return <div key={_id} />;
                  return (
                    <Dropdown.Item
                      key={_id}
                      onClick={() => setCurrentDropdown({ name, _id })}
                    >
                      {name}
                    </Dropdown.Item>
                  );
                })}
                {currentDropdown._id && (
                  <Dropdown.Item onClick={() => setCurrentDropdown({})}>
                    Upload new
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </DropdownContainerStyled>
          )}
          {!currentDropdown._id && (
            <>
              <FormGroupInputContainerStyled controlId="name">
                <Form.Label>Point Cloud Name</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={cloudNameValue}
                  onChange={(e) => setCloudNameValue(e.target.value)}
                />
              </FormGroupInputContainerStyled>
              <FormGroupInputContainerStyled controlId="description">
                <Form.Label>Point Cloud Description</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  value={cloudDescriptionValue}
                  onChange={(e) => setCloudDescriptionValue(e.target.value)}
                />
              </FormGroupInputContainerStyled>
              <Form.Control
                type="file"
                onChange={(e) => setFileValue(e.target.files[0])}
              />
            </>
          )}
        </FormGroupUniqContainerStyled>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={createAction}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
