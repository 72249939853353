import * as React from "react";
import update from "immutability-helper";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import { Intersection, StopSign } from "../../models/map-interface.d";
import { ItemWithContainerValues } from "../common/ItemWithContainerValues";
import { getMultipleIdsFromCommaSeparateString } from "../../utils/toolbox";

import { DropdownButtonStyled } from "./styles";

//TODO need to edit upper order types

type PolygonFeatureProps = {
  mapStructs: any;
  testFeatures: any;
  availableIds: any;
  updateMapStructs: (structs: any) => void;
  updateFeatures: (temp_features: any) => void;
  feature_info: any;
  feature_index: number;
  feature_info_index: number;
  makeLaneIdAvailable: (id: string, elementType: string) => void;
  makeLaneIdAvailableForAllElements: (laneId: string) => void;
  addNewLaneToMapStructs: (laneId: string) => void;
  makeLaneIdUnavailable: (laneId: string, elementType: string) => void;
};

export class PolygonFeature extends React.Component<PolygonFeatureProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentLane: null,
    };
  }

  _addNewStopSignToMapStructs(stop_sign_id: string) {
    const temp_stop_signs = this.props.mapStructs.stop_signs;
    const new_stop_sign: StopSign = {
      stop_sign_id: stop_sign_id,
      associated_lane_id: "",
      associated_control_line_id: "",
    };
    temp_stop_signs.set(stop_sign_id, new_stop_sign);
    this.props.updateMapStructs({ temp_stop_signs });
  }

  _addNewPolygonMapStructElement(e: any, feature_info: any) {
    if (e === MapConst.STOP_SIGN_STRING_NAME) {
      this._addNewStopSignToMapStructs(
        feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]
      );
    } else if (e === MapConst.INTERSECTION_STRING_NAME) {
      this._addNewIntersectionToMapStructs(
        feature_info[MapConst.INTERSECTION_ID_STRING_NAME]
      );
    }
  }

  _addNewIntersectionToMapStructs(intersection_id: string) {
    const temp_intersections = this.props.mapStructs.intersections;
    const new_intersection: Intersection = {
      intersection_id: intersection_id,
      associated_lane_ids: [],
    };
    temp_intersections.set(intersection_id, new_intersection);
    this.props.updateMapStructs({ temp_intersections });
  }

  getAvailableControlLines(curr_value: string) {
    const { mapStructs, feature_info } = this.props;
    const { control_lines, stop_signs } = mapStructs;
    const { lane_association } = feature_info;
    const filteredControlLines = new Map();

    if (lane_association === "NULL") return [];

    control_lines.forEach((controlLine: any, controlLineId: string) => {
      if (controlLine.associated_lane_id === lane_association) {
        filteredControlLines.set(controlLineId, controlLine);
      }
    });
    const available_control_line_ids_set = new Set(filteredControlLines.keys());

    stop_signs.forEach((stop_sign: any) => {
      const { associated_control_line_id } = stop_sign;
      available_control_line_ids_set.delete(associated_control_line_id);
    });
    const available_control_line_ids = Array.from(
      available_control_line_ids_set
    );
    if (curr_value) {
      available_control_line_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_control_line_ids;
  }

  getAvailablePolygonTypes(feature_index: number, curr_value: string) {
    const available_polygon_types_set = new Set([
      MapConst.STOP_SIGN_STRING_NAME,
      MapConst.INTERSECTION_STRING_NAME,
    ]);
    available_polygon_types_set.delete(curr_value);
    // handle in-feature conflict
    const temp_feature_info_list =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list;
    if (!temp_feature_info_list) {
      return Array.from(available_polygon_types_set);
    }
    for (let i = 0; i < temp_feature_info_list.length; i++) {
      const curr_feature_info = temp_feature_info_list[i];
      const curr_polygon_type =
        curr_feature_info[MapConst.POLYGON_TYPE_STRING_NAME];
      if (curr_polygon_type === MapConst.STOP_SIGN_STRING_NAME) {
        available_polygon_types_set.delete(MapConst.INTERSECTION_STRING_NAME);
        available_polygon_types_set.delete(MapConst.STOP_SIGN_STRING_NAME);
      } else if (curr_polygon_type === MapConst.INTERSECTION_STRING_NAME) {
        available_polygon_types_set.delete(MapConst.STOP_SIGN_STRING_NAME);
        available_polygon_types_set.delete(MapConst.INTERSECTION_STRING_NAME);
      }
    }
    const available_polygon_types = Array.from(available_polygon_types_set);
    if (curr_value !== MapConst.NULL_STRING_NAME) {
      available_polygon_types.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_polygon_types;
  }

  getAvailableLaneIdsForStopSign(
    feature_index: number,
    feature_info_index: number
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    const available_lane_ids_set =
      this.props.availableIds.lane_ids_for_stop_sign;
    const available_lane_ids = Array.from(available_lane_ids_set);

    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids;
  }

  getAvailableLaneIdsForIntersection(
    feature_index: number,
    feature_info_index: number
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    const available_lane_ids_set =
      this.props.availableIds.lane_ids_for_intersection;
    const available_lane_ids = Array.from(available_lane_ids_set);

    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids;
  }

  getAvailableLaneIdsForPolygon(
    feature_index: number,
    feature_info_index: number
  ) {
    const { testFeatures } = this.props;
    const available_lane_ids: never[] = [];
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.NULL_STRING_NAME
    ) {
      return available_lane_ids;
    }
    const polygon_type = temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME];
    if (polygon_type === MapConst.STOP_SIGN_STRING_NAME) {
      return this.getAvailableLaneIdsForStopSign(
        feature_index,
        feature_info_index
      );
    } else {
      return this.getAvailableLaneIdsForIntersection(
        feature_index,
        feature_info_index
      );
    }
  }

  _updateLaneAssocOfStopSign(
    affected_stop_sign_id: string,
    new_lane_association: string
  ) {
    if (affected_stop_sign_id === MapConst.NULL_STRING_NAME) {
      return;
    }
    const temp_stop_signs = this.props.mapStructs.stop_signs;

    new_lane_association = new_lane_association ? "" : new_lane_association;
    const affected_stop_sign = temp_stop_signs.get(affected_stop_sign_id);

    affected_stop_sign["associated_lane_id"] = new_lane_association;

    temp_stop_signs.set(affected_stop_sign_id, affected_stop_sign);
    this.props.updateMapStructs({ temp_stop_signs });
  }

  _updateLaneAssocOfIntersection(
    affected_intersection_id: string,
    new_lane_association: string
  ) {
    if (!affected_intersection_id) {
      return;
    }
    const temp_intersections = this.props.mapStructs.intersections;

    const affected_intersection = temp_intersections.get(
      affected_intersection_id
    );
    if (new_lane_association) {
      // new lane association is NULL
      affected_intersection["associated_lane_ids"] = [];
    } else {
      affected_intersection["associated_lane_ids"].push(new_lane_association);
    }

    temp_intersections.set(affected_intersection_id, affected_intersection);
    this.props.updateMapStructs({ temp_intersections });
  }

  _updateControlLineAssocOfStopSign(
    affected_stop_sign_id: string,
    new_control_line_association: string
  ) {
    if (affected_stop_sign_id === MapConst.NULL_STRING_NAME) {
      return;
    }
    const temp_stop_signs = this.props.mapStructs.stop_signs;

    new_control_line_association = new_control_line_association
      ? ""
      : new_control_line_association;
    const affected_stop_sign = temp_stop_signs.get(affected_stop_sign_id);
    affected_stop_sign["associated_control_line_id"] =
      new_control_line_association;

    temp_stop_signs.set(affected_stop_sign_id, affected_stop_sign);
    this.props.updateMapStructs({ temp_stop_signs });
  }

  _clearStaleStopSignMapStructElement(feature_info: any) {
    // remove stop sign struct
    const temp_stop_signs = this.props.mapStructs.stop_signs;
    temp_stop_signs.delete(feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]);
    // update control line association for this stop sign to sentinel
    const temp_control_lines = this.props.mapStructs.control_lines;
    const affected_control_line_id =
      feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME];
    if (
      !affected_control_line_id ||
      !temp_control_lines.has(affected_control_line_id)
    ) {
      console.log(
        "undefined / missing control line id for map struct's stop sign " +
          feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]
      );
    } else {
      temp_control_lines[affected_control_line_id]["associated_stop_sign_id"] =
        "";
    }
    this.props.updateMapStructs({ temp_control_lines, temp_stop_signs });
  }

  _clearStaleIntersectionMapStructElement(feature_info: any) {
    // remove intersection struct
    const temp_intersections = this.props.mapStructs.intersections;
    temp_intersections.delete(
      feature_info[MapConst.INTERSECTION_ID_STRING_NAME]
    );
    this.props.updateMapStructs({ temp_intersections });
  }

  _clearStalePolygonMapStructElement(feature_info: { [x: string]: string }) {
    if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.NULL_STRING_NAME
    ) {
      return;
    } else if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      this._clearStaleStopSignMapStructElement(feature_info);
    } else if (
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.INTERSECTION_STRING_NAME
    ) {
      this._clearStaleIntersectionMapStructElement(feature_info);
    }
  }

  handleChangeInPolygonLaneAssociation(
    feature_index: any,
    feature_info_index: any,
    e: any
  ) {
    const {
      testFeatures,
      makeLaneIdAvailable,
      updateFeatures,
      makeLaneIdAvailableForAllElements,
      addNewLaneToMapStructs,
      makeLaneIdUnavailable,
    } = this.props;

    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] === e) {
      return;
    }

    // make available previous lane association
    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      makeLaneIdAvailable(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
        MapConst.STOP_SIGN_STRING_NAME
      );
    } else if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.INTERSECTION_STRING_NAME &&
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
        MapConst.NULL_STRING_NAME &&
      e === MapConst.NULL_STRING_NAME
    ) {
      const all_affected_lane_ids = getMultipleIdsFromCommaSeparateString(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]
      );
      for (let i = 0; i < all_affected_lane_ids.length; i++) {
        makeLaneIdAvailable(
          all_affected_lane_ids[i],
          MapConst.INTERSECTION_STRING_NAME
        );
      }
    }

    let lane_id;
    if (e === MapConst.NEW_STRING_NAME) {
      const new_lane_id = uuidv4();
      lane_id = new_lane_id;
      makeLaneIdAvailableForAllElements(new_lane_id);
      addNewLaneToMapStructs(new_lane_id);
    } else {
      lane_id = e;
    }

    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = lane_id;
    } else if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.INTERSECTION_STRING_NAME
    ) {
      if (
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] ===
          MapConst.NULL_STRING_NAME ||
        e === MapConst.NULL_STRING_NAME
      ) {
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = lane_id;
      } else {
        const pre_existing_ids =
          temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME];
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
          pre_existing_ids + "," + lane_id;
      }
    }

    // make unavailable new lane association
    makeLaneIdUnavailable(
      lane_id,
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME]
    );

    // update corresponding map struct of new lane id
    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      this._updateLaneAssocOfStopSign(
        temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME],
        lane_id
      );
    } else if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.INTERSECTION_STRING_NAME
    ) {
      this._updateLaneAssocOfIntersection(
        temp_feature_info[MapConst.INTERSECTION_ID_STRING_NAME],
        lane_id
      );
    }

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  }

  handleChangeInControlLineAssociationValue(
    feature_index: string | number,
    feature_info_index: string | number,
    e: any
  ) {
    const { testFeatures, updateFeatures } = this.props;
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (
      temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] === e
    ) {
      return;
    }

    temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] = e;

    // update control line association for this stop sign in mapStruct
    this._updateControlLineAssocOfStopSign(
      temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME],
      e === MapConst.NEW_STRING_NAME ? uuidv4() : e
    );

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  }

  handleChangeInPolygonTypeValue(
    feature_index: string | number,
    feature_info_index: string | number,
    e: string
  ) {
    const { testFeatures, makeLaneIdAvailable, updateFeatures } = this.props;
    const temp_feature_info =
      testFeatures.features[feature_index].properties.feature_info_list[
        feature_info_index
      ];
    if (temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] === e) {
      return;
    }

    // remove struct related to previous type of feature info
    this._clearStalePolygonMapStructElement(temp_feature_info);

    // make available previous lane association
    if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_STRING_NAME
    ) {
      makeLaneIdAvailable(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
        MapConst.STOP_SIGN_STRING_NAME
      );
    } else if (
      temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
      MapConst.INTERSECTION_STRING_NAME
    ) {
      const all_affected_lane_ids = getMultipleIdsFromCommaSeparateString(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]
      );
      for (let i = 0; i < all_affected_lane_ids.length; i++) {
        makeLaneIdAvailable(
          all_affected_lane_ids[i],
          MapConst.INTERSECTION_STRING_NAME
        );
      }
    }

    temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] = e;
    temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.STOP_SIGN_ID_STRING_NAME] =
      e === MapConst.STOP_SIGN_STRING_NAME
        ? uuidv4()
        : MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.INTERSECTION_ID_STRING_NAME] =
      e === MapConst.INTERSECTION_STRING_NAME
        ? uuidv4()
        : MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;

    // add new element to map struct
    this._addNewPolygonMapStructElement(e, temp_feature_info);

    const result_state = update(testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    updateFeatures(result_state);
  }

  _renderPolygonTypeDropDown(
    available_polygon_types: string[],
    feature_info: {
      [x: string]:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    },
    feature_index: string | number,
    feature_info_index: string | number
  ) {
    return (
      <Row
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.POLYGON_TYPE_STRING_NAME
        }
      >
        <Col>
          <b>POLYGON TYPE</b>
        </Col>
        <Col>
          <DropdownButtonStyled
            title={feature_info[MapConst.POLYGON_TYPE_STRING_NAME] || ""}
            id="dropdown-menu-align-right"
            size="sm"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSelect={this.handleChangeInPolygonTypeValue.bind(
              this,
              feature_index,
              feature_info_index
            )}
          >
            <ItemWithContainerValues values={available_polygon_types} />
          </DropdownButtonStyled>
        </Col>
      </Row>
    );
  }

  render() {
    const { feature_info, feature_index, feature_info_index } = this.props;
    const available_control_line_ids = this.getAvailableControlLines(
      feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME]
    );
    const available_polygon_types = this.getAvailablePolygonTypes(
      feature_index,
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME]
    );
    const available_lane_ids = this.getAvailableLaneIdsForPolygon(
      feature_index,
      feature_info_index
    );

    return [
      this._renderPolygonTypeDropDown(
        available_polygon_types,
        feature_info,
        feature_index,
        feature_info_index
      ),
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_STRING_NAME &&
        MapConst.STOP_SIGN_ID_STRING_NAME in feature_info && (
          <Row
            key={
              feature_index +
              "_" +
              feature_info_index +
              "_" +
              MapConst.STOP_SIGN_ID_STRING_NAME
            }
          >
            <Col>
              <b>STOP SIGN ID</b>
            </Col>
            <Col>
              <b>{feature_info[MapConst.STOP_SIGN_ID_STRING_NAME]}</b>
            </Col>
          </Row>
        ),
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.INTERSECTION_STRING_NAME &&
        MapConst.INTERSECTION_ID_STRING_NAME in feature_info && (
          <Row
            key={
              feature_index +
              "_" +
              feature_info_index +
              "_" +
              MapConst.INTERSECTION_ID_STRING_NAME
            }
          >
            <Col>
              <b>INTERSECTION ID</b>
            </Col>
            <Col>
              <b>{feature_info[MapConst.INTERSECTION_ID_STRING_NAME]}</b>
            </Col>
          </Row>
        ),
      <Row
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.LANE_ASSOCIATION_STRING_NAME
        }
      >
        <Col xs={6}>
          <b>LANE ASSOC</b>
        </Col>
        <Col xs={6}>
          <DropdownButtonStyled
            title={feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] || ""}
            id="dropdown-menu-align-right"
            size="sm"
            onSelect={this.handleChangeInPolygonLaneAssociation.bind(
              this,
              feature_index,
              feature_info_index
            )}
          >
            <ItemWithContainerValues values={available_lane_ids} />
          </DropdownButtonStyled>
        </Col>
      </Row>,
      feature_info[MapConst.POLYGON_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_STRING_NAME &&
        MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME in feature_info && (
          <Row
            key={
              feature_index +
              "_" +
              feature_info_index +
              "_" +
              MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME
            }
          >
            <Col>
              <b>CONTROL LINE ASSOC</b>
            </Col>
            <Col>
              <DropdownButtonStyled
                title={
                  feature_info[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] ||
                  ""
                }
                id="dropdown-menu-align-right"
                size="sm"
                onSelect={this.handleChangeInControlLineAssociationValue.bind(
                  this,
                  feature_index,
                  feature_info_index
                )}
              >
                <ItemWithContainerValues values={available_control_line_ids} />
              </DropdownButtonStyled>
            </Col>
          </Row>
        ),
    ];
  }
}
