import { DrawLineStringMode } from "@nebula.gl/edit-modes";
import bearing from "@turf/bearing";
import destination from "@turf/destination";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import { throttle } from "lodash";
import { getPickedEditHandle } from ".";

type DraggingHandler = (event: any, props: any) => void;

export class DrawLinestringByDraggingMode extends DrawLineStringMode {
  handleDraggingThrottled: DraggingHandler | null | undefined = null;
  _refinedSequence: any = [];

  getRefinedSequence(): any {
    return this._refinedSequence;
  }

  addRefinedSequence({ mapCoords }: any): void {
    // refine where possible
    if (this._refinedSequence.length > 0) {
      const prev_coords =
        this._refinedSequence[this._refinedSequence.length - 1];
      const mid_coords = this.intermediatePoints(prev_coords, mapCoords);
      this._refinedSequence.push(...mid_coords);
    }
    this._refinedSequence.push(mapCoords);
  }

  resetRefinedSequence(): void {
    this._refinedSequence = [];
  }

  intermediatePoints(position1: any, position2: any): any {
    const delta = 0.0005; // km
    const lower_bound = 0.0002; // km
    const points: any = [];
    const point1 = point(position1);
    const point2 = point(position2);
    const dist = distance(point1, point2, { units: "kilometers" });
    const heading = bearing(point1, point2);
    const elevation = (position1[2] + position2[2]) / 2;
    let dist_so_far = 0.0;
    while (dist_so_far + delta < dist) {
      dist_so_far += delta;
      const temp_point = destination(point1, dist_so_far, heading, {
        units: "kilometers",
      });
      const hold = temp_point.geometry.coordinates;
      points.push([hold[0], hold[1], elevation]);
    }
    if (points.length > 0) {
      const last_point = point(points[points.length - 1]);
      const last_dist = distance(last_point, point2, { units: "kilometers" });
      if (last_dist < lower_bound) {
        points.pop();
      }
    }

    return points;
  }

  handleClick() {
    // No-op
  }

  handleStartDragging(event: any, props: any) {
    event.cancelPan();
    if (props.modeConfig && props.modeConfig.throttleMs) {
      this.handleDraggingThrottled = throttle(
        this.handleDraggingAux,
        props.modeConfig.throttleMs
      );
    } else {
      this.handleDraggingThrottled = this.handleDraggingAux;
    }
  }

  handleStopDragging(event: any, props: any) {
    this.addClickSequence(event);
    this.addRefinedSequence(event);
    const clickSequence = this.getClickSequence();
    const refinedSequence = this.getRefinedSequence();

    if (
      this.handleDraggingThrottled &&
      (this.handleDraggingThrottled as any).cancel
    ) {
      (this.handleDraggingThrottled as any).cancel();
    }

    if (clickSequence.length > 1) {
      // Complete the linestring.
      const lineStringToAdd: any = {
        type: "LineString",
        coordinates: [...refinedSequence],
      };

      this.resetClickSequence();
      this.resetRefinedSequence();

      const editAction = this.getAddFeatureAction(lineStringToAdd, props.data);
      if (editAction) {
        props.onEdit(editAction);
      }
    }
  }

  handleDraggingAux(event: any) {
    const { picks } = event;
    const clickedEditHandle = getPickedEditHandle(picks);

    if (!clickedEditHandle) {
      // Don't add another point right next to an existing one.
      this.addClickSequence(event);
      this.addRefinedSequence(event);
    }
  }

  handleDragging(event: any, props: any) {
    if (this.handleDraggingThrottled) {
      this.handleDraggingThrottled(event, props);
    }
  }
}
