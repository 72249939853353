import { ModalBackgroundStyled, ModalStyled } from "../styles";
import {
  ButtonsContainerStyled,
  CancelButtonStyled,
  ConfirmActionModalStyled,
  SubmitButtonStyled,
  TextStyled,
} from "./styles";
import React, { FC, memo } from "react";

type ConfirmActionModalCommonProps = {
  modalMessage: string;
  handleClose: () => void;
  handleConfirm: () => void;
  cancelButtonText?: string;
};

export const ConfirmActionModalCommon: FC<ConfirmActionModalCommonProps> = memo(
  ({ modalMessage, handleClose, handleConfirm, cancelButtonText = "No" }) => {
    return (
      <ModalStyled>
        <ModalBackgroundStyled onClick={handleClose} />
        <ConfirmActionModalStyled>
          <TextStyled>{modalMessage}</TextStyled>
          <ButtonsContainerStyled>
            <CancelButtonStyled onClick={handleClose}>
              {cancelButtonText}
            </CancelButtonStyled>
            <SubmitButtonStyled onClick={handleConfirm}>Ok</SubmitButtonStyled>
          </ButtonsContainerStyled>
        </ConfirmActionModalStyled>
      </ModalStyled>
    );
  }
);
