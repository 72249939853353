import {
  EMPTY_MAP_STRUCTS,
  EMPTY_AVAILABLE_IDS,
} from "../../constants/map-constants";
import { MapStructs, AvailableIds } from "../../models/map-interface";

const numberFieldIntoString = (currentMap: any, field: string) => {
  Array.from(currentMap.values()).forEach((mapItem: any) => {
    const currentValue = currentMap.get(mapItem[field]);
    currentMap.delete(currentValue[field]);
    currentValue[field] = String(currentValue[field]);
    currentMap.set(String(currentValue[field]), currentValue);
  });
  return currentMap;
};

export const getNewMapStructs = (data: MapStructs): MapStructs => {
  const mapStructs = { ...EMPTY_MAP_STRUCTS };
  const { control_lines, intersections, lanes, stop_signs } = data;
  if (control_lines && control_lines.size !== 0) {
    mapStructs.control_lines = numberFieldIntoString(
      control_lines,
      "control_line_id"
    );
  }
  if (intersections && intersections.size !== 0) {
    mapStructs.intersections = numberFieldIntoString(
      intersections,
      "intersection_id"
    );
  }
  if (lanes && lanes.size !== 0) {
    mapStructs.lanes = numberFieldIntoString(lanes, "lane_id");
  }
  if (stop_signs && stop_signs.size !== 0) {
    mapStructs.stop_signs = numberFieldIntoString(stop_signs, "stop_sign_id");
  }

  return mapStructs;
};
export const getNewAvailableIds = (data: AvailableIds): AvailableIds => {
  const availableIds = { ...EMPTY_AVAILABLE_IDS };
  Object.keys(availableIds).forEach((availableIdsKey) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (availableIds[availableIdsKey]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      availableIds[availableIdsKey] = new Set(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Array.from(data[availableIdsKey]).map((item: number | string) =>
          String(item)
        )
      );
    }
  });

  return availableIds;
};

export const getMergedMapStructs = (
  mapStructs: MapStructs,
  newMapStructs: MapStructs
): MapStructs => {
  return {
    control_lines: new Map([
      ...Array.from(mapStructs.control_lines),
      ...Array.from(newMapStructs.control_lines),
    ]),
    intersections: new Map([
      ...Array.from(mapStructs.intersections),
      ...Array.from(newMapStructs.intersections),
    ]),
    lanes: new Map([
      ...Array.from(mapStructs.lanes),
      ...Array.from(newMapStructs.lanes),
    ]),
    stop_signs: new Map([
      ...Array.from(mapStructs.stop_signs),
      ...Array.from(newMapStructs.stop_signs),
    ]),
  };
};

export const getMergedAvailableIds = (
  availableIds: AvailableIds,
  newAvailableIds: AvailableIds
): AvailableIds => {
  return {
    lane_ids_for_intersection: new Set([
      ...Array.from(availableIds.lane_ids_for_intersection),
      ...Array.from(newAvailableIds.lane_ids_for_intersection),
    ]),
    lane_ids_for_left_boundary_line: new Set([
      ...Array.from(availableIds.lane_ids_for_left_boundary_line),
      ...Array.from(newAvailableIds.lane_ids_for_left_boundary_line),
    ]),
    lane_ids_for_right_boundary_line: new Set([
      ...Array.from(availableIds.lane_ids_for_right_boundary_line),
      ...Array.from(newAvailableIds.lane_ids_for_right_boundary_line),
    ]),
    lane_ids_for_start_line: new Set([
      ...Array.from(availableIds.lane_ids_for_start_line),
      ...Array.from(newAvailableIds.lane_ids_for_start_line),
    ]),
    lane_ids_for_stop_sign: new Set([
      ...Array.from(availableIds.lane_ids_for_stop_sign),
      ...Array.from(newAvailableIds.lane_ids_for_stop_sign),
    ]),
    lane_ids_for_stop_sign_control_line: new Set([
      ...Array.from(availableIds.lane_ids_for_stop_sign_control_line),
      ...Array.from(newAvailableIds.lane_ids_for_stop_sign_control_line),
    ]),
    lane_ids_for_termination_line: new Set([
      ...Array.from(availableIds.lane_ids_for_termination_line),
      ...Array.from(newAvailableIds.lane_ids_for_termination_line),
    ]),
  };
};
