import * as React from "react";
import update from "immutability-helper";
import { Col, DropdownButton, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import { ControlLine } from "../../models/map-interface.d";
import { ItemWithContainerValues } from "../common/ItemWithContainerValues";

import { DropdownButtonStyled, UniqColStyled } from "./styles";

//TODO need to edit upper order types

type LineStringFeatureProps = {
  mapStructs: any;
  testFeatures: any;
  availableIds: any;
  updateMapStructs: (structs: any) => void;
  updateFeatures: (temp_features: any) => void;
  feature_info: any;
  feature_index: number;
  feature_info_index: number;
  makeLaneIdAvailable: (id: string, elementType: string) => void;
  makeLaneIdAvailableForAllElements: (laneId: string) => void;
  addNewLaneToMapStructs: (laneId: string) => void;
  makeLaneIdUnavailable: (laneId: string, elementType: string) => void;
};

export class LineStringFeature extends React.Component<LineStringFeatureProps> {
  getAvailableStopSigns(curr_value: string) {
    const { mapStructs, feature_info } = this.props;
    const { control_lines, stop_signs } = mapStructs;
    const { lane_association } = feature_info;

    if (lane_association === "NULL") return [];

    const filteredStopSigns = new Map();
    stop_signs.forEach((controlLine: any, controlLineId: string) => {
      if (controlLine.associated_lane_id === lane_association) {
        filteredStopSigns.set(controlLineId, controlLine);
      }
    });

    const available_stop_sign_ids_set = new Set(filteredStopSigns.keys());

    for (const [, control_line] of control_lines) {
      const temp_stop_sign_id = control_line.associated_stop_sign_id;
      available_stop_sign_ids_set.delete(temp_stop_sign_id);
    }
    const available_stop_sign_ids = Array.from(available_stop_sign_ids_set);
    if (curr_value !== MapConst.NULL_STRING_NAME) {
      available_stop_sign_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_stop_sign_ids;
  }

  getAvailableLineTypes(feature_index: number, curr_value: string) {
    const { testFeatures } = this.props;
    const available_line_types_set = new Set([
      MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME,
      MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME,
      MapConst.LANE_START_LINE_STRING_NAME,
      MapConst.LANE_TERMINATION_LINE_STRING_NAME,
      MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME,
    ]);
    available_line_types_set.delete(curr_value);
    // handle in-feature conflict
    const temp_feature_info_list =
      testFeatures.features[feature_index].properties?.feature_info_list;
    if (!temp_feature_info_list) {
      return Array.from(available_line_types_set);
    }
    for (let i = 0; i < temp_feature_info_list.length; i++) {
      const curr_feature_info = temp_feature_info_list[i];
      const curr_line_type = curr_feature_info[MapConst.LINE_TYPE_STRING_NAME];
      if (
        curr_line_type === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
        curr_line_type === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
      ) {
        available_line_types_set.delete(MapConst.LANE_START_LINE_STRING_NAME);
        available_line_types_set.delete(
          MapConst.LANE_TERMINATION_LINE_STRING_NAME
        );
        available_line_types_set.delete(
          MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
        );
      } else if (
        curr_line_type === MapConst.LANE_START_LINE_STRING_NAME ||
        curr_line_type === MapConst.LANE_TERMINATION_LINE_STRING_NAME ||
        curr_line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
      ) {
        if (curr_line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
          available_line_types_set.delete(curr_line_type);
        }
        available_line_types_set.delete(
          MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME
        );
        available_line_types_set.delete(
          MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
        );
      }
    }
    const available_line_types = Array.from(available_line_types_set);
    if (curr_value !== MapConst.NULL_STRING_NAME) {
      available_line_types.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_line_types;
  }

  getAvailableLaneIdsForStopSignControlLine(
    feature_index: number,
    feature_info_index: number
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        ?.feature_info_list[feature_info_index];
    const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];
    let available_lane_ids_set;

    if (line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
      available_lane_ids_set =
        this.props.availableIds.lane_ids_for_stop_sign_control_line;
    }

    const available_lane_ids = Array.from(available_lane_ids_set);

    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);

    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids;
  }

  getLanesOccupiedByBoundaryLineType(
    feature_index: number,
    boundary_line_type: string
  ) {
    const occupied_lane_ids = new Set();
    const temp_feature_info_list =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list;
    for (let i = 0; i < temp_feature_info_list.length; i++) {
      const curr_feature_info = temp_feature_info_list[i];
      const curr_boundary_line_type =
        curr_feature_info[MapConst.LINE_TYPE_STRING_NAME];
      if (
        curr_boundary_line_type === boundary_line_type &&
        curr_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
          MapConst.NULL_STRING_NAME
      ) {
        occupied_lane_ids.add(
          parseInt(curr_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME], 10)
        );
      }
    }
    return occupied_lane_ids;
  }

  getAvailableLaneIdsForLaneBoundaryLine(
    feature_index: number,
    feature_info_index: number
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];
    let available_lane_ids_set;
    let restricted_lane_ids_set: Set<unknown>;

    if (line_type === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
      available_lane_ids_set =
        this.props.availableIds.lane_ids_for_left_boundary_line;
      restricted_lane_ids_set = this.getLanesOccupiedByBoundaryLineType(
        feature_index,
        MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME
      );
    } else if (line_type === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME) {
      available_lane_ids_set =
        this.props.availableIds.lane_ids_for_right_boundary_line;
      restricted_lane_ids_set = this.getLanesOccupiedByBoundaryLineType(
        feature_index,
        MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME
      );
    } else if (line_type === MapConst.LANE_START_LINE_STRING_NAME) {
      available_lane_ids_set = this.props.availableIds.lane_ids_for_start_line;
      restricted_lane_ids_set = this.getLanesOccupiedByBoundaryLineType(
        feature_index,
        MapConst.LANE_TERMINATION_LINE_STRING_NAME
      );
    } else if (line_type === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
      available_lane_ids_set =
        this.props.availableIds.lane_ids_for_termination_line;
      restricted_lane_ids_set = this.getLanesOccupiedByBoundaryLineType(
        feature_index,
        MapConst.LANE_START_LINE_STRING_NAME
      );
    }
    // DO NOT modify available_lane_ids_set directly
    const available_lane_ids: Array<any> = [];

    available_lane_ids_set.forEach((id: string) => {
      if (!restricted_lane_ids_set.has(id)) {
        available_lane_ids.push(id);
      }
    });

    available_lane_ids.unshift(MapConst.NEW_STRING_NAME);
    if (
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] !==
      MapConst.NULL_STRING_NAME
    ) {
      available_lane_ids.unshift(MapConst.NULL_STRING_NAME);
    }
    return available_lane_ids;
  }

  getAvailableLaneIdsForLineString(
    feature_index: number,
    feature_info_index: number
  ) {
    const available_lane_ids: never[] = [];
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    const line_type = temp_feature_info[MapConst.LINE_TYPE_STRING_NAME];

    if (line_type === MapConst.NULL_STRING_NAME) {
      return available_lane_ids;
    }
    if (line_type === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
      return this.getAvailableLaneIdsForStopSignControlLine(
        feature_index,
        feature_info_index
      );
    } else {
      return this.getAvailableLaneIdsForLaneBoundaryLine(
        feature_index,
        feature_info_index
      );
    }
  }

  _updateStopSignAssocOfControlLine(
    affected_control_line_id: string,
    new_stop_sign_association: string
  ) {
    if (affected_control_line_id === MapConst.NULL_STRING_NAME) {
      return;
    }
    const temp_control_lines = this.props.mapStructs.control_lines;
    new_stop_sign_association = new_stop_sign_association
      ? ""
      : new_stop_sign_association;
    const affected_control_line = temp_control_lines.get(
      affected_control_line_id
    );

    affected_control_line["associated_stop_sign_id"] =
      new_stop_sign_association;

    temp_control_lines.set(affected_control_line_id, affected_control_line);
    this.props.updateMapStructs({ temp_control_lines });
  }

  _clearStaleControlLineMapStructElement(feature_info: {
    [x: string]: string;
  }) {
    // remove control line struct
    const temp_control_lines = this.props.mapStructs.control_lines;
    temp_control_lines.delete(
      feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]
    );
    // update stop sign association for this control line to sentinel
    const temp_stop_signs = this.props.mapStructs.stop_signs;
    const affected_stop_sign_id =
      feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME];

    if (!affected_stop_sign_id || !temp_stop_signs.has(affected_stop_sign_id)) {
      console.log(
        "undefined / missing stop sign id for map struct's control line " +
          feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]
      );
    } else {
      temp_stop_signs[affected_stop_sign_id]["associated_control_line_id"] = -1;
    }
    this.props.updateMapStructs({ temp_control_lines, temp_stop_signs });
  }

  _clearStaleLineStringMapStructElement(feature_info: any) {
    if (
      feature_info[MapConst.LINE_TYPE_STRING_NAME] === MapConst.NULL_STRING_NAME
    ) {
      return;
    } else if (
      feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
    ) {
      this._clearStaleControlLineMapStructElement(feature_info);
    }
  }

  _addNewControlLineToMapStructs(control_line_id: string) {
    const temp_control_lines = this.props.mapStructs.control_lines;
    const new_control_line: ControlLine = {
      control_line_id: control_line_id,
      associated_lane_id: "",
      associated_stop_sign_id: "",
    };
    temp_control_lines.set(control_line_id, new_control_line);
    this.props.updateMapStructs({ temp_control_lines });
  }

  _addNewLineStringMapStructElement(e: string, feature_info: any) {
    if (e === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME) {
      this._addNewControlLineToMapStructs(
        feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]
      );
    }
  }

  _updateBoundaryLineOfLane(
    affected_lane_id: any,
    line_type: any,
    line_string_feature_id: any
  ) {
    // handle NULL and NEW line ids
    if (!affected_lane_id || affected_lane_id === "NULL") {
      return;
    }
    const temp_lanes = this.props.mapStructs.lanes;
    const affected_lane = temp_lanes.get(affected_lane_id);
    if (line_type === MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME) {
      affected_lane["left_boundary_line_id"] = line_string_feature_id;
    } else if (line_type === MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME) {
      affected_lane["right_boundary_line_id"] = line_string_feature_id;
    } else if (line_type === MapConst.LANE_START_LINE_STRING_NAME) {
      affected_lane["start_line_id"] = line_string_feature_id;
    } else if (line_type === MapConst.LANE_TERMINATION_LINE_STRING_NAME) {
      affected_lane["termination_line_id"] = line_string_feature_id;
    }

    temp_lanes.set(affected_lane_id, affected_lane);
    this.props.updateMapStructs({ temp_lanes });
  }

  _updateLaneAssocOfControlLine(
    affected_control_line_id: string,
    new_lane_association: string
  ) {
    if (!affected_control_line_id) {
      return;
    }
    const temp_control_lines = this.props.mapStructs.control_lines;
    new_lane_association = !new_lane_association ? "" : new_lane_association;

    const affected_control_line = temp_control_lines.get(
      affected_control_line_id
    );

    affected_control_line["associated_lane_id"] = new_lane_association;
    temp_control_lines.set(affected_control_line_id, affected_control_line);

    this.props.updateMapStructs({ temp_control_lines });
  }

  handleChangeInStopSignAssociationValue(
    feature_index: string | number,
    feature_info_index: string | number,
    e: any
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    if (temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] === e) {
      return;
    }

    temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] = e;

    // update stop sign association for this control line in mapStruct
    this._updateStopSignAssocOfControlLine(
      temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME],
      e
    );

    const result_state = update(this.props.testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    this.props.updateFeatures(result_state);
  }

  handleChangeInLineTypeValue(
    feature_index: any,
    feature_info_index: any,
    e: any
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    if (temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] === e) {
      return;
    }

    // remove struct related to previous type of feature info
    this._clearStaleLineStringMapStructElement(temp_feature_info);

    // make available affected lane ids
    this.props.makeLaneIdAvailable(
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME]
    );

    // update corresponding map struct of old lane id
    if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_START_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_TERMINATION_LINE_STRING_NAME
    ) {
      this._updateBoundaryLineOfLane(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
        ""
      );
    } else if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
    ) {
      // nothing to update - control line mapStruct is just completely removed
    }

    temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] = e;
    temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME] =
      e === MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
        ? uuidv4()
        : MapConst.NULL_STRING_NAME;
    temp_feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] =
      MapConst.NULL_STRING_NAME;

    // add new element to map struct
    this._addNewLineStringMapStructElement(e, temp_feature_info);

    const result_state = update(this.props.testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    this.props.updateFeatures(result_state);
  }

  handleChangeInLineStringLaneAssociation(
    feature_index: number,
    feature_info_index: number,
    e: any
  ) {
    const temp_feature_info =
      this.props.testFeatures.features[feature_index].properties
        .feature_info_list[feature_info_index];
    if (temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] === e) {
      return;
    }
    const line_string_feature_id =
      this.props.testFeatures.features[feature_index].properties.feature_id;

    // make available previous lane association
    this.props.makeLaneIdAvailable(
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME]
    );

    // update corresponding map struct of old lane id
    if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_START_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_TERMINATION_LINE_STRING_NAME
    ) {
      this._updateBoundaryLineOfLane(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
        ""
      );
    } else if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
    ) {
      // update it directly with new lane id in latter condition
    }

    if (e === MapConst.NEW_STRING_NAME) {
      const new_lane_id = uuidv4();
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = new_lane_id;
      this.props.makeLaneIdAvailableForAllElements(new_lane_id);
      // create new lane mapStruct
      this.props.addNewLaneToMapStructs(new_lane_id);
    } else {
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME] = e;
    }

    // make unavailable new lane association
    this.props.makeLaneIdUnavailable(
      temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME]
    );

    // update corresponding map struct of new lane id
    if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_LEFT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_RIGHT_BOUNDARY_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_START_LINE_STRING_NAME ||
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.LANE_TERMINATION_LINE_STRING_NAME
    ) {
      this._updateBoundaryLineOfLane(
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME],
        temp_feature_info[MapConst.LINE_TYPE_STRING_NAME],
        line_string_feature_id
      );
    } else if (
      temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
      MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME
    ) {
      this._updateLaneAssocOfControlLine(
        temp_feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME],
        temp_feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]
      );
    }

    const result_state = update(this.props.testFeatures, {
      features: {
        [feature_index]: {
          properties: {
            feature_info_list: {
              [feature_info_index]: { $set: temp_feature_info },
            },
          },
        },
      },
    });
    this.props.updateFeatures(result_state);
  }

  _renderLineStringLineTypeDropDown(
    available_line_types: any,
    feature_info: any,
    feature_index: number,
    feature_info_index: number
  ) {
    return (
      <Row key={feature_index + "_" + feature_info_index + "_line_type"}>
        <Col xs={6}>
          <b>LINE TYPE</b>
        </Col>
        <UniqColStyled xs={6}>
          <DropdownButton
            title={feature_info[MapConst.LINE_TYPE_STRING_NAME]}
            id="dropdown-menu-align-right"
            size="sm"
            onSelect={this.handleChangeInLineTypeValue.bind(
              this,
              feature_index,
              feature_info_index
            )}
          >
            <ItemWithContainerValues values={available_line_types} />
          </DropdownButton>
        </UniqColStyled>
      </Row>
    );
  }

  render() {
    const { feature_info, feature_index, feature_info_index } = this.props;
    const available_stop_sign_ids = this.getAvailableStopSigns(
      feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME]
    );
    const available_line_types = this.getAvailableLineTypes(
      feature_index,
      feature_info[MapConst.LINE_TYPE_STRING_NAME]
    );
    const available_lane_ids = this.getAvailableLaneIdsForLineString(
      feature_index,
      feature_info_index
    );
    return [
      this._renderLineStringLineTypeDropDown(
        available_line_types,
        feature_info,
        feature_index,
        feature_info_index
      ),
      <Row
        key={
          feature_index +
          "_" +
          feature_info_index +
          "_" +
          MapConst.LANE_ASSOCIATION_STRING_NAME
        }
      >
        <Col xs={6}>
          <b>LANE ASSOC</b>
        </Col>
        <UniqColStyled xs={6}>
          <DropdownButton
            title={feature_info[MapConst.LANE_ASSOCIATION_STRING_NAME]}
            id="dropdown-menu-align-right"
            size="sm"
            onSelect={this.handleChangeInLineStringLaneAssociation.bind(
              this,
              feature_index,
              feature_info_index
            )}
          >
            <ItemWithContainerValues values={available_lane_ids} />
          </DropdownButton>
        </UniqColStyled>
      </Row>,
      feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME && (
        <Row
          key={
            feature_index +
            "_" +
            feature_info_index +
            "_" +
            MapConst.CONTROL_LINE_ID_STRING_NAME
          }
        >
          <Col>
            <b>CONTROL LINE ID</b>
          </Col>
          <Col>
            <b>{feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME]}</b>
          </Col>
        </Row>
      ),
      feature_info[MapConst.LINE_TYPE_STRING_NAME] ===
        MapConst.STOP_SIGN_CONTROL_LINE_STRING_NAME && (
        <Row
          key={
            feature_index +
            "_" +
            feature_info_index +
            "_" +
            MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME
          }
        >
          <Col>
            <b>STOP SIGN ASSOC</b>
          </Col>
          <Col>
            <DropdownButtonStyled
              title={
                feature_info[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] || ""
              }
              id="dropdown-menu-align-right"
              size="sm"
              onSelect={this.handleChangeInStopSignAssociationValue.bind(
                this,
                feature_index,
                feature_info_index
              )}
            >
              <ItemWithContainerValues values={available_stop_sign_ids} />
            </DropdownButtonStyled>
          </Col>
        </Row>
      ),
    ];
  }
}
