import { GeoJsonEditMode } from "@nebula.gl/edit-modes";
import {
  AvailableIds,
  FeaturesInterface,
  LatLngPoint,
  MapStructs,
} from "../../models/map-interface";

export enum GeoJsonType {
  Semantic = "semantic",
  Centerline = "centerline",
}

export enum GeoJsonUploadType {
  MergeWithFeatures = "mergeWithFeatures",
  MergeWithNoFeatures = "MergeWithNoFeatures",
}

export interface MapEditorState {
  viewport: Record<string, any>;
  testFeatures: FeaturesInterface;
  measureFeatures: FeaturesInterface;
  undoFeatures: FeaturesInterface[];
  mapTooltips: Array<any>;
  mode: any;
  modeView: typeof GeoJsonEditMode;
  modeConfig: any;
  pointsRemovable: boolean;
  selectedFeatureIndexes: number[];
  selectedLaneId: string;
  editContext?: any;
  editHandleType: string;
  selectionTool?: string;
  showGeoJson: boolean;
  showDialog: boolean;
  featureMenu?: {
    index: number;
    x: number;
    y: number;
  };
  pointcloudOrigin: LatLngPoint;
  renderMapboxLayer: boolean;
  useLocalCoord: boolean;
  currentMapProjectId: string;
  editPlane: {
    renderEditPlane: boolean;
    elevation: number;
    radius: number;
    color: number[];
  };
  currentCategory: string;
  mapStructs: MapStructs;
  availableIds: AvailableIds;
  radiusDialog: boolean;
  radius?: Map<number, number>;
  pointCloudJson: any;
  layerVisibility: any;
  layers: any;
  editableGeoJsonLayer: any;
  editableGeoJsonLayerVisible: boolean;
  editPlaneLayerVisible: boolean;
  pointCloudData: any;
  isLoading: boolean;
  error: any;
  showError: boolean;
  geojsonType: GeoJsonType | null;
  showGeoJsonTypeModal: boolean;
  geoJsonDataIsLoaded: boolean;
}
