import * as Sentry from "@sentry/browser";

export const sendSentryEvent = (error, errorType, userText = "") => {
  Sentry.withScope((scope) => {
    scope.setExtra("date", new Date());
    scope.setExtra("userText", userText);
    scope.setExtra("user", {
      jwt: localStorage.getItem("JWT"),
    });
    // scope.setExtra("store", JSON.stringify(store));
    Sentry.captureException(error || new Error(errorType));
  });
};
