import styled from "styled-components";

import { ReactComponent as ArrowResize } from "./assets/arrowResize.svg";

export const ModalStyled = styled.div`
  top: 72px;
  left: calc(50vw - 220px);
  z-index: 1999;
  background-color: rgb(23, 28, 41);
  position: absolute;
  color: #ffffff;
`;

export const ResizebleWrapperContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

export const ResizebleHandleStyled = styled(ArrowResize)`
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  padding: 0 3px 3px 0;
  z-index: 2000;
`;

export const ResizebleHandleSWStyled = styled(ResizebleHandleStyled)`
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
`;

export const ResizebleHandleSEStyled = styled(ResizebleHandleStyled)`
  bottom: 0;
  right: 0;
  cursor: se-resize;
`;

export const ResizebleHandleNWStyled = styled(ResizebleHandleStyled)`
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
`;

export const ResizebleHandleNEStyled = styled(ResizebleHandleStyled)`
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
`;
