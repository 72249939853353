import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAnyModal, getModalData } from "../../../redux/slices/modals";

import { ConfirmActionModalCommon } from "./ConfirmActionModalCommon";

interface ConfirmActionData {
  text: string;
  confirmAction: () => void;
  cancelAction?: () => void;
}

export const ConfirmActionModal = memo(() => {
  const dispatch = useDispatch();
  const data = useSelector(getModalData);
  const { text, confirmAction, cancelAction } = data as ConfirmActionData;

  const handleCloseModal = useCallback(() => {
    if (cancelAction) {
      cancelAction();
    }
    dispatch(closeAnyModal());
  }, []);

  const currentConfirmAction = () => {
    confirmAction();
    dispatch(closeAnyModal());
  };

  return (
    <ConfirmActionModalCommon
      handleClose={handleCloseModal}
      handleConfirm={currentConfirmAction}
      modalMessage={text}
    />
  );
});
