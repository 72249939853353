import React, { useEffect, useState } from "react";
import { Form, Modal, Button, InputGroup } from "react-bootstrap";

export default function RadiusDialog({
  isError,
  radius = "",
  radiusDialog,
  updateRadius,
}: {
  isError: boolean;
  radius: string;
  radiusDialog: boolean;
  updateRadius: (v: boolean, r?: number) => void;
}) {
  const [inputRadius, setInputRadius] = useState("");

  useEffect(() => {
    setInputRadius(radius);
  }, [radius]);

  const handleAppy = () => {
    const parsedRadius = inputRadius ? parseFloat(inputRadius) : 0;

    updateRadius(false, parsedRadius);
  };

  return (
    <Modal show={radiusDialog} onHide={() => updateRadius(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Input Radius</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isError ? (
          <Form>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="0.0"
                value={inputRadius}
                onChange={(e) => setInputRadius(e.target.value)}
              />
              <InputGroup.Text>Meters</InputGroup.Text>
            </InputGroup>
          </Form>
        ) : (
          <p>Pick a valid edge</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleAppy}>
          {!isError ? "Apply" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
