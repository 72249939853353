import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import { CustomToastProps } from "./index.d";
import { ExclamationTriangle } from "react-bootstrap-icons";
import { ToastStyled } from "./styles";

function CustomToast({
  title,
  delay = 3000,
  variant = "danger",
  show = false,
  setShow,
}: CustomToastProps) {
  return (
    <Row>
      <Col xs={6}>
        <ToastStyled
          onClose={() => setShow(false)}
          show={show}
          bg={variant}
          delay={delay}
          autohide
        >
          <Toast.Header>
            <ExclamationTriangle className="me-2" /> {title}
          </Toast.Header>
        </ToastStyled>
      </Col>
    </Row>
  );
}

export default CustomToast;
