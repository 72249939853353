import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  FormGroupInputContainerStyled,
  FormGroupUniqContainerStyled,
} from "../../../pages/Login/styles";

export default function NewPointCloudModal({
  show,
  handleClose,
  createPointCloud,
}) {
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const dispatch = useDispatch();
  const activeCreateBtn = nameValue && descriptionValue && fileValue;

  const createAction = async () => {
    if (activeCreateBtn) {
      dispatch(
        createPointCloud({
          name: nameValue,
          description: descriptionValue,
          file: fileValue,
        })
      );
      setNameValue("");
      setDescriptionValue("");
      setFileValue(null);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New cloud</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroupInputContainerStyled controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupInputContainerStyled controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupUniqContainerStyled controlId="formFile">
          <Form.Label>File to be uploaded</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setFileValue(e.target.files[0])}
          />
        </FormGroupUniqContainerStyled>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={createAction}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
