import { GeoJsonEditMode } from "@nebula.gl/edit-modes";
import bezierSpline from "@turf/bezier-spline";
import { Feature, LineString, Position } from "@turf/helpers";
import { getPickedEditHandle } from ".";

export class DrawCurvedLineStringMode extends GeoJsonEditMode {
  _curvedSequence: Feature | null = null;

  getCurvedSequence(): Feature | null {
    return this._curvedSequence;
  }

  addCurvedSequence(points: Position[]): void {
    if (points.length > 0) {
      const mid_coords = this.intermediatePoints(points);
      this._curvedSequence = mid_coords;
    }
  }

  resetCurvedSequence(): void {
    this._curvedSequence = null;
  }

  intermediatePoints(points: Position[]): Feature {
    const lineStringToTransform: LineString = {
      type: "LineString",
      coordinates: points,
    };

    return bezierSpline(lineStringToTransform);
  }

  handleClick(event: any, props: any) {
    const { picks } = event;
    const clickedEditHandle = getPickedEditHandle(picks);

    let positionAdded = false;

    if (!clickedEditHandle) {
      // Don't add another point right next to an existing one
      this.addClickSequence(event);
      positionAdded = true;
    }
    const clickSequence = this.getClickSequence();

    // They clicked the last point (or double-clicked), so add the LineString
    if (
      clickSequence.length > 1 &&
      clickedEditHandle &&
      clickedEditHandle?.properties?.positionIndexes?.[0] ===
        clickSequence.length - 1
    ) {
      this.addCurvedSequence(clickSequence);
      const lineStringToAdd = this.getCurvedSequence();

      this.resetClickSequence();
      this.resetCurvedSequence();

      const editAction = this.getAddFeatureAction(
        lineStringToAdd as any,
        props.data
      );
      if (editAction) {
        props.onEdit(editAction);
      }
    } else if (positionAdded) {
      // new tentative point
      props.onEdit({
        // data is the same
        updatedData: props.data,
        editType: "addTentativePosition",
        editContext: {
          position: event.mapCoords,
        },
      });
    }
  }

  getGuides(props: any): any {
    const { lastPointerMoveEvent } = props;
    const clickSequence = this.getClickSequence();
    // This remove the tentative guideline for 3 point (the curve definition)
    const lastCoords = lastPointerMoveEvent?.mapCoords
      ? [lastPointerMoveEvent?.mapCoords]
      : [];

    const guides: any = {
      type: "FeatureCollection",
      features: [],
    };

    let tentativeFeature;
    if (clickSequence.length > 0) {
      tentativeFeature = {
        type: "Feature",
        properties: {
          guideType: "tentative",
        },
        geometry: {
          type: "LineString",
          coordinates: [...clickSequence, ...lastCoords],
        },
      };
    }

    if (tentativeFeature) {
      guides.features.push(tentativeFeature);
    }

    const editHandles = clickSequence.map(
      (clickedCoord: Position, index: number): Feature => ({
        type: "Feature",
        properties: {
          guideType: "editHandle",
          editHandleType: "existing",
          featureIndex: -1,
          positionIndexes: [index],
        },
        geometry: {
          type: "Point",
          coordinates: clickedCoord,
        },
      })
    );

    guides.features.push(...editHandles);

    return guides;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-empty-pattern
  handlePointerMove({}: any, { onUpdateCursor }: any) {
    onUpdateCursor(null);
  }
}
