import { Button } from "react-bootstrap";
import styled from "styled-components";
import { getHtmlColorForFeature } from "../../utils/toolbox";
import { ToolboxCheckbox } from "../common/toolbox";

interface ToolboxCheckboxProps {
  isHide: boolean;
}
export const ToolboxCheckboxStyled = styled(
  ToolboxCheckbox
)<ToolboxCheckboxProps>`
  margin: 10px;
  ${(props) =>
    props.isHide &&
    `
  display: none;
`}
`;

export const ModalStyled = styled.div`
  position: absolute;
  right: 32px;
`;

interface ToolboxCheckboxSpanProps {
  index: number;
  isSelected: boolean;
}

export const ToolboxCheckboxSpanStyled = styled.span<ToolboxCheckboxSpanProps>`
  color: ${(props) => getHtmlColorForFeature(props.index, props.isSelected)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
`;

export const ModalButtonStyled = styled(Button)`
  margin-right: 16px;
`;

interface ToolboxCheckboxButtonProps {
  index: number;
  isSelected: boolean;
}

export const ToolboxCheckboxButtonStyled = styled(
  Button
)<ToolboxCheckboxButtonProps>`
  position: absolute;
  right: 12px;
  text-decoration: none;
  color: ${(props) => getHtmlColorForFeature(props.index, props.isSelected)};
`;
