import React, { Component } from "react";
import { connect } from "react-redux";
import * as Utils from "../../utils";
import { PointCloudLayer } from "@deck.gl/layers";
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { MapController, MapView } from "@deck.gl/core";
import DeckGL from "@deck.gl/react/typed";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { Feature, GeoJsonProperties, Geometry, LineString } from "geojson";
import { cloneDeep } from "lodash";
import window from "window-or-global";
import { StaticMap } from "react-map-gl";
import { WebMercatorViewport } from "@deck.gl/core";
import { registerLoaders } from "@loaders.gl/core";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import { rainbow } from "../../constants/nebula";
import * as MapConst from "../../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import {
  DuplicateMode,
  ElevationMode,
  ModifyMode,
  Position,
  SnappableMode,
} from "@nebula.gl/edit-modes";
import {
  initialViewport,
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
} from "../../constants/nebula";
import {
  FeaturesInterface,
  GeoJSONInterface,
  Lane,
} from "../../models/map-interface.d";
import { edit_plane_layer } from "../../utils/layers";
import FeatureDialog from "../../components/FeatureDialog";
import FeatureMenu from "../../components/FeatureMenu";
import LoaderSpinner from "../../components/common/loaderSpinner";
import { MapToolbox } from "../../components/MapToolbox";
import { PLYWorkerLoader } from "@loaders.gl/ply";
import { RGBAColor } from "../../types";
import RadiusDialog from "../../components/RadiusDialog";
import { SideMenu } from "../../components/sideMenu/side-menu";

import {
  getCurrentMapData,
  setGeojsonType,
  getCurrentMap,
} from "../../redux/slices/currentMap";
import {
  getIsLoading,
  getSaveSemanticMapFlag,
} from "../../redux/slices/appState";
import { saveMapGeoJson } from "../../redux/slices/projectsList";
import { calculateCurve } from "../../utils/alter-curved-edge-mode";
import { CustomViewMode } from "../../utils/custom-view-mode";
import CustomToast from "../../components/common/CustomToast";
import { TangentLineConstraintMode } from "../../utils/tangent-line-constraint-mode";
import {
  getFilteredAvalibleIds,
  getFilteredFeatures,
  getLaneFeatures,
  onLaneSegment,
  getDeckColorForFeature,
  snapFeature,
} from "../../utils";
import { CONFIRM_ACTION_MODAL, toggleModal } from "../../redux/slices/modals";
import GeoJsonTypeModal from "../../components/GeoJsonTypeModal";

import {
  getNewMapStructs,
  getNewAvailableIds,
  getMergedMapStructs,
  getMergedAvailableIds,
} from "./tempDataUtils";
import { MapContainerStyled } from "./styles";
import { MapEditorState, GeoJsonUploadType } from "./mapEditor";

registerLoaders(PLYWorkerLoader);

const SPEED_LIMIT_DEFAULT_VALUE = 1.2;

class MapEditorComponent extends Component<any, MapEditorState> {
  currentPointCloudLayers: any;
  initializeLayerVisibility(pointCloudData: any) {
    if (!pointCloudData) {
      return {}; // Return an empty object if pointCloudData is undefined
    }

    const pointCloudLayers = this.createPointCloudLayers(pointCloudData);
    const initialLayerVisibility = pointCloudLayers?.reduce(
      (visibilityMap, layer) => {
        (visibilityMap as any)[layer?.id] = true; // Set the initial visibility for each layer to true
        return visibilityMap;
      },
      {}
    );

    return initialLayerVisibility;
  }

  constructor(props: any) {
    super(props);

    const { currentMapData } = props;

    this.state = {
      currentMapProjectId: window.location.pathname.split("/")[2] || "",
      viewport: {
        ...initialViewport(),
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
      testFeatures: MapConst.getEmptyFeatureCollection(
        currentMapData.latitude,
        currentMapData.longitude
      ),
      measureFeatures: MapConst.getEmptyFeatureCollection(
        currentMapData.latitude,
        currentMapData.longitude
      ),
      mapTooltips: [],
      undoFeatures: [],
      mode: CustomViewMode,
      modeView: CustomViewMode,
      modeConfig: null,
      currentCategory: "View",
      pointsRemovable: true,
      selectedFeatureIndexes: [],
      selectedLaneId: "",
      editContext: undefined,
      editHandleType: "point",
      selectionTool: undefined,
      showGeoJson: false,
      showDialog: false,
      featureMenu: undefined,
      pointcloudOrigin: {
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
      renderMapboxLayer: true,
      useLocalCoord: false,
      editPlane: {
        renderEditPlane: false,
        elevation: 0,
        radius: 0.0005,
        color: [99, 203, 224, 150],
      },
      mapStructs: MapConst.EMPTY_MAP_STRUCTS,
      availableIds: MapConst.EMPTY_AVAILABLE_IDS,
      radiusDialog: false,
      pointCloudJson: currentMapData.pointCloudJson,
      layerVisibility: {},
      layers: [],
      editableGeoJsonLayer: null,
      editableGeoJsonLayerVisible: true,
      editPlaneLayerVisible: false,
      pointCloudData: [],
      // TODO: add Floor Plan state and initialize
      isLoading: true,
      error: null,
      showError: false,
      geojsonType: null,
      showGeoJsonTypeModal: false,
      geoJsonDataIsLoaded: false,
    };

    this.clearSelectedFeatures = this.clearSelectedFeatures.bind(this);
    this.featureMenuClick = this.featureMenuClick.bind(this);
    this.undoFeature = this.undoFeature.bind(this);
    this.updateMapStructs = this.updateMapStructs.bind(this);
    this.updateAvailablesIds = this.updateAvailablesIds.bind(this);
    this.updateFeatures = this.updateFeatures.bind(this);
    this.updateModeConfig = this.updateModeConfig.bind(this);
    this.updatePointsRemovable = this.updatePointsRemovable.bind(this);
    this.updateSelectedFeatureIndexes =
      this.updateSelectedFeatureIndexes.bind(this);
    this.updateRenderMapboxLayer = this.updateRenderMapboxLayer.bind(this);
    this.updateShowGeoJson = this.updateShowGeoJson.bind(this);
    this.updateMode = this.updateMode.bind(this);
    this.updateFeatureMenu = this.updateFeatureMenu.bind(this);
    this.updateDialog = this.updateDialog.bind(this);
    this.updateRadius = this.updateRadius.bind(this);
    this.updatePointCloudJson = this.updatePointCloudJson.bind(this);
    this.toggleLayerVisibility = this.toggleLayerVisibility.bind(this);
    this.setShowError = this.setShowError.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleEditableGeoJsonLayerVisibility =
      this.toggleEditableGeoJsonLayerVisibility.bind(this);
    this.currentPointCloudLayers = [];
  }

  async loadData() {
    const { currentMapData, getCurrentMapDataAction } = this.props;
    const { currentMapProjectId } = this.state;

    if (!currentMapData._id) {
      await getCurrentMapDataAction(currentMapProjectId);
      await this.getGeoJsonData();
    } else {
      await this.getGeoJsonData();
    }
    await this.fetchPointCloudData();
  }

  async componentDidMount() {
    await this.loadData();
    this.updateLayers();

    const { geojsonType } = this.props.currentMapData;
    if (!geojsonType) {
      this.setState({ showGeoJsonTypeModal: true });
    }

    window.addEventListener("resize", this.resize);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.currentMapData.pointCloudJson !==
      prevProps.currentMapData.pointCloudJson
    ) {
      await this.loadData();
    }

    if (this.state.layerVisibility !== prevState.layerVisibility) {
      // Update the layers only when layerVisibility state changes
      this.updateLayers();
    }

    if (prevProps.currentMapData._id !== this.props.currentMapData._id) {
      const { geojsonType } = this.props.currentMapData;
      if (!geojsonType) {
        this.setState({ showGeoJsonTypeModal: true });
      }
    }
  }

  handleKeyDown = (event: any) => {
    if (
      event.keyCode === MapConst.ESCAPE_KEY &&
      this.state.mode === TangentLineConstraintMode
    ) {
      this.setState({ selectedFeatureIndexes: [] }, () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
      );
    }
  };

  async fetchPointCloudData() {
    this.setState({ isLoading: true });

    try {
      const response = await fetch(this.props.currentMapData.pointCloudJson);
      const pointCloudData = await response.json();
      this.setState(
        {
          pointCloudData,
          isLoading: false,
          layerVisibility: this.initializeLayerVisibility(pointCloudData),
        },
        this.updateLayers
      );
    } catch (e: any) {
      this.setState({
        error: `fetchPointCloudData: ${e}`,
        isLoading: false,
        showError: true,
      });
    }
  }

  // TODO: add Floor Plan data fetch

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  closeGeoJsonTypeModal = () => {
    this.setState({ showGeoJsonTypeModal: false });
  };

  getGeoJsonData = async () => {
    const { currentMapData } = this.props;
    let needParseFile = true;

    if (!currentMapData.semanticMapGeoJson) return;

    const geoJsonData = await fetch(currentMapData.semanticMapGeoJson, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch(() => {
        needParseFile = false;
        return MapConst.fetchMapOrigin();
      });

    if (needParseFile) {
      this.parseStringJson(JSON.stringify(geoJsonData));
    } else {
      this.setState({});
    }

    this.setState({
      viewport: {
        ...initialViewport(),
        latitude: currentMapData.latitude,
        longitude: currentMapData.longitude,
      },
    });
  };

  debugLaneSegmentArea = (
    lanes: Map<string, Lane>,
    features: GeoJSON.Feature<LineString>[],
    id?: string
  ) => {
    const f = { ...this.state.testFeatures };
    f.features = [];

    if (id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const lane = Utils.makePolygon(lanes.get(id)!, features);
      f.features.push(lane.polygon);
      // f.features.push(lane.line);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const l of lanes.values()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const lane = Utils.makePolygon(l, features);
        f.features.push(lane.polygon);
      }
    }

    this.setState({ testFeatures: f }, () =>
      console.log("state", this.state.testFeatures)
    );
  };

  onLayerClick = (info: any) => {
    const lanes = this.state.mapStructs.lanes;
    const features = this.state.testFeatures
      .features as GeoJSON.Feature<LineString>[];
    let selectedLaneId = "";
    let selectedFeatureIndexes: number[] = [];

    if (this.state.mode !== CustomViewMode || this.state.selectionTool) {
      // don't change selection while editing
      return;
    }

    if (info) {
      // a feature was clicked
      if (info.index !== -1) {
        selectedFeatureIndexes = [info.index];
      } else if (!MapConst.DEBUG_LANE_SEGMENT) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedLaneId = onLaneSegment(info, lanes, features);
      }

      if (selectedLaneId) {
        const laneInfo = lanes.get(selectedLaneId);

        selectedFeatureIndexes = getLaneFeatures(
          this.state.testFeatures.features,
          laneInfo
        );
      }
    } else {
      // open space was clicked, so stop editing
      console.log("deselect editing feature");
    }

    if (MapConst.DEBUG_LANE_SEGMENT) {
      this.debugLaneSegmentArea(lanes, features);
    } else {
      this.setState(
        {
          selectedFeatureIndexes,
          selectedLaneId,
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
    }
  };

  resize = () => {
    this.forceUpdate();
  };

  updateViewPortWithLatLong(
    lat: number,
    long: number,
    bounding_box: [any, any] | undefined
  ) {
    typeof bounding_box !== "undefined"
      ? bounding_box
      : [
          [-10, -10],
          [10, 10],
        ];
    let xyz_delta = [0, 0];
    if (typeof bounding_box !== "undefined") {
      const [mins, maxs] = bounding_box;
      xyz_delta = [(mins[0] + maxs[0]) / 2, (mins[1] + maxs[1]) / 2];
    }
    const [viewport_center_lon, viewport_center_lat] =
      new WebMercatorViewport().addMetersToLngLat([long, lat], xyz_delta);

    // File contains bounding box info
    const viewport = {
      ...this.state.viewport,
      longitude: viewport_center_lon,
      latitude: viewport_center_lat,
    };
    this.setState({ viewport });
  }

  parseStringJson = (json: string, geoJsonUploadType?: GeoJsonUploadType) => {
    const input: GeoJSONInterface = JSON.parse(json, Utils.reviver);
    const { testFeatures, availableIds, mapStructs, viewport } = this.state;
    const { features, properties, type } = testFeatures;

    if (geoJsonUploadType === GeoJsonUploadType.MergeWithNoFeatures) {
      const newFeatures = input.features.map(({ geometry, type }) => ({
        type,
        geometry,
        properties: {
          feature_id: uuidv4(),
          feature_info_list: [],
        },
      }));
      this.setState(
        {
          testFeatures: {
            features: [...features, ...newFeatures] || [],
            properties,
            type,
          },
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    if (geoJsonUploadType === GeoJsonUploadType.MergeWithFeatures) {
      const newFeatures = input.features.map(
        ({ geometry, type, properties }) => ({
          type,
          geometry,
          properties: {
            feature_id: uuidv4(),
            feature_info_list: properties?.feature_info_list || [],
          },
        })
      );
      const newMapStructs = getNewMapStructs(input.map_structs);
      const newAvailableIds = getNewAvailableIds(input.available_ids);

      this.setState(
        {
          testFeatures: {
            features: [...features, ...newFeatures] || [],
            properties,
            type,
          },
          mapStructs: getMergedMapStructs(mapStructs, newMapStructs),
          availableIds: getMergedAvailableIds(availableIds, newAvailableIds),
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    try {
      const newFeatures = input.features.map(
        ({ geometry, type, properties }) => ({
          type,
          geometry,
          properties: {
            feature_id: properties?.feature_id
              ? String(properties?.feature_id)
              : uuidv4(),
            feature_info_list: properties?.feature_info_list || [],
          },
        })
      );
      const newProperties = input.properties || {};
      const { latLngOrigin } = newProperties;

      this.setState(
        {
          testFeatures: {
            type: "FeatureCollection",
            features: newFeatures,
            properties: newProperties,
          },
          mapStructs: getNewMapStructs(input.map_structs),
          availableIds: getNewAvailableIds(input.available_ids),
          geoJsonDataIsLoaded: true,
          viewport: {
            ...viewport,
            latitude: latLngOrigin.latitude
              ? latLngOrigin.latitude
              : viewport.latitude,
            longitude: latLngOrigin.longitude
              ? latLngOrigin.longitude
              : viewport.longitude,
          },
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );

      // Alert if origin mismatch with pointcloud opened
      if (
        this.state.testFeatures.properties.latLngOrigin.latitude !==
          this.state.pointcloudOrigin.latitude ||
        this.state.testFeatures.properties.latLngOrigin.longitude !==
          this.state.pointcloudOrigin.longitude
      ) {
        const msg =
          // eslint-disable-next-line no-template-curly-in-string
          "WARNING: Point cloud and GeoJSON origin mismatch! (Make Point Cloud Map origin is taken from xxx/cyngn_map/${location_id}/metadata.yaml). GeoJSON origin will be used for now.";
        this.error(msg);
        const geojson_latlong_origin =
          this.state.testFeatures.properties.latLngOrigin;
        const temp_state = update(this.state, {
          pointcloudOrigin: {
            latitude: { $set: geojson_latlong_origin.latitude },
            longitude: { $set: geojson_latlong_origin.longitude },
          },
        });
        this.setState(temp_state);
        this.updateViewPortWithLatLong(
          geojson_latlong_origin.latitude,
          geojson_latlong_origin.longitude,
          undefined
        );
      }
    } catch (err) {
      this.error(err);
    }
  };

  error = (err: any) => {
    // eslint-disable-next-line
    alert(err);
  };

  handleMouseDown = (e: any) => {
    if (e.button === 2) {
      e.preventDefault();
    }
  };

  adjustEditPlaneElevation(diff: number) {
    const new_edit_plane = update(this.state.editPlane, {
      elevation: { $set: this.state.editPlane.elevation + diff },
    });
    this.setState(() => ({ editPlane: new_edit_plane }));
  }

  adjustEditPlaneRadius(diff: number) {
    const new_edit_plane = update(this.state.editPlane, {
      radius: { $set: this.state.editPlane.radius + diff },
    });
    this.setState(() => ({ editPlane: new_edit_plane }));
  }

  setEditPlaneOpen(open: boolean) {
    const new_edit_plane = update(this.state.editPlane, {
      renderEditPlane: { $set: open },
    });
    this.setState(() => ({ editPlane: new_edit_plane }));
  }

  onSpeedLimitChange(lane_id: string, lane_speed_limit: number) {
    this.setState((state) => {
      const new_lane = state.mapStructs.lanes.get(lane_id);

      if (new_lane) {
        new_lane.speed_limit = lane_speed_limit;
        state.mapStructs.lanes.set(lane_id, new_lane);
      }
      return state;
    });
  }

  featurePropIdtoIndex(
    geometry_type: string,
    feature_prop_name: string,
    feature_prop_id: string
  ) {
    for (let i = 0; i < this.state.testFeatures.features?.length; i++) {
      const feature = this.state.testFeatures.features[i];
      if (feature.geometry.type === geometry_type) {
        for (const index in feature.properties?.feature_info_list) {
          if (
            feature.properties?.feature_info_list[index][feature_prop_name] ===
            feature_prop_id
          ) {
            return i;
          }
        }
      }
    }
    return -1;
  }

  onLaneFocus(laneIds: Array<string>) {
    const lanesRelatedFeaturesIndexes: Array<number> = [];

    laneIds.forEach((laneId: string) => {
      const laneInfo = this.state.mapStructs.lanes.get(laneId);
      const { control_lines, stop_signs } = this.state.mapStructs;
      const controlLineItem = Array.from(control_lines.values()).find(
        (controlLine) => String(controlLine.associated_lane_id) === laneId
      );
      const stopSignItem = Array.from(stop_signs.values()).find(
        (stopSign) => String(stopSign.associated_lane_id) === laneId
      );
      const laneRelatedFeatures = getLaneFeatures(
        this.state.testFeatures.features,
        laneInfo,
        controlLineItem?.control_line_id || undefined,
        stopSignItem?.stop_sign_id || undefined
      );
      lanesRelatedFeaturesIndexes.push(...laneRelatedFeatures);
    });
    this.setState({ selectedFeatureIndexes: lanesRelatedFeaturesIndexes }, () =>
      this.setState({
        editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
      })
    );
  }

  onIntersectionFocus(intersection_id: string) {
    // highlight the intersection polygon
    this.setState({
      selectedFeatureIndexes: [
        this.featurePropIdtoIndex(
          "Polygon",
          MapConst.INTERSECTION_ID_STRING_NAME,
          intersection_id
        ),
      ],
    });
  }

  onStopSignFocus(stop_sign_id: string) {
    // highlight the stop sign polygon and associated control line
    let selectedStopSignsIndexes: number[] = [];
    selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
      this.featurePropIdtoIndex(
        "Polygon",
        MapConst.STOP_SIGN_ID_STRING_NAME,
        stop_sign_id
      )
    );

    const associated_control_line_id: string | undefined =
      this.state.mapStructs.stop_signs.get(
        stop_sign_id
      )?.associated_control_line_id;

    if (associated_control_line_id) {
      selectedStopSignsIndexes = selectedStopSignsIndexes.concat(
        this.featurePropIdtoIndex(
          "LineString",
          MapConst.CONTROL_LINE_ID_STRING_NAME,
          associated_control_line_id
        )
      );
    }

    this.setState({ selectedFeatureIndexes: selectedStopSignsIndexes });
  }

  renderStaticMap(viewport: Record<string, any>) {
    if (this.state.renderMapboxLayer)
      return (
        <StaticMap
          {...viewport}
          mapboxApiAccessToken="pk.eyJ1IjoieWlhbm5pLXZlcnZlcmlzIiwiYSI6ImNrcWF0azdnejBjdm4yd3M3ajBmb2hpeGkifQ.rl7QWaaMtqRYNJ-vMIMoOA"
          mapStyle={"mapbox://styles/mapbox/dark-v10"}
        />
      );
    else return <div></div>;
  }

  updateUndo(updatedData: FeaturesInterface) {
    const undo = [...this.state.undoFeatures];

    undo.push(updatedData);

    if (undo.length > MapConst.MAX_UNDO) {
      undo.shift();
    }
    return undo;
  }

  onEdit = ({
    updatedData,
    editType,
    editContext,
  }: {
    updatedData: FeaturesInterface;
    editType: string;
    editContext: any;
  }) => {
    let updatedSelectedFeatureIndexes = [...this.state.selectedFeatureIndexes];
    let { undoFeatures } = this.state;
    const {
      measureFeatures,
      editableGeoJsonLayer,
      mapTooltips,
      currentCategory,
    } = this.state;
    let newUpdatedData = cloneDeep(updatedData);
    const currentMeasureFeatures = { ...measureFeatures };
    let currentMapTooltips = [...mapTooltips];

    if (currentCategory === "View" && editType === "addTentativePosition") {
      const { mode } = editableGeoJsonLayer.state;
      if (mode && mode._clickSequence && mode._clickSequence.length > 1) {
        const { _clickSequence, _currentDistance, _currentTooltips } = mode;
        currentMeasureFeatures.features = [
          ...currentMeasureFeatures.features,
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: _clickSequence,
            },
            properties: {
              id: uuidv4(),
              type: "measure",
              mode,
              distance: _currentDistance,
              feature_id: 0,
              feature_info_list: [],
            },
          },
        ];
        currentMapTooltips = [...currentMapTooltips, ..._currentTooltips];
      }
      this.setState(
        {
          selectedFeatureIndexes: updatedSelectedFeatureIndexes,
          measureFeatures: currentMeasureFeatures,
          mapTooltips: currentMapTooltips,
        },
        () =>
          this.setState({
            editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
          })
      );
      return;
    }

    if (
      ![
        "movePosition",
        "extruding",
        "rotating",
        "translating",
        "scaling",
        "addTentativePosition",
      ].includes(editType)
    ) {
      // Don't log edits that happen as the pointer moves since they're really chatty
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const updatedDataInfo = Utils.featuresToInfoString(newUpdatedData);
      // eslint-disable-next-line
      console.log("onEdit", editType, editContext, updatedDataInfo);
    }

    if (editType === "removePosition" && !this.state.pointsRemovable) {
      // reject the edit
      return;
    }

    if (editType === "addFeature" && this.state.mode !== DuplicateMode) {
      const { featureIndexes } = editContext;

      // Add the new feature to the selection
      updatedSelectedFeatureIndexes = [
        ...this.state.selectedFeatureIndexes,
        ...featureIndexes,
      ];

      newUpdatedData = snapFeature(
        featureIndexes,
        newUpdatedData,
        currentMeasureFeatures.features.length + 1
      );
    }

    if (editType === "addFeature" && this.state.mode === DuplicateMode) {
      updatedSelectedFeatureIndexes.forEach((index) => {
        const currentItem = { ...newUpdatedData.features[index] };
        currentItem.properties = {
          feature_id: uuidv4(),
          feature_info_list: [],
        };
        newUpdatedData.features[index] = currentItem;
      });
    }

    if (editType === "tangentLine") {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updatedSelectedFeatureIndexes = Utils.tangentLineConstraint(
          this.state.testFeatures.features,
          this.state.selectedFeatureIndexes,
          editContext
        );
      } catch (e: any) {
        this.setState({
          selectedFeatureIndexes: [],
          showError: true,
          error: e.message,
        });
        return;
      }
    }

    if (["removePosition", "addFeature", "addPosition"].includes(editType)) {
      undoFeatures = this.updateUndo(this.state.testFeatures);
    }

    this.setState(
      {
        testFeatures: newUpdatedData,
        selectedFeatureIndexes: updatedSelectedFeatureIndexes,
        editContext: editContext,
        radiusDialog: !!editContext?.radiusDialog,
        undoFeatures,
        measureFeatures: currentMeasureFeatures,
        mapTooltips: currentMapTooltips,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  getFillColor = (
    feature: Feature<Geometry, GeoJsonProperties>,
    isSelected: any
  ) => {
    const index = this.state.testFeatures.features?.indexOf(feature);
    return isSelected
      ? getDeckColorForFeature(index, 1.0, 0.5)
      : getDeckColorForFeature(index, 0.5, 0.5);
  };

  getLineColor = (
    feature: Feature<Geometry, GeoJsonProperties>,
    isSelected: any
  ): RGBAColor => {
    const index = this.state.testFeatures.features?.indexOf(feature);

    if (
      feature.properties &&
      feature.properties.type &&
      feature.properties.type === "measure"
    ) {
      return [108, 117, 125, 255];
    }

    return isSelected
      ? getDeckColorForFeature(index, 1.0, 1.0)
      : getDeckColorForFeature(index, 0.5, 1.0);
  };

  updateMapStructs(structs: any): void {
    // TODO: refactor to update only changing fields
    this.setState((state) => {
      return {
        mapStructs: {
          lanes: structs?.temp_lanes || state.mapStructs.lanes,
          control_lines:
            structs?.temp_control_lines || state.mapStructs.control_lines,
          intersections:
            structs?.temp_intersections || state.mapStructs.intersections,
          stop_signs: structs?.temp_stop_signs || state.mapStructs.stop_signs,
        },
      };
    });
  }

  updateAvailablesIds(ids: any) {
    this.setState(() => {
      return {
        availableIds: {
          lane_ids_for_left_boundary_line:
            ids.temp_lane_ids_for_left_boundary_line,
          lane_ids_for_right_boundary_line:
            ids.temp_lane_ids_for_right_boundary_line,
          lane_ids_for_start_line: ids.temp_lane_ids_for_start_line,
          lane_ids_for_termination_line: ids.temp_lane_ids_for_termination_line,
          lane_ids_for_stop_sign_control_line:
            ids.temp_lane_ids_for_stop_sign_control_line,
          lane_ids_for_stop_sign: ids.temp_lane_ids_for_stop_sign,
          lane_ids_for_intersection: ids.temp_lane_ids_for_intersection,
        },
      };
    });
  }

  updateFeatures = (testFeatures: any) => {
    this.setState({ testFeatures });
  };

  updateModeConfig(temp_props: any) {
    this.setState({
      modeConfig: {
        ...(this.state.modeConfig || {}),
        ...temp_props,
      },
    });
  }

  updatePointsRemovable() {
    this.setState({ pointsRemovable: !this.state.pointsRemovable });
  }

  updateRenderMapboxLayer() {
    this.setState({ renderMapboxLayer: !this.state.renderMapboxLayer });
  }

  deleteFeatureProperty(featureIndex: number, featureInfoIndex: number) {
    const featureInfoProperties =
      this.state.testFeatures.features[featureIndex].properties
        ?.feature_info_list;

    if (!featureInfoProperties) return;

    const filteredInfoProperties = featureInfoProperties.filter(
      (featureInfo: GeoJsonProperties, index: number) =>
        index !== featureInfoIndex && featureInfo
    );

    const updatedFeatures = update(this.state.testFeatures, {
      features: {
        [featureIndex]: {
          properties: {
            feature_info_list: { $set: filteredInfoProperties },
          },
        },
      },
    });
    this.updateFeatures(updatedFeatures);
  }

  removeNullFeatureProperty(selectedFeatures: Array<number>) {
    const features = this.state.testFeatures.features;
    const fileredPropertyFeatures = features.map((feature, index) => {
      if (selectedFeatures.includes(index)) {
        return feature;
      } else {
        const filteredFeatureInfoProperties: Feature<
          Geometry,
          GeoJsonProperties
        >[] = feature.properties?.feature_info_list.filter(
          (featureInfo: GeoJsonProperties) =>
            (featureInfo?.control_line_id !== "NULL" &&
              featureInfo?.lane_association !== "NULL") ||
            (featureInfo?.polygon_type !== "NULL" &&
              featureInfo?.lane_association !== "NULL")
        );

        return {
          ...feature,
          properties: {
            ...feature.properties,
            feature_info_list: filteredFeatureInfoProperties,
          },
        };
      }
    });

    this.updateFeatures({
      ...this.state.testFeatures,
      features: fileredPropertyFeatures,
    });
  }

  updateSelectedFeatureIndexes(selected: Array<number>, menu?: any) {
    this.removeNullFeatureProperty(selected);
    this.setState(
      {
        selectedFeatureIndexes: selected,
        featureMenu: menu || this.state.featureMenu,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  }

  updateShowGeoJson() {
    this.setState({ showGeoJson: !this.state.showGeoJson });
  }

  updateMode(mode: any, category: string) {
    this.setState(
      {
        mode,
        modeConfig: {
          turfOptions: { units: "meters" },
        },
        selectionTool: undefined,
        currentCategory: category,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  }

  updateFeatureMenu(
    testFeatures: any,
    show_dialog: boolean,
    selectedFeatureIndexes: number[],
    undoFeatures: FeaturesInterface[]
  ) {
    this.setState(
      {
        featureMenu: undefined,
        testFeatures,
        showDialog: show_dialog,
        selectedFeatureIndexes,
        undoFeatures,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  }

  updateDialog(showDialog: boolean) {
    this.setState({ showDialog });
  }

  updateLayers() {
    const { editPlane, viewport, pointCloudData } = this.state;

    const pointCloudLayers = this.createPointCloudLayers(pointCloudData);

    const visiblePointCloudLayers = pointCloudLayers.filter((layer) => {
      return this.state.layerVisibility[layer.id];
    });

    const layers = [
      ...(this.state.editableGeoJsonLayerVisible
        ? [this.state.editableGeoJsonLayer]
        : []),
      ...visiblePointCloudLayers,
      ...(this.state.editPlaneLayerVisible
        ? [
            edit_plane_layer(
              [viewport.longitude, viewport.latitude],
              editPlane.elevation,
              editPlane.radius,
              editPlane.color,
              editPlane.renderEditPlane
            ),
          ]
        : []),
    ];

    this.currentPointCloudLayers = this.createPointCloudLayers(pointCloudData);

    this.setState({
      layers,
      editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
    });
  }

  getRadius() {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const feature = this.state.testFeatures.features?.[index];

    return feature?.properties?.radius?.get(position);
  }

  getIsError() {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const line = this.state.testFeatures.features?.[index]
      ?.geometry as LineString;
    const length = line?.coordinates?.length || 0;

    // Intermediate handle
    if (this.state.editContext?.position) {
      return true;
    }

    return !(position > 0 && length - 1 > position);
  }

  updateRadius(radiusDialog: boolean, radius?: number) {
    const index: number = this.state.editContext?.featureIndex;
    const position = this.state.editContext?.positionIndexes?.[0];
    const features = [...this.state.testFeatures.features];
    const feature = cloneDeep(features?.[index]);
    const length = (feature?.geometry as LineString)?.coordinates?.length || 0;
    const vertex = position > length && this.state.editContext?.position;
    let { undoFeatures } = this.state;

    if (radius) {
      if (feature?.properties?.radius) {
        (feature.properties.radius as Map<number, number>).set(
          position,
          radius
        );
      } else {
        feature.properties = {
          radius: new Map([[position, radius]]),
        };
      }

      try {
        (feature.geometry as LineString).coordinates = calculateCurve(
          feature.geometry as LineString,
          radius,
          position,
          vertex
        );
        undoFeatures = this.updateUndo(this.state.testFeatures);
      } catch (e: any) {
        this.setState({ showError: true, error: e.message });
      }

      features[index] = feature;
    }

    this.setState(() => ({
      radiusDialog,
      testFeatures: {
        ...this.state.testFeatures,
        features,
      },
      undoFeatures,
    }));
  }

  updatePointCloudJson() {
    // TODO: INFR-4308 - Call backend to obtain the json url for the point so it can be updated.
    // And createAsyncThunk on cloud-map-editor/src/redux/slices/currentMap.js
  }

  undoFeature() {
    const undoFeatures = [...this.state.undoFeatures];
    const testFeatures = undoFeatures.pop();
    if (testFeatures) {
      this.setState({ testFeatures, undoFeatures });
    }
  }

  clearSelectedFeatures() {
    this.setState(
      {
        selectedFeatureIndexes: [],
        selectionTool: undefined,
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  }

  // TODO: add toggling of layer for Floor Plan
  isSemanticDataPresent = (state: {
    currentMap: { semanticMapGeoJson: null };
  }) => state.currentMap.semanticMapGeoJson !== null;

  isCenterlineDataPresent = (state: {
    currentMap: { centerlineGeoJson: null };
  }) => state.currentMap.centerlineGeoJson !== null;

  groupPointsByColor(pointCloudData: { COORDINATES: number[] }[]) {
    if (!pointCloudData) {
      return {}; // Return an empty object if pointCloudData is undefined
    }

    const groupedPoints: any = {};

    for (let i = 0; i < MapConst.COLOR_COUNT; i++) {
      const color = rainbow().colourAt((i * 20) % 100);
      groupedPoints[color] = [];
    }

    pointCloudData.forEach((point: { COORDINATES: number[] }) => {
      const colorIndex = Math.floor(
        ((point.COORDINATES[2] * 20) % 100) / (100 / MapConst.COLOR_COUNT)
      );
      const color = Object.keys(groupedPoints)[colorIndex];
      if (groupedPoints[color]) {
        groupedPoints[color].push(point);
      }
    });

    return groupedPoints;
  }

  createPointCloudLayers(pointCloudData: { COORDINATES: number[] }[]) {
    const groupedPoints = this.groupPointsByColor(pointCloudData);

    // create separate PointCloudLayers for each color group
    const pointCloudLayers = Object.entries(groupedPoints).map(
      ([color, points], index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const rgbColor = Utils.hex2rgb(color).map((c) => c * 255);
        const layerId = `point_cloud_layer_${index}`;

        return new PointCloudLayer({
          id: layerId,
          data: points,
          coordinateSystem: COORDINATE_SYSTEM.LNGLAT_OFFSETS,
          coordinateOrigin: [
            this.props.currentMapData?.longitude,
            this.props.currentMapData?.latitude,
          ],
          getPosition: (d: any) => d.COORDINATES,
          pointSize: 2,
          opacity: 1.0,
          getColor: () => rgbColor,
          visible: this.state.layerVisibility[layerId] || false,
        });
      }
    );
    return pointCloudLayers;
  }

  // TODO: add new creation of layer for Floor Plan

  toggleLayerVisibility(id: string) {
    this.setState((prevState) => {
      const newLayerVisibility = {
        ...prevState.layerVisibility,
        [id]: !prevState.layerVisibility[id],
      };

      if (newLayerVisibility[id] !== prevState.layerVisibility[id]) {
        return { layerVisibility: newLayerVisibility };
      } else {
        return null;
      }
    }, this.updateLayers);
  }

  toggleEditableGeoJsonLayerVisibility() {
    this.setState(
      (prevState) => ({
        editableGeoJsonLayerVisible: !prevState.editableGeoJsonLayerVisible,
      }),
      this.updateLayers
    );
  }

  toggleEditPlaneLayerVisibility = () => {
    this.setState((prevState) => {
      return { editPlaneLayerVisible: !prevState.editPlaneLayerVisible };
    }, this.updateLayers);
  };

  setShowError(showError: boolean) {
    this.setState({ showError });
  }

  // TODO: add toggling of layer for Floor Plan

  isFeatureInfoListNull(feature_info_list: any[]) {
    // nothing has been populated yet
    if (!feature_info_list) {
      return true;
    }

    for (let i = 0; i < feature_info_list.length; i++) {
      const temp_feature_info = feature_info_list[i];
      if (MapConst.LINE_TYPE_STRING_NAME in temp_feature_info) {
        if (
          temp_feature_info[MapConst.LINE_TYPE_STRING_NAME] !==
          MapConst.NULL_STRING_NAME
        ) {
          return false;
        }
      } else if (MapConst.POLYGON_TYPE_STRING_NAME in temp_feature_info) {
        if (
          temp_feature_info[MapConst.POLYGON_TYPE_STRING_NAME] !==
          MapConst.NULL_STRING_NAME
        ) {
          return false;
        }
      }
    }
    return true;
  }

  featureMenuClick(
    action: string,
    index: number | undefined = this.state.featureMenu?.index
  ) {
    const selectedFeatureIndexes = this.state.selectedFeatureIndexes;
    let { testFeatures, undoFeatures } = this.state;

    let show_dialog = !!index;
    if (index !== undefined && action === "delete") {
      if (
        !this.isFeatureInfoListNull(
          testFeatures.features[index].properties?.feature_info_list
        )
      ) {
        alert("Please make all LineType / PolygonType NULL before deleting");
      } else {
        const features = [...testFeatures.features];
        const selectedIndex = selectedFeatureIndexes.indexOf(index);

        undoFeatures = this.updateUndo(this.state.testFeatures);

        features.splice(index, 1);

        if (selectedIndex > -1) {
          selectedFeatureIndexes.splice(selectedIndex, 1);
        }

        if (selectedFeatureIndexes.length === 0) {
          show_dialog = false;
        }

        testFeatures = Object.assign({}, testFeatures, {
          features,
        });
      }
    } else if (action === "split") {
      // TODO
    } else if (action === "info") {
      show_dialog = true;
    } else if (action === "close") {
      show_dialog = false;
    }

    this.updateFeatureMenu(
      testFeatures,
      show_dialog,
      selectedFeatureIndexes,
      undoFeatures
    );
  }

  selectGeojsonType = (type: any) => {
    const { setGeojsonTypeAction } = this.props;
    setGeojsonTypeAction(type);
  };

  generateLayers = () => {
    const { editPlane, viewport, editableGeoJsonLayer } = this.state;
    const pointCloudLayers = this.currentPointCloudLayers;

    const layers = [
      ...pointCloudLayers,
      edit_plane_layer(
        [viewport.longitude, viewport.latitude],
        editPlane.elevation,
        editPlane.radius,
        editPlane.color,
        editPlane.renderEditPlane
      ),
    ];

    if (editableGeoJsonLayer) {
      return [...layers, editableGeoJsonLayer];
    }

    return layers;
  };

  getCurrentEditableGeoJsonLayer = () => {
    const {
      testFeatures,
      selectedFeatureIndexes,
      mode,
      editHandleType,
      modeConfig,
      editPlane,
      editableGeoJsonLayerVisible,
      mapTooltips,
      measureFeatures,
    } = this.state;

    const editableGeoJsonLayer = new (EditableGeoJsonLayer as any)({
      ...MapConst.defaultGeoJsonLayerProps,
      data: {
        ...testFeatures,
        features: [...testFeatures.features, ...measureFeatures.features],
      },
      selectedFeatureIndexes,
      mode,
      modeConfig,
      onEdit: this.onEdit,
      editHandleType,
      getEditHandleIcon: (d: any) => getEditHandleTypeFromEitherLayer(d),
      getEditHandleIconColor: getEditHandleColor,
      getLineColor: this.getLineColor,
      getEditHandlePointColor: getEditHandleColor,
      editElevation: editPlane.elevation,
      visible: editableGeoJsonLayerVisible,
      _subLayerProps: {
        tooltips: {
          data: mapTooltips,
          getColor: [255, 255, 255],
        },
      },
    });

    return editableGeoJsonLayer;
  };

  removeLane = (laneId: string) => {
    const { mapStructs, availableIds, testFeatures } = this.state;
    const { lanes } = mapStructs;
    const { features } = testFeatures;
    lanes.delete(laneId);
    this.setState({
      mapStructs: {
        ...mapStructs,
        lanes,
      },
      testFeatures: {
        ...testFeatures,
        features: getFilteredFeatures(features, laneId),
      },
      availableIds: getFilteredAvalibleIds(availableIds, laneId),
    });
  };

  resetAllSpeedLimits = () => {
    this.setState((state) => {
      state.mapStructs.lanes.forEach((laneEntity, laneId) => {
        const newLaneEntity = state.mapStructs.lanes.get(laneId) as Lane;
        newLaneEntity.speed_limit = SPEED_LIMIT_DEFAULT_VALUE;
        state.mapStructs.lanes.set(laneId, newLaneEntity);
      });

      return state;
    });
  };

  removeMeasureElements = (id: string, distance: string) => {
    const { measureFeatures, mapTooltips } = this.state;
    const { features } = measureFeatures;

    this.setState(
      {
        measureFeatures: {
          ...measureFeatures,
          features: features.filter(
            (feature) => feature?.properties?.id !== id
          ),
        },
        mode: CustomViewMode,
        mapTooltips: mapTooltips.filter(
          ({ text }) => text.split(" ")[0] !== distance
        ),
      },
      () =>
        this.setState({
          editableGeoJsonLayer: this.getCurrentEditableGeoJsonLayer(),
        })
    );
  };

  render() {
    const {
      mode,
      viewport,
      isLoading,
      selectedFeatureIndexes,
      selectionTool,
      editableGeoJsonLayer,
    } = this.state;

    let { modeConfig } = this.state;
    const { showGeoJsonTypeModal } = this.state;
    const { currentMapData, toggleModalAction } = this.props;
    const { geojsonType } = currentMapData;

    if (mode === ElevationMode) {
      modeConfig = {
        ...modeConfig,
        viewport,
        calculateElevationChange: (opts: {
          pointerDownScreenCoords: Position;
          screenCoords: Position;
        }) =>
          ElevationMode.calculateElevationChangeWithViewport(viewport, opts),
      };
    } else if (mode === ModifyMode) {
      modeConfig = {
        ...modeConfig,
        viewport,
      };
    } else if (
      mode instanceof SnappableMode &&
      modeConfig &&
      modeConfig.enableSnapping
    ) {
      // Snapping can be accomplished to features that aren't rendered in the same layer
      modeConfig = {
        ...modeConfig,
        additionalSnapTargets: MapConst.modeConfigAdditionalSnapTargets,
      };
    }

    if (isLoading) {
      return <div>Loading Point Cloud Data...</div>;
    }

    const layers = this.generateLayers();

    return (
      <>
        <div>
          <GeoJsonTypeModal
            show={showGeoJsonTypeModal}
            onHide={this.closeGeoJsonTypeModal}
          />
          <MapContainerStyled
            onMouseDown={this.handleMouseDown}
            onContextMenu={this.handleMouseDown}
          >
            {this.props.isLoading && <LoaderSpinner />}
            <link
              href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
              rel="stylesheet"
            />
            {this.props.currentMapData.pointCloudJson && (
              <DeckGL
                viewState={viewport}
                getCursor={
                  editableGeoJsonLayer
                    ? editableGeoJsonLayer.getCursor.bind(editableGeoJsonLayer)
                    : undefined
                }
                layers={layers}
                height="100%"
                width="100%"
                views={[
                  new MapView({
                    id: "basemap",
                    controller: {
                      type: MapController,
                      doubleClickZoom:
                        (mode as any) === "view" && !selectionTool,
                    },
                  }),
                ]}
                onClick={this.onLayerClick}
                onViewStateChange={({ viewState }) => {
                  this.setState({ viewport: viewState });
                }}
              >
                {this.renderStaticMap(viewport)}
              </DeckGL>
            )}
            {/* TODO: Inprove: Send only required variables */}
            <MapToolbox
              {...this.state}
              removeMeasureElements={this.removeMeasureElements}
              measureFeatures={this.state.measureFeatures}
              deleteFeatureProperty={this.deleteFeatureProperty.bind(this)}
              alterDisabled={selectedFeatureIndexes.length === 0}
              dispatch={this.props.dispatch}
              saveMapGeoJson={this.props.saveMapGeoJsonAction}
              clearSelectedFeatures={this.clearSelectedFeatures}
              featureMenuClick={this.featureMenuClick}
              parseStringJson={this.parseStringJson}
              undo={this.undoFeature}
              updateMapStructs={this.updateMapStructs}
              updateAvailablesIds={this.updateAvailablesIds}
              updateFeatures={this.updateFeatures}
              updateModeConfig={this.updateModeConfig}
              updatePointsRemovable={this.updatePointsRemovable}
              updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
              updateRenderMapboxLayer={this.updateRenderMapboxLayer}
              updateShowGeoJson={this.updateShowGeoJson}
              updateMode={this.updateMode}
              updatePointCloudJson={this.updatePointCloudJson}
              toggleLayerVisibility={this.toggleLayerVisibility}
              toggleEditPlaneLayerVisibility={
                this.toggleEditPlaneLayerVisibility
              }
              layerVisibility={this.state.layerVisibility}
              editableGeoJsonLayerVisible={
                this.state.editableGeoJsonLayerVisible
              }
              toggleEditableGeoJsonLayerVisibility={
                this.toggleEditableGeoJsonLayerVisibility
              }
              isSemanticDataPresent={this.isSemanticDataPresent}
              isCenterlineDataPresent={this.isCenterlineDataPresent}
              onGeojsonTypeSelect={this.selectGeojsonType}
              geojsonType={geojsonType}
              toggleModalAction={toggleModalAction}
            />
            {/* TODO: Send only required props */}
            {this.state.featureMenu && (
              <FeatureMenu
                {...this.state}
                featureMenuClick={this.featureMenuClick}
              />
            )}
          </MapContainerStyled>
          <div>
            {/* TODO: Inprove: Send only required variables */}
            <FeatureDialog
              {...this.state}
              deleteFeatureProperty={this.deleteFeatureProperty.bind(this)}
              selectedFeatureIndexes={selectedFeatureIndexes}
              showDialog={this.state.showDialog}
              testFeatures={this.state.testFeatures}
              lane={this.state.mapStructs.lanes.get(this.state.selectedLaneId)}
              featureMenuClick={this.featureMenuClick}
              onSpeedLimitChange={this.onSpeedLimitChange.bind(this)}
              updateAvailablesIds={this.updateAvailablesIds}
              updateDialog={this.updateDialog}
              updateFeatures={this.updateFeatures}
              updateMapStructs={this.updateMapStructs}
              updateSelectedFeatureIndexes={this.updateSelectedFeatureIndexes}
            />
            <RadiusDialog
              isError={this.getIsError()}
              radius={this.getRadius()}
              radiusDialog={this.state.radiusDialog}
              updateRadius={this.updateRadius}
            />
            <CustomToast
              show={this.state.showError}
              setShow={this.setShowError}
              title={this.state.error}
            ></CustomToast>
          </div>
          <div>
            <SideMenu
              mapStructs={this.state.mapStructs}
              resetAllSpeedLimits={this.resetAllSpeedLimits}
              selectedFeatureIndexes={this.state.selectedFeatureIndexes}
              renderEditPlane={this.state.editPlane.renderEditPlane}
              adjustEditPlaneElevation={this.adjustEditPlaneElevation.bind(
                this
              )}
              adjustEditPlaneRadius={this.adjustEditPlaneRadius.bind(this)}
              setEditPlaneOpen={this.setEditPlaneOpen.bind(this)}
              onSpeedLimitChange={this.onSpeedLimitChange.bind(this)}
              onLaneFocus={this.onLaneFocus.bind(this)}
              onIntersectionFocus={this.onIntersectionFocus.bind(this)}
              onStopSignFocus={this.onStopSignFocus.bind(this)}
              removeLane={(laneId: string) =>
                toggleModalAction({
                  type: CONFIRM_ACTION_MODAL,
                  data: {
                    text: "Are you sure you want to delete the entire lane?",
                    confirmAction: () => this.removeLane(laneId),
                  },
                })
              }
            ></SideMenu>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentMapData: getCurrentMap(state),
  isLoading: getIsLoading(state),
  saveSemanticMapFlag: getSaveSemanticMapFlag(state),
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveMapGeoJsonAction: (params: any) => dispatch(saveMapGeoJson(params)),
    getCurrentMapDataAction: (params: any) =>
      dispatch(getCurrentMapData(params)),
    setGeojsonTypeAction: (params: any) => dispatch(setGeojsonType(params)),
    toggleModalAction: (params: any) => dispatch(toggleModal(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapEditorComponent);
