import {
  ModifyMode,
  TranslateMode,
  TransformMode,
  DuplicateMode,
  ExtendLineStringMode,
  DrawLineStringMode,
  DrawPointMode,
  DrawPolygonMode,
  DrawRectangleMode,
  MeasureDistanceMode,
  MeasureAreaMode,
  MeasureAngleMode,
  SnappableMode,
  ElevationMode,
} from "@nebula.gl/edit-modes";

import { DrawLinestringByDraggingMode } from "../utils/draw-linestring-by-dragging-mode";
import { DrawRefinedLineStringMode } from "../utils/draw-refined-line-string-mode";
import { DrawCurvedLineStringMode } from "../utils/draw-curved-line-string-mode";
import { RGBAColor } from "../types";
import { CustomViewMode } from "../utils/custom-view-mode";
import { AlterCurvedEdgeMode } from "../utils/alter-curved-edge-mode";
import { TangentLineConstraintMode } from "../utils/tangent-line-constraint-mode";

import * as MapConst from "./map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply

import Rainbow from "rainbowvis.js";
export const rainbow = () => {
  const r = new Rainbow();
  r.setSpectrum(
    "lightskyblue",
    "aqua",
    "mediumseagreen",
    "lawngreen",
    "yellowgreen",
    "yellow",
    "wheat",
    "gold",
    "coral",
    "hotpink",
    "darksalmon",
    "red"
  );
  return r;
};

export const initialViewport = () => {
  const {
    properties: { latLngOrigin },
  } = MapConst.fetchMapOrigin();
  return {
    bearing: 0,
    latitude: latLngOrigin.latitude,
    longitude: latLngOrigin.longitude,
    pitch: 0,
    minZoom: 0,
    maxZoom: 23,
    zoom: 18,
    maxPitch: 100,
  };
};

export const ALL_MODES: any = [
  {
    category: "View",
    modes: [
      { label: "View", mode: CustomViewMode },
      { label: "Measure Distance", mode: MeasureDistanceMode },
      { label: "Measure Area", mode: MeasureAreaMode },
      { label: "Measure Angle", mode: MeasureAngleMode },
      { label: "Tangent Line Constraint", mode: TangentLineConstraintMode },
    ],
  },
  {
    category: "Draw",
    modes: [
      { label: "Draw Point", mode: DrawPointMode },
      { label: "Draw Snappable LineString", mode: DrawLineStringMode },
      {
        label: "Draw LineString By Dragging",
        mode: DrawLinestringByDraggingMode,
      },
      { label: "Draw Refined LineString", mode: DrawRefinedLineStringMode },
      { label: "Draw Curved LineString", mode: DrawCurvedLineStringMode },
      { label: "Draw Polygon", mode: DrawPolygonMode },
      { label: "Draw Rectangle", mode: DrawRectangleMode },
    ],
  },
  {
    category: "Alter",
    modes: [
      { label: "Modify", mode: ModifyMode },
      { label: "Translate*", mode: new SnappableMode(new TranslateMode()) },
      { label: "Duplicate", mode: DuplicateMode },
      { label: "Extend LineString", mode: ExtendLineStringMode },
      { label: "Transform*", mode: new SnappableMode(new TransformMode()) },
      { label: "Elevate*", mode: ElevationMode },
      { label: "Curve Edge", mode: AlterCurvedEdgeMode },
      { label: "* Cannot be undone", note: true },
    ],
  },
];

export const TWO_CLICK_POLYGON_MODES = [DrawRectangleMode];

export const FEATURE_COLORS: any[] = [
  "00AEE4",
  "DAF0E3",
  "9BCC32",
  "07A35A",
  "F7DF90",
  "EA376C",
  "6A126A",
  "FCB09B",
  "B0592D",
  "C1B5E3",
  "9C805B",
  "CCDFE5",
].map((hex: string) => {
  const value = parseInt(hex, 16);
  return [16, 8, 0].map((shift) => ((value >> shift) & 0xff) / 255);
});

// TODO edit-modes:  delete once fully on EditMode implementation and just use handle.properties.editHandleType...
// Unwrap the edit handle object from either layer implementation
export const getEditHandleTypeFromEitherLayer = (handleOrFeature: any) => {
  if (handleOrFeature.__source) {
    return handleOrFeature.__source.object.properties.editHandleType;
  } else if (handleOrFeature.sourceFeature) {
    return handleOrFeature.sourceFeature.feature.properties.editHandleType;
  } else if (handleOrFeature.properties) {
    return handleOrFeature.properties.editHandleType;
  }

  return handleOrFeature.type;
};

export const getEditHandleColor = (handle: any): RGBAColor => {
  switch (getEditHandleTypeFromEitherLayer(handle)) {
    case "existing":
      return [0xff, 0x80, 0x00, 0xff];
    case "snap-source":
      return [0xc0, 0x80, 0xf0, 0xff];
    case "intermediate":
    default:
      return [0xff, 0xc0, 0x80, 0xff];
  }
};
