import {
  getInitialData,
  refreshTokenAction,
  fetchCheckAccessToken,
} from "./actions";
import {
  getIsLoading,
  getExpirationAccessToken,
  getExpirationRefreshToken,
  getLogOutDate,
  getSaveSemanticMapFlag,
} from "./selectors";
import AppStateSlice from "./reducers";

// actions
export const {
  setIsLoading,
  setExpirationAccessToken,
  setExpirationRefreshToken,
  setSaveSemanticMapFlag,
  resetAppState,
} = AppStateSlice.actions;

// async actions
export { getInitialData, refreshTokenAction, fetchCheckAccessToken };

// selectors
export {
  getIsLoading,
  getExpirationAccessToken,
  getExpirationRefreshToken,
  getLogOutDate,
  getSaveSemanticMapFlag,
};

// reducer
export default AppStateSlice.reducer;
