import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { FormGroupInputContainerStyled } from "../../../pages/Login/styles";

export default function EditPointCloudModal({
  data,
  handleClose,
  updatePointCloud,
}) {
  const { name, description, _id } = data;
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const dispatch = useDispatch();

  const editAction = async () => {
    dispatch(
      updatePointCloud({
        _id,
        name: nameValue,
        description: descriptionValue,
      })
    );
    handleClose();
  };

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  return (
    <Modal show={Boolean(data.name)} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit cloud</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroupInputContainerStyled controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupInputContainerStyled controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={editAction}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
