import React, { FC, memo, useCallback, useState } from "react";
import { DefaultSpeedButtonStyled } from "../styles";
import { ConfirmActionModalCommon } from "../../Modals/ConfirmActionModal/ConfirmActionModalCommon";

type ResetSpeedLimitsProps = {
  resetAllSpeedLimits: () => void;
};

const CONFIRMATION_MODAL_TEXT =
  "Are you sure you would like to reset all lane elements to a default speed limit to 1.2 m/s?";

export const ResetSpeedLimits: FC<ResetSpeedLimitsProps> = memo(
  ({ resetAllSpeedLimits }) => {
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCloseModal = useCallback(() => {
      setIsShowConfirmModal(false);
    }, []);

    const handleConfirm = useCallback(() => {
      resetAllSpeedLimits();
      setIsShowConfirmModal(false);
    }, []);

    return (
      <>
        {isShowConfirmModal && (
          <ConfirmActionModalCommon
            modalMessage={CONFIRMATION_MODAL_TEXT}
            handleClose={handleCloseModal}
            handleConfirm={handleConfirm}
            cancelButtonText={"Cancel"}
          />
        )}
        <DefaultSpeedButtonStyled
          onClick={() => {
            setIsShowConfirmModal(true);
          }}
        >
          Reset speed limits
        </DefaultSpeedButtonStyled>
      </>
    );
  }
);
