import React from "react";
import { Modal, Button } from "react-bootstrap";
import { GeoJsonType } from "../../pages/MapEditor/mapEditor";
import { useDispatch } from "react-redux";
import { setGeojsonType } from "../../redux/slices/currentMap";

import { ModalContainerStyled } from "./styles";

interface GeoJsonTypeModalProps {
  show: boolean;
  onHide: () => void;
}

const GeoJsonTypeModal: React.FC<GeoJsonTypeModalProps> = ({
  show,
  onHide,
}) => {
  const dispatch = useDispatch();

  const handleGeojsonTypeSelect = (type: GeoJsonType) => {
    dispatch(setGeojsonType(type));
    onHide();
  };

  return (
    <ModalContainerStyled show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Select GeoJSON Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please select the type of GeoJSON you are working with.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => handleGeojsonTypeSelect(GeoJsonType.Semantic)}
        >
          Semantic
        </Button>
        <Button
          variant="primary"
          onClick={() => handleGeojsonTypeSelect(GeoJsonType.Centerline)}
        >
          Centerline
        </Button>
      </Modal.Footer>
    </ModalContainerStyled>
  );
};

export default GeoJsonTypeModal;
