import styled from "styled-components";

export const ReportModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export const ReportModalBackgroundStyled = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(37, 63, 115, 0.5);
`;

export const ReportModalContentStyled = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 8px;
  width: 340px;
`;

export const ReportModalTitleStyled = styled.div`
  display: flex;
  font-size: 16pt;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 8px;
`;

export const ReportModalTextStyled = styled.textarea`
  display: flex;
  font-size: 12pt;
  line-height: 1.5;
  background: #f8fafc;
  border: 2px solid;
  border-color: transparent;
  border-radius: 4px;
  height: 160px;
  width: 100%;
  margin-bottom: 16px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
`;
