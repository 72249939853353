import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { FormGroupInputContainerStyled } from "../../../pages/Login/styles";

export default function EditProjectModal({ data, handleClose, updateProject }) {
  const { name, description, latitude, longitude, _id, pointCloudId } = data;
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const dispatch = useDispatch();

  const editAction = async () => {
    dispatch(
      updateProject({
        _id,
        name: nameValue,
        description: descriptionValue,
        latitude: Number(lat),
        longitude: Number(long),
        pointCloudId,
      })
    );
    handleClose();
  };

  useEffect(() => {
    setLat(latitude);
  }, [latitude]);

  useEffect(() => {
    setLong(longitude);
  }, [longitude]);

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  useEffect(() => {
    setDescriptionValue(description);
  }, [description]);

  return (
    <Modal show={Boolean(data.name)} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroupInputContainerStyled controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupInputContainerStyled controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <Form>
          <FormGroupInputContainerStyled controlId="password">
            <Form.Label>Lat</Form.Label>
            <Form.Control
              type="text"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
            />
          </FormGroupInputContainerStyled>
          <FormGroupInputContainerStyled controlId="password">
            <Form.Label>Long</Form.Label>
            <Form.Control
              type="text"
              value={long}
              onChange={(e) => setLong(e.target.value)}
            />
          </FormGroupInputContainerStyled>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={editAction}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
