import React from "react";
import { SpinnerContainerStyled, SpinnerStyled } from "./styles";

export default function LoaderSpinner() {
  return (
    <SpinnerContainerStyled>
      <SpinnerStyled />
    </SpinnerContainerStyled>
  );
}
