import { FEATURE_COLORS } from "../constants/nebula";
import * as MapConst from "../constants/map-constants"; // Register ply file loader - Used by pointcloud layer for loading ply
import { GeoJSONInterface, Lane } from "../models/map-interface.d";

export function verify_features(features: GeoJSON.Feature[]): boolean {
  for (const feature of features) {
    if (feature["properties"] && feature["properties"]["feature_info_list"]) {
      for (const feature_info of feature["properties"]["feature_info_list"]) {
        let type = "";
        if (MapConst.LINE_TYPE_STRING_NAME in feature_info) {
          type = feature_info[MapConst.LINE_TYPE_STRING_NAME];
          if (type === MapConst.NULL_STRING_NAME) continue;
        }
        if (MapConst.POLYGON_TYPE_STRING_NAME in feature_info) {
          type = feature_info[MapConst.POLYGON_TYPE_STRING_NAME];
          if (type === MapConst.NULL_STRING_NAME) continue;
        }

        for (const field of MapConst.VERIFY_FIELDS.get(type)!) {
          if (
            field in feature_info &&
            feature_info[field] === MapConst.NULL_STRING_NAME
          ) {
            let id = feature["properties"]["feature_id"];
            if (
              MapConst.INTERSECTION_ID_STRING_NAME in feature_info &&
              feature_info[MapConst.INTERSECTION_ID_STRING_NAME] !==
                MapConst.NULL_STRING_NAME
            ) {
              id = feature_info[MapConst.INTERSECTION_ID_STRING_NAME];
            } else if (
              MapConst.STOP_SIGN_ID_STRING_NAME in feature_info &&
              feature_info[MapConst.STOP_SIGN_ID_STRING_NAME] !==
                MapConst.NULL_STRING_NAME
            ) {
              id = feature_info[MapConst.STOP_SIGN_ID_STRING_NAME];
            } else if (
              MapConst.CONTROL_LINE_ID_STRING_NAME in feature_info &&
              feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME] !==
                MapConst.NULL_STRING_NAME
            ) {
              id = feature_info[MapConst.CONTROL_LINE_ID_STRING_NAME];
            }

            alert(
              "Feature incomplete. Field " +
                field +
                " is missing for " +
                type +
                " " +
                id
            );
            return false;
          }
        }
      }
    }
  }

  return true;
}

export function verify_lanes(lanes: Map<number | string, Lane>): boolean {
  const lane_interface_keys = [
    "lane_id",
    "left_boundary_line_id",
    "right_boundary_line_id",
    "start_line_id",
    "termination_line_id",
  ];
  for (const [id, lane] of Array.from(lanes.entries())) {
    for (const key of lane_interface_keys) {
      if (lane[key as keyof typeof lane] === MapConst.INVALID_ID_NUMBER) {
        alert("Property " + key + " missing for lane " + id);
        return false;
      }
    }
  }

  return true;
}

export const add_deploy_properties_to_geojson = (
  input: GeoJSONInterface
): GeoJSONInterface => {
  const output = input;
  // @see cyngn_semantic_map_builder_suite/map_editor_tools/scripts/fit_line_1.py -> exportToGeojson
  output.features.forEach((feature, index) => {
    feature.id = ++index;
    if (!feature.properties) {
      feature.properties = {};
    }

    if (feature.geometry.type === "Point") {
      const feature_info_list = feature.properties.feature_info_list || [];
      const isStopPoint =
        feature_info_list.findIndex(
          (f: any) => f?.polygon_type === MapConst.STOP_SIGN_STRING_NAME
        ) > -1;
      const category = isStopPoint ? "Stop Point" : "Init Point";
      feature.properties.shape = "Marker";
      feature.properties.name = "User optional name";
      feature.properties.category = category;

      if (isStopPoint) {
        feature.properties["marker-color"] = "#FF0000";
      } else {
        feature.properties["marker-color"] = "#0000FF";
      }
    }

    if (feature.geometry.type === "LineString") {
      feature.properties["line_type"] = "center_line";
      feature.properties.shape = "Line";
      feature.properties.name = "";
      feature.properties.category = "Centerline Path";
    }
  });

  return output;
};

export const getHtmlColorForFeature = (index: number, selected: boolean) => {
  const length = FEATURE_COLORS.length;
  const color = FEATURE_COLORS[index % length]
    .map((c: any) => c * 255)
    .join(",");
  const alpha = selected ? 1.0 : 0.7;

  return `rgba(${color}, ${alpha})`;
};

export const getMultipleIdsFromCommaSeparateString = (
  comma_separated_string: any
) => {
  let list_of_ids: any[] = [];
  if (comma_separated_string === MapConst.NULL_STRING_NAME) {
    return list_of_ids;
  }
  list_of_ids = comma_separated_string.split(",");
  return list_of_ids;
};
