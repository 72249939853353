import { createSlice } from "@reduxjs/toolkit";

export type AppState = {
  isLoading: boolean;
  expirationAccessToken: number | null;
  expirationRefreshToken: number | null;
  logOutDate: number | null;
  saveSemanticMapFlag: boolean;
};

const initialState: AppState = {
  isLoading: false,
  expirationAccessToken: null,
  expirationRefreshToken: null,
  logOutDate: null,
  saveSemanticMapFlag: false,
};

export const Slice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
      return state;
    },
    setExpirationAccessToken: (state, action) => {
      state.expirationAccessToken = action.payload;
    },
    setExpirationRefreshToken: (state, action) => {
      state.expirationRefreshToken = action.payload;
    },
    setSaveSemanticMapFlag: (state, action) => {
      state.saveSemanticMapFlag = action.payload;
    },
    resetAppState: () => initialState,
  },
});

export default Slice;
