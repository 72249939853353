import {
  ClickEvent,
  FeatureCollection,
  ModeProps,
  GeoJsonEditMode,
} from "@nebula.gl/edit-modes";

export class TangentLineConstraintMode extends GeoJsonEditMode {
  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    const { picks } = event;

    if (picks.length > 0) {
      const { index } = picks[0];

      props.onEdit({
        updatedData: props.data,
        editType: "tangentLine",
        editContext: {
          position: null,
          featureIndex: [index],
        },
      });
    }

    // super.handleClick(event, props);
  }

  handlePointerMove({ picks }: { picks: any[] }, { onUpdateCursor }: any) {
    if (picks.length > 0) {
      onUpdateCursor("cell");
    } else {
      onUpdateCursor(null);
    }
  }
}
