import ModalsSlice from "./reducers";
import {
  getIsModalOpen,
  getModalData,
  getIsSaveProgressModal,
  getIsShowReportModal,
  getIsConfirmActionModal,
} from "./selectors";
import {
  SAVE_PROGRESS_MODAL,
  SHOW_REPORT_MODAL,
  CONFIRM_ACTION_MODAL,
} from "./constants";

//constants
export { SAVE_PROGRESS_MODAL, SHOW_REPORT_MODAL, CONFIRM_ACTION_MODAL };

// actions
export const { toggleModal, closeAnyModal } = ModalsSlice.actions;

// selectors
export {
  getIsModalOpen,
  getModalData,
  getIsSaveProgressModal,
  getIsShowReportModal,
  getIsConfirmActionModal,
};

// reducer
export default ModalsSlice.reducer;
