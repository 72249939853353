import { GeoJsonEditMode } from "@nebula.gl/edit-modes";

export class CustomViewMode extends GeoJsonEditMode {
  handlePointerMove({ picks }: { picks: any[] }, { onUpdateCursor }: any) {
    if (picks.length > 0) {
      onUpdateCursor("cell");
    } else {
      onUpdateCursor(null);
    }
  }
}
