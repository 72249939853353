import styled from "styled-components";

export const SaveProgressModalStyled = styled.div`
  width: 400px;
  height: 160px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  background: #172a3f;
  box-shadow: 0px 8px 32px rgba(27, 27, 27, 0.08);
  border-radius: 4px;
  color: #ffffff;
  line-height: 140%;
  box-sizing: border-box;
  padding: 16px;
  z-index: 1;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonStyled = styled.div`
  height: 40px;
  font-weight: 400;
  line-height: 128%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const SubmitButtonStyled = styled(ButtonStyled)`
  width: 107px;
  background: #3f7f7d;
  border: 2px solid transparent;
  color: #ffffff;
  margin-left: 8px;

  &:hover {
    border: 2px solid #0f625f;
  }
`;

export const CancelButtonStyled = styled(ButtonStyled)`
  width: 107px;
  border: 2px solid transparent;
  background: #df2020;
  color: #ffffff;

  &:hover {
    border: 2px solid #df2020;
  }
`;
