import React, { memo, FC } from "react";

import { ReactComponent as LogoSvg } from "./assets/logo.svg";
import ContextMenu from "./ContextMenu";
import {
  HeaderStyled,
  LogoContainerStyled,
  ContentContainerStyled,
} from "./styles";

type HeaderProps = {
  handleReportModal: () => void;
};

const Header: FC<HeaderProps> = memo(({ handleReportModal }) => {
  return (
    <HeaderStyled>
      <ContentContainerStyled>
        <LogoContainerStyled data-test-id="home">
          <LogoSvg />
        </LogoContainerStyled>
      </ContentContainerStyled>
      <ContentContainerStyled>
        <ContextMenu handleReportModal={handleReportModal} />
      </ContentContainerStyled>
    </HeaderStyled>
  );
});

export default Header;
