import React, { memo, FC, useState } from "react";

import {
  MeasureElementsStyled,
  MeasureElementsHeaderStyled,
  MeasureElementsListStyled,
  MeasureElementStyled,
  RemoveBtnStyled,
} from "./styles";

type MeasureElementsProps = {
  features: any;
  units?: string;
  removeMeasureElements: (id: string, distance: string) => void;
};

const MeasureElements: FC<MeasureElementsProps> = memo(
  ({ features, units, removeMeasureElements }) => {
    const [showList, setShowList] = useState(false);

    return (
      <MeasureElementsStyled>
        <MeasureElementsHeaderStyled onClick={() => setShowList(!showList)}>
          Measure Elements:
        </MeasureElementsHeaderStyled>
        <MeasureElementsListStyled>
          {features.map(({ properties }: any) => {
            const { distance, id } = properties;
            return (
              <MeasureElementStyled key={id}>
                <span>{distance.toFixed(2)}</span>
                <span>{units}</span>
                <RemoveBtnStyled
                  onClick={() =>
                    removeMeasureElements(id, String(distance.toFixed(2)))
                  }
                >
                  Remove
                </RemoveBtnStyled>
              </MeasureElementStyled>
            );
          })}
        </MeasureElementsListStyled>
      </MeasureElementsStyled>
    );
  }
);

export default MeasureElements;
