import styled from "styled-components";

interface ToolboxTitleProps {
  isDisabled?: boolean;
  active?: boolean;
}
export const ToolboxTitleStyled = styled.div<ToolboxTitleProps>`
  background: rgba(39, 45, 59, 0.8);
  font-size: 16px;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.isDisabled ? "grey" : props.active ? "#55B8B3" : "#ffffff"};
`;

export const ToolboxControlTextareaStyled = styled.textarea`
  width: 100%;
`;
