import * as React from "react";
import { Dropdown } from "react-bootstrap";

export class ItemWithContainerValues extends React.Component<any> {
  render() {
    const { values } = this.props;

    return values.map((value: string | undefined) => {
      return [
        <Dropdown.Item
          as="button"
          eventKey={value}
          key={value + "_button"}
          style={{ fontSize: ".875rem" }}
        >
          {value}
        </Dropdown.Item>,
        <Dropdown.Divider key={value + "_divider"} />,
      ];
    });
  }
}
