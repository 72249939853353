import { configureStore } from "@reduxjs/toolkit";

import cloudList from "./slices/cloudList";
import projectsList from "./slices/projectsList";
import user from "./slices/user";
import currentMap from "./slices/currentMap";
import appState from "./slices/appState";
import modals from "./slices/modals";

const store = configureStore({
  reducer: {
    appState,
    user,
    cloudList,
    projectsList,
    currentMap,
    modals,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
