import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

export const GOOGLE_CLIENT_ID =
  window._envK8S_?.REACT_APP_GOOGLE_CLIENT_ID || "";

function App() {
  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar={true} />
      <Outlet />
    </>
  );
}

export default App;
