import { slide as Menu } from "react-burger-menu";
import * as React from "react";
import { Button, Accordion, Card, ListGroup } from "react-bootstrap";

import { Lane, StopSign } from "../../map-interface.d";
import { Checkbox } from "../common/Checkbox";

import {
  BmItemTitleStyled,
  CardBodyStyled,
  CardContainerStyled,
  CardHeaderStyled,
  CardStyled,
  EditPlaneButtonStyled,
  EditPlaneToolboxStyled,
  MenuBtnStyled,
  SwitchStyled,
  CancelSelectedLanesButtonStyled,
} from "./styles";
import "./side-menu.css";
import { ResetSpeedLimits } from "./components/ResetSpeedLimits";
import { ExpandedLane } from "./components/ExpandedLane";

interface SideMenuState {
  openedTab: number | null;
  selectedLanes: Array<any>;
}

export class SideMenu extends React.Component<any, SideMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      openedTab: null,
      selectedLanes: [],
    };
  }

  selectLane = (event: any, laneId: any) => {
    event.preventDefault();
    const { onLaneFocus } = this.props;
    const { selectedLanes } = this.state;
    let newSelectedLanes = [];

    if (selectedLanes.includes(laneId)) {
      newSelectedLanes = [...selectedLanes.filter((lane) => laneId !== lane)];
    } else {
      newSelectedLanes = [...selectedLanes, laneId];
    }
    this.setState({
      selectedLanes: newSelectedLanes,
    });
    onLaneFocus(newSelectedLanes);
  };

  showSettings(event: any) {
    event.preventDefault();
  }

  _renderEditPlaneToolBox() {
    const elev_step = 0.5;
    const radius_step = 0.00005;
    return (
      <EditPlaneToolboxStyled>
        <BmItemTitleStyled>
          Edit Plane
          <SwitchStyled
            name="displayEditPlane"
            value={this.props.renderEditPlane ? 1 : 0}
            onChange={() => {
              this.props.setEditPlaneOpen(!this.props.renderEditPlane);
            }}
          ></SwitchStyled>
        </BmItemTitleStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              this.props.adjustEditPlaneElevation(elev_step);
            }}
          >
            Up
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              this.props.adjustEditPlaneElevation(-elev_step);
            }}
          >
            Down
          </Button>
        </EditPlaneButtonStyled>
        <EditPlaneButtonStyled>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              this.props.adjustEditPlaneRadius(radius_step);
            }}
          >
            +
          </Button>
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              this.props.adjustEditPlaneRadius(-radius_step);
            }}
          >
            -
          </Button>
        </EditPlaneButtonStyled>
      </EditPlaneToolboxStyled>
    );
  }

  senActiveLane = (lane_id: number) => {
    const { openedTab } = this.state;

    this.setState({ ...this.state, openedTab: openedTab ? null : lane_id });
  };

  _renderIntersectionProps(intersection_props: Lane) {
    let props_list: any = [];
    Object.keys(intersection_props).forEach((prop) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentValue = `${prop}:${intersection_props[prop]}`;
      props_list = props_list.concat(
        <ListGroup.Item key={prop}>{currentValue}</ListGroup.Item>
      );
    });

    return <ListGroup as="ul">{props_list}</ListGroup>;
  }

  _renderIntersectionList = () => {
    const { mapStructs, onIntersectionFocus } = this.props;
    const { intersections } = mapStructs;

    if (mapStructs === undefined || intersections === undefined) {
      return null;
    }

    const listItems: any = [];

    intersections.forEach((intersection_props: any) => {
      const { intersection_id } = intersection_props;
      listItems.push(
        <Card key={intersection_id}>
          <CardHeaderStyled>
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                onIntersectionFocus(intersection_id);
              }}
            >
              {intersection_id}
            </Button>
          </CardHeaderStyled>
          <Accordion.Collapse eventKey={intersection_id}>
            <CardBodyStyled>
              {this._renderIntersectionProps(intersection_props)}
            </CardBodyStyled>
          </Accordion.Collapse>
        </Card>
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Intersections</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  };

  _renderStopSignProps(stop_sign_props: StopSign) {
    let props_list: any = [];
    Object.keys(stop_sign_props).forEach((prop) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentItem = `${prop}:${stop_sign_props[prop]}`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props_list = props_list.concat(
        <ListGroup.Item key={prop}>{currentItem}</ListGroup.Item>
      );
    });

    return <ListGroup as="ul">{props_list}</ListGroup>;
  }

  _renderStopSignList() {
    const { mapStructs, onStopSignFocus } = this.props;
    const { stop_signs } = mapStructs;

    if (mapStructs === undefined || stop_signs === undefined) {
      return null;
    }

    const listItems: any = [];

    stop_signs.forEach((stop_sign_props: any) => {
      const { stop_sign_id } = stop_sign_props;
      listItems.push(
        <Card key={stop_sign_id}>
          <CardHeaderStyled>
            <Button
              variant="link"
              size="sm"
              onClick={() => {
                onStopSignFocus(stop_sign_id);
              }}
            >
              {stop_sign_id}
            </Button>
          </CardHeaderStyled>
          <Accordion.Collapse eventKey={stop_sign_id}>
            <CardBodyStyled>
              {this._renderStopSignProps(stop_sign_props)}
            </CardBodyStyled>
          </Accordion.Collapse>
        </Card>
      );
    });

    return (
      <div>
        <BmItemTitleStyled>Stop Signs</BmItemTitleStyled>
        <Accordion defaultActiveKey="0">{listItems}</Accordion>
      </div>
    );
  }

  canselSelectedLanes = () => {
    const { selectedLanes } = this.state;
    const { onLaneFocus } = this.props;

    if (selectedLanes.length !== 0) {
      this.setState({
        selectedLanes: [],
      });
      onLaneFocus([]);
    }
  };

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    const { mapStructs } = this.props;
    const { openedTab, selectedLanes } = this.state;
    const { lanes } = mapStructs;

    if (mapStructs === undefined) {
      return null;
    }

    return (
      <Menu right={true} isOpen={true}>
        <div>{this._renderEditPlaneToolBox()}</div>
        <div>
          {lanes && mapStructs.size !== 0 && (
            <div>
              <BmItemTitleStyled>Lane Elements</BmItemTitleStyled>
              {Array.from(lanes.values()).length > 0 && (
                <ResetSpeedLimits
                  resetAllSpeedLimits={this.props.resetAllSpeedLimits}
                />
              )}
              {selectedLanes.length !== 0 && (
                <CancelSelectedLanesButtonStyled
                  onClick={this.canselSelectedLanes}
                >
                  Cancel Select
                </CancelSelectedLanesButtonStyled>
              )}
              <Accordion defaultActiveKey="0">
                {Array.from(lanes.values()).map((lane: any) => {
                  const { lane_id } = lane;

                  return (
                    <CardContainerStyled key={lane_id}>
                      <CardStyled>
                        <MenuBtnStyled
                          onClick={() => {
                            this.senActiveLane(lane_id);
                          }}
                        >
                          <span>{lane_id}</span>
                          <Checkbox
                            key={lane_id}
                            checked={selectedLanes.includes(lane_id)}
                            onChange={(e: Event) => this.selectLane(e, lane_id)}
                          />
                        </MenuBtnStyled>
                      </CardStyled>
                      {openedTab === lane_id && (
                        <ExpandedLane
                          lane={lane}
                          onSpeedLimitChange={this.props.onSpeedLimitChange}
                          removeLane={this.props.removeLane}
                        />
                      )}
                    </CardContainerStyled>
                  );
                })}
              </Accordion>
            </div>
          )}
        </div>
        <div>{this._renderIntersectionList()}</div>
        <div>{this._renderStopSignList()}</div>
      </Menu>
    );
  }
}
