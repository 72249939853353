import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getIsLoading } from "../../redux/slices/appState";
import LoaderSpinner from "../../components/common/loaderSpinner";
import { getLogin } from "../../redux/slices/user";
import { BaseError } from "../../types";

import {
  ButtonStyled,
  FormGroupInputContainerStyled,
  GoogleLoginStyled,
  LoginContainerStyled,
  LoginFormContainerStyled,
} from "./styles";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isLoading = useSelector(getIsLoading);
  const GOOGLE_CLIENT_ID = window._envK8S_?.REACT_APP_GOOGLE_CLIENT_ID;
  const GOOGLE_AUTH =
    window._envK8S_?.REACT_APP_GOOGLE_AUTH || "https://dev.cloud.cyngn.net/";

  useEffect(() => {
    if (window.localStorage.getItem("JWT")) {
      navigate("/");
    }
  }, [navigate]);

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await dispatch(getLogin({ email, password }));
    navigate("/");
  };

  const onGoogleLoginSuccess = useCallback(
    async (response: any) => {
      const idToken = response.tokenId;

      const data = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
      };

      const headers = {
        Authorization: idToken,
        "Content-Type": "application/json",
      };
      try {
        const responseAuth = await axios.post(
          "https://prod.cloud.cyngn.net/api/v1/login/GoogleOauth2/",
          data,
          {
            headers,
          }
        );

        if (responseAuth.data.access) {
          window.localStorage.setItem(
            "JWT",
            `Bearer ${responseAuth.data.access}`
          );
          window.localStorage.setItem(
            "JWTrefresh",
            `Bearer ${responseAuth.data.refresh}`
          );
          navigate("/");
        }
      } catch (error) {
        console.log(error);
        const baseError = error as BaseError;

        if (baseError?.response?.data?.detail) {
          toast.error(baseError.response.data.detail);
          return;
        }
        toast.error("Something went wrong with Google Auth");
      }
    },
    [navigate]
  );

  return (
    <LoginContainerStyled>
      {isLoading && <LoaderSpinner />}
      <LoginFormContainerStyled onSubmit={handleSubmit}>
        <FormGroupInputContainerStyled controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <FormGroupInputContainerStyled controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroupInputContainerStyled>
        <ButtonStyled size="lg" type="submit" disabled={!validateForm()}>
          Login
        </ButtonStyled>
        {GOOGLE_CLIENT_ID && (
          <GoogleLoginStyled
            clientId={GOOGLE_CLIENT_ID}
            className="GoogleLoginButton"
            buttonText="Sign in with Google"
            onSuccess={onGoogleLoginSuccess}
            onFailure={(e) => console.log("Google sign in error", e)}
          />
        )}
      </LoginFormContainerStyled>
    </LoginContainerStyled>
  );
}
