import { Pick } from "@nebula.gl/edit-modes";
import { EditHandleFeature } from "../models/nebula.gl";

// Replica of cloud-map-editor/node_modules/@nebula.gl/edit-modes/src/utils.ts
export function getPickedEditHandles(
  picks: Pick[] | null | undefined
): EditHandleFeature[] {
  const handles =
    (picks &&
      picks
        .filter(
          (pick) =>
            pick.isGuide && pick.object.properties.guideType === "editHandle"
        )
        .map((pick) => pick.object)) ||
    [];

  return handles;
}

export function getPickedExistingEditHandle(
  picks: Pick[] | null | undefined
): EditHandleFeature | null | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }: any) =>
      properties.featureIndex >= 0 && properties.editHandleType === "existing"
  );
}

export function getPickedIntermediateEditHandle(
  picks: Pick[] | null | undefined
): EditHandleFeature | null | undefined {
  const handles = getPickedEditHandles(picks);
  return handles.find(
    ({ properties }) =>
      properties.featureIndex >= 0 &&
      properties.editHandleType === "intermediate"
  );
}
