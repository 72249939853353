import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getIsSaveProgressModal,
  getIsShowReportModal,
  getIsConfirmActionModal,
} from "../../redux/slices/modals";

import { SaveProgressModal } from "./SaveProgressModal";
import { ReportModal } from "./ReportModal";
import { ConfirmActionModal } from "./ConfirmActionModal/ConfirmActionModal";

export const Modals = memo(() => {
  const isSaveProgressModal = useSelector(getIsSaveProgressModal);
  const isReportModal = useSelector(getIsShowReportModal);
  const isConfirmActionModal = useSelector(getIsConfirmActionModal);

  return (
    <>
      {isSaveProgressModal && <SaveProgressModal />}
      {isReportModal && <ReportModal />}
      {isConfirmActionModal && <ConfirmActionModal />}
    </>
  );
});
