import React from "react";
import update from "immutability-helper";
import Button from "react-bootstrap/Button";
import { LayoutSplit, Trash } from "react-bootstrap-icons";
import { v4 as uuidv4 } from "uuid";

import {
  FeatureInfo,
  FeaturePropertiesPanel,
} from "./components/FeaturePropertiesPanel";
import * as MapConst from "../../constants/map-constants";

import { FeatureItemProps } from "./index.d";
import {
  ModalButtonStyled,
  ModalStyled,
  ToolboxCheckboxButtonStyled,
  ToolboxCheckboxSpanStyled,
  ToolboxCheckboxStyled,
} from "./styles";

export default function FeatureItem({
  availableIds,
  featureType,
  index,
  mapStructs,
  selectedFeatureIndexes,
  testFeatures,
  isModal,
  featureMenuClick,
  updateAvailablesIds,
  updateMapStructs,
  updateFeatures,
  updateSelectedFeatureIndexes,
  deleteFeatureProperty,
}: FeatureItemProps) {
  const feature = testFeatures.features[index];
  const featureId = feature?.properties?.feature_id || "";

  const handleAddFeatureProperty = (
    e: React.MouseEvent<HTMLButtonElement>,
    featureIndex: number
  ) => {
    e.preventDefault();

    const featureType = testFeatures.features?.[featureIndex]?.geometry?.type;
    /*
    feature_info_list will be a collection of LineStringInfo or PolygonInfo
    LineStringInfo {
      linetype: string (lane_left_boundary_line / lane_right_boundary_line / lane_start_line / lane_termination_line / stop_sign_control_line),
      lane_association: number,
      control_line_id: number (ideally render only for stop_sign_control_line / must be NULL for other types),
      stop_sign_association: number (ideally render only for stop_sign_control_line / must be NULL for other types),
    }
    PolygonInfo {
      polygon_type: string (stop_sign, intersection)
      lane association: number[], (intersection can have multiple lanes associated)
      stop_sign_id, (ideally render only for stop_sign / must be NULL for other types)
      intersection_id, (ideally render only for intersection / must be NULL for other types)
      control_line_association, (ideally render only for stop_sign / must be NULL for other types)
    }
    */

    const feature = testFeatures.features[featureIndex];
    const tempFeatureInfoList = feature.properties?.feature_info_list || [];
    const featureId = feature.properties?.feature_id || uuidv4();

    const featureInfo: FeatureInfo = {};
    if (featureType === "LineString") {
      featureInfo[MapConst.LINE_TYPE_STRING_NAME] = MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.CONTROL_LINE_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.STOP_SIGN_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    } else if (featureType === "Polygon") {
      featureInfo[MapConst.POLYGON_TYPE_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.LANE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.STOP_SIGN_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.INTERSECTION_ID_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
      featureInfo[MapConst.CONTROL_LINE_ASSOCIATION_STRING_NAME] =
        MapConst.NULL_STRING_NAME;
    }
    tempFeatureInfoList.push(featureInfo);

    const result_state = update(testFeatures, {
      features: {
        [featureIndex]: {
          properties: {
            feature_info_list: { $set: tempFeatureInfoList },
            feature_id: { $set: featureId },
          },
        },
      },
    });
    updateFeatures(result_state);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    action: string,
    index: number
  ) => {
    e.preventDefault();
    featureMenuClick(action, index);
  };

  return (
    <div key={index}>
      <ToolboxCheckboxStyled
        isHide={isModal}
        type="checkbox"
        checked={selectedFeatureIndexes.includes(index)}
        disabled={!!isModal}
        onChange={() => {
          if (selectedFeatureIndexes.includes(index)) {
            updateSelectedFeatureIndexes(
              selectedFeatureIndexes.filter((e) => e !== index)
            );
          } else {
            updateSelectedFeatureIndexes([...selectedFeatureIndexes, index]);
          }
        }}
      >
        <ToolboxCheckboxSpanStyled
          index={index}
          isSelected={selectedFeatureIndexes.includes(index)}
        >
          {featureId}
          {": "}
          {featureType}
        </ToolboxCheckboxSpanStyled>
        {isModal ? (
          <ModalStyled>
            <ModalButtonStyled
              variant="secondary"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClick(e, "split", index)
              }
            >
              <LayoutSplit size={16} title="Split" />
            </ModalButtonStyled>
            <Button
              variant="danger"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleClick(e, "delete", index)
              }
            >
              <Trash size={16} title="Delete" />
            </Button>
          </ModalStyled>
        ) : (
          <ToolboxCheckboxButtonStyled
            variant="link"
            index={index}
            isSelected={selectedFeatureIndexes.includes(index)}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              updateSelectedFeatureIndexes([index], {
                index,
                x: e.clientX,
                y: e.clientY,
              });
            }}
          >
            &gt;&gt;
          </ToolboxCheckboxButtonStyled>
        )}
      </ToolboxCheckboxStyled>
      {selectedFeatureIndexes.includes(index) && (
        <FeaturePropertiesPanel
          deleteFeatureProperty={deleteFeatureProperty}
          handleAddFeatureProperty={handleAddFeatureProperty}
          featureIndex={index}
          testFeatures={testFeatures}
          availableIds={availableIds}
          mapStructs={mapStructs}
          updateAvailablesIds={updateAvailablesIds}
          updateMapStructs={updateMapStructs}
          updateFeatures={updateFeatures}
        />
      )}
    </div>
  );
}
