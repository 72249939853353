import { FC, memo, useMemo } from "react";
import { Lane } from "../../../map-interface.d";
import { ListGroup } from "react-bootstrap";
import {
  RemoveLaneButtonStyled,
  SpeedLimitContainerStyled,
  SpeedLimitInputStyled,
  SpeedLimitTextStyled,
} from "../styles";
import * as React from "react";

type ExpandedLaneProps = {
  lane: Lane;
  removeLane: (laneId: string) => void;
  onSpeedLimitChange: (laneId: string, speedLimitValue: string) => void;
};

export const ExpandedLane: FC<ExpandedLaneProps> = memo(
  ({ lane, removeLane, onSpeedLimitChange }) => {
    const propsList = useMemo(
      () =>
        Object.keys(lane).map((prop) => {
          if (prop === "speed_limit") return null;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const currentItem = `${prop}:${lane[prop]}`;

          return <ListGroup.Item key={prop}>{currentItem}</ListGroup.Item>;
        }),
      [
        lane?.start_line_id,
        lane?.termination_line_id,
        lane?.left_boundary_line_id,
        lane?.right_boundary_line_id,
      ]
    );

    const speedLimitInput = useMemo(() => {
      return (
        <SpeedLimitContainerStyled>
          <SpeedLimitTextStyled>speed_limit</SpeedLimitTextStyled>
          <SpeedLimitInputStyled
            id={lane.lane_id.toString()}
            value={lane.speed_limit}
            onChange={(e) => {
              onSpeedLimitChange(lane.lane_id, e.currentTarget.value);
            }}
          />
        </SpeedLimitContainerStyled>
      );
    }, [lane?.speed_limit]);

    return (
      <ListGroup as="ul">
        {propsList}
        {speedLimitInput}
        <SpeedLimitContainerStyled>
          <RemoveLaneButtonStyled onClick={() => removeLane(lane.lane_id)}>
            Remove
          </RemoveLaneButtonStyled>
        </SpeedLimitContainerStyled>
      </ListGroup>
    );
  }
);
