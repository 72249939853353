import { RootState } from "../../store";
import {
  SAVE_PROGRESS_MODAL,
  SHOW_REPORT_MODAL,
  CONFIRM_ACTION_MODAL,
} from ".";

export const getIsSaveProgressModal = (state: RootState) =>
  state.modals.type === SAVE_PROGRESS_MODAL;
export const getIsShowReportModal = (state: RootState) =>
  state.modals.type === SHOW_REPORT_MODAL;
export const getIsConfirmActionModal = (state: RootState) =>
  state.modals.type === CONFIRM_ACTION_MODAL;
export const getIsModalOpen = (state: RootState) => Boolean(state.modals.type);
export const getModalData = (state: RootState) => state.modals.data;
