import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getProject } from "./projectsList";
import { BASE } from "../../utils/api";

// when editing semantic/centerline we will need to update this
export const collectCurrentMapData = createAsyncThunk(
  "currentMap/collectCurrentMapData",
  async ({ _id, latitude, longitude }: any, thunkAPI) => {
    thunkAPI.dispatch(
      setValue({
        _id,
        latitude,
        longitude,
        pointCloudJson: `${BASE}map-projects/${_id}/point_cloud.json`,
        semanticMapGeoJson: `${BASE}map-projects/${_id}/semantic_map.geojson`,
        centerlineGeoJson: `${BASE}map-projects/${_id}/centerline.geojson`,
      })
    );
  }
);

export const getCurrentMapData = createAsyncThunk(
  "currentMap/getCurrentMapData",
  async (currentMapProjectId: string, thunkAPI) => {
    const projectData = await thunkAPI.dispatch(
      getProject(currentMapProjectId)
    );

    const { _id, latitude, longitude, radius } = projectData.payload;

    thunkAPI.dispatch(
      setValue({
        _id,
        latitude,
        longitude,
        radius,
        pointCloudJson: `${BASE}map-projects/${_id}/point_cloud.json`,
        semanticMapGeoJson: `${BASE}map-projects/${_id}/semantic_map.geojson`,
        centerlineGeoJson: `${BASE}map-projects/${_id}/centerline.geojson`,
      })
    );
  }
);

// TODO: might be good to change this Slice to something more meaningful - like currentMapSlice
export const Slice = createSlice({
  name: "currentMap",
  initialState: {
    _id: "",
    latitude: 0,
    longitude: 0,
    pointCloudJson: "",
    semanticMapGeoJson: "",
    centerlineGeoJson: "",
    geojsonType: null,
  },
  reducers: {
    setValue: (state, action) => {
      state = action.payload;
      return state;
    },
    setGeojsonType: (state, action) => {
      state.geojsonType = action.payload;
    },
  },
});

export const getCurrentMap = (state: any) => state.currentMap;

export const { setValue, setGeojsonType } = Slice.actions;
export default Slice.reducer;
